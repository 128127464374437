import React, { useEffect, useState } from "react";
import { Divider, Button, Switch, Popconfirm, Image, Table, Modal } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-hot-toast";
import {
    addPromotions,
    selectAllPromotions,
} from "../../features/promotionSlice";
import AddPromotion from "./AddPromotion";
import moment from "moment";
import { addShops } from "../../features/shopSlice";
import EditPromotion from "./EditPromotion";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Promo Image",
        dataIndex: "promotion_banner",
        key: "promotion_banner",
        render: (_, { promotion_banner }) => (
            <>
                <ViewImage image={promotion_banner} />
            </>
        ),
    },
    {
        title: "Shop",
        key: "shop",
        render: (_, promotion) => (
            <>
                <p>{promotion?.shops?.shopName}</p>
                <p>{promotion?.shops?.contactNumber}</p>
            </>
        ),
    },
    {
        title: "Product",
        key: "product",
        render: (_, promotion) => (
            <>
                <ViewProduct promotion={promotion} />
            </>
        ),
    },
    {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
        render: (date) => <p>{moment(date).format("DD-MM-YYYY")}</p>,
    },
    {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        render: (date) => <p>{moment(date).format("DD-MM-YYYY")}</p>,
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (_, promotion) => (
            <>
                <PromoStatus promotion={promotion} />
            </>
        ),
    },
    {
        title: "Actions",
        key: "action",
        render: (_, promotion) => (
            <p className="flex flex-row justify-start gap-2">
                <EditPromotion promotion={promotion} />
                <DeletePromo promotion={promotion} />
            </p>
        ),
    },
];

const PromoStatus = ({ promotion }) => {
    const dispatch = useDispatch();

    const getPromotions = async () => {
        let { data: promotions, error } = await supabase
            .from("promotions")
            .select("*, shops(*), products(*, model(*), brand(*))");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addPromotions(promotions));
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("promotions")
                .update({
                    status: !promotion?.status,
                })
                .eq("id", promotion?.id)
                .select("*");

            if (data) {
                //
                getPromotions();
                toast.success("Promotion is changed successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                promotion?.status ? "deactivate" : "activate"
            } this promotion?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={promotion?.status}
                className={
                    promotion?.status ? null : `bg-zinc-300 rounded-full`
                }
            />
        </Popconfirm>
    );
};

const ViewProduct = ({ promotion }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formatter = new Intl.NumberFormat("en-US");

    const product = promotion?.products;

    return (
        <>
            {promotion?.productId ? (
                <button
                    type="button"
                    className="px-6 py-2 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                    onClick={showModal}
                >
                    View
                </button>
            ) : null}
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                width={600}
            >
                <h4 className="text-lg font-semibold text-center pb-2">
                    PRODUCT DETAILS
                </h4>
                <div className="h-[420px] px-3 pt-2">
                    <div className="flex flex-col max-w-3xl px-6 space-y-4 sm:p-10 bg-gray-50 text-gray-800">
                        {/* <h2 className="text-xl font-semibold text-center"></h2> */}
                        <ul className="flex flex-col divide-y divide-gray-300">
                            {product ? (
                                <li className="flex flex-col py-6 sm:flex-row sm:justify-between">
                                    <div className="flex w-full space-x-2 sm:space-x-4">
                                        <img
                                            className="flex-shrink-0 object-cover w-20 h-20 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                                            src={product?.bannerImage}
                                            alt="car spare"
                                        />
                                        <div className="w-full pb-4">
                                            <div>
                                                <p className="capitalize font-semibold">
                                                    {product?.title}{" "}
                                                    {product?.condition}
                                                </p>
                                                <p>
                                                    {product?.brand?.brandName}{" "}
                                                    {product?.model?.modelName}{" "}
                                                    {product?.year}
                                                </p>
                                                <p>
                                                    TZS{" "}
                                                    {formatter.format(
                                                        product?.price || 0
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : (
                                <li className="text-center">
                                    There is no product
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const DeletePromo = ({ promotion }) => {
    const dispatch = useDispatch();

    const getPromotions = async () => {
        let { data: promotions, error } = await supabase
            .from("promotions")
            .select("*, shops(*), products(*, model(*), brand(*))");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addPromotions(promotions));
        }
    };

    const confirmDelete = async () => {
        //detelete category
        try {
            const { data, error } = await supabase
                .from("promotions")
                .delete()
                .eq("id", promotion?.id)
                .select("*");

            if (data) {
                //
                getPromotions();
                toast.success("Promotions is deleted successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Delete Brand"
            description="Are you sure to delete this promotion?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={() => confirmDelete()}
        >
            <Button type="text" shape="circle" className="flex justify-center">
                <RiDeleteBin5Line className="text-red-500 text-xl cursor-pointer" />
            </Button>
        </Popconfirm>
    );
};

const ViewImage = ({ image }) => {
    return (
        <Image
            width={120}
            height={80}
            src={image}
            style={{ objectFit: "cover" }}
        />
    );
};

const Promotions = () => {
    const dispatch = useDispatch();
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getPromotions = async () => {
            let { data: promotions, error } = await supabase
                .from("promotions")
                .select("*, shops(*), products(*, model(*), brand(*))");

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addPromotions(promotions));
            }
        };

        const getShops = async () => {
            setDataLoad(true);
            let { data: shops, error } = await supabase
                .from("shops")
                .select("*, shopOwner(*)");

            if (shops) {
                // console.log(shops);
                setDataLoad(false);
                dispatch(addShops(shops));
            } else {
                setDataLoad(false);
                console.log(error.message);
            }
        };

        getShops();
        getPromotions();
    }, [dispatch]);

    const promotions = useSelector(selectAllPromotions);
    const allPromotions = promotions
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedPromotions = allPromotions.map((promotion, index) => {
        const key = index + 1;
        return { ...promotion, key };
    });

    return (
        <div>
            <PageHeader
                head={"JU Promotions managments"}
                action={<AddPromotion />}
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={sortedPromotions}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Promotions;
