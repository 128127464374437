import React, { useEffect, useState } from "react";
import { DoNotDisturbAlt, Verified } from "@mui/icons-material";
import {
    Button,
    Popconfirm,
    Segmented,
    Switch,
    Table,
    Tag,
    Input,
    Space,
} from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addFilteredShops,
    addShopDetails,
    addShops,
    selectFilteredShops,
    selectShops,
} from "../../features/shopSlice";
import supabase from "../../supabaseClient";
import { toast } from "react-hot-toast";
import PageHeader from "../../components/PageHeader";
import { colors } from "../../assets/utils/colors";
import DataLoader from "../../components/DataLoader";

const { Search } = Input;

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Shop",
        dataIndex: "shopName",
        key: "shopName",
    },
    {
        title: "Location",
        dataIndex: "region",
        key: "shopLocation",
    },
    {
        title: "Shop Owner",
        key: "shopOwner",
        render: (_, shop) => (
            <>
                <OwnerInfo shop={shop} />
            </>
        ),
    },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (_, { isActive }) => (
            <>
                {!isActive ? (
                    <Tag color={"red"}>Closed</Tag>
                ) : (
                    <Tag color={"green"}>Open</Tag>
                )}
            </>
        ),
    },
    {
        title: "Verification",
        key: "isVerified",
        dataIndex: "isVerified",
        render: (_, { isVerified }) => (
            <>
                {!isVerified ? (
                    <p className="flex flex-row justify center items-center">
                        <DoNotDisturbAlt className="text-blue-500" />
                    </p>
                ) : (
                    <p className="flex flex-row justify center items-center">
                        <Verified className="text-green-500" />
                    </p>
                )}
            </>
        ),
    },
    {
        title: "Suspension",
        key: "isSuspended",
        render: (_, shop) => (
            <>
                <SuspensionStatus shop={shop} />
            </>
        ),
    },
    {
        title: "Action",
        key: "action",
        render: (_, shop) => <ViewShop shop={shop} />,
    },
];

const OwnerInfo = ({ shop }) => {
    return (
        <div>
            <p>
                {shop?.shopOwner?.first_name} {shop?.shopOwner?.last_name}
            </p>
            <p>{shop?.shopOwner?.phone}</p>
        </div>
    );
};

const ViewShop = ({ shop }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleViewShop = () => {
        dispatch(addShopDetails(shop));
        navigate(`/shops/${shop?.id}`);
    };

    return (
        <Button
            type="text"
            shape="circle"
            className="flex justify-center"
            onClick={() => handleViewShop()}
        >
            <FaEye className="text-red-500 text-xl cursor-pointer" />
        </Button>
    );
};

const SuspensionStatus = ({ shop }) => {
    const dispatch = useDispatch();

    const getShops = async () => {
        let { data: shops, error } = await supabase
            .from("shops")
            .select("*, shopOwner(*)");

        if (shops) {
            dispatch(addShops(shops));
        } else {
            console.log(error.message);
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("shops")
                .update({
                    isSuspended: !shop?.isSuspended,
                })
                .eq("id", shop?.id)
                .select("*");

            if (data) {
                //
                toast.success("Suspension status is changed successfully");
                try {
                    getShops();
                } catch (error) {
                    console.log(error);
                }
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Suspension Status"
            description={`Are you sure you want to ${
                shop?.isSuspended ? "suspend" : "unsuspend"
            } this shop?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={shop?.isSuspended}
                className={
                    shop?.isSuspended ? null : `bg-zinc-300 rounded-full`
                }
            />
        </Popconfirm>
    );
};

const Shops = () => {
    const dispatch = useDispatch();

    const [shopStatus, setShopStatus] = useState("");
    const [dataLoad, setDataLoad] = useState(false);

    // const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);

    useEffect(() => {
        const getShops = async () => {
            setDataLoad(true);
            let { data: shops, error } = await supabase
                .from("shops")
                .select("*, shopOwner(*)");

            if (shops) {
                setDataLoad(false);
                dispatch(addShops(shops));
            } else {
                setDataLoad(false);
                console.log(error.message);
            }
        };

        getShops();
    }, [dispatch]);

    const shops = useSelector(selectShops);
    const allShops = shops
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const statusFilters = {
        isClosed: (shop) => !shop.isActive,
        isActive: (shop) => shop.isActive,
        isVerified: (shop) => shop.isVerified,
        isSuspended: (shop) => shop.isSuspended,
    };

    const filteredShops = allShops.filter((shop) => {
        if (shopStatus && statusFilters[shopStatus]) {
            return statusFilters[shopStatus](shop);
        } else {
            // Include all items in the result if shopStatus is undefined or invalid
            return true;
        }
    });

    const sortedShops = filteredShops.map((shop, index) => {
        const key = index + 1;
        return { ...shop, key };
    });

    const openedShops = shops.filter((shop) => shop.isActive === true);

    const closedShops = shops.filter((shop) => shop.isActive === false);

    const verifiedShops = shops.filter((shop) => shop.isVerified === true);

    const suspendedShops = shops.filter((shop) => shop.isSuspended === true);

    const onStatusChange = (value) => {
        // console.log(value);
        setShopStatus(value);
    };

    const handleOnSearchChange = (searchText) => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedShops = shops.filter((shop) => {
                const name = shop?.shopName.toLocaleLowerCase();
                return name.includes(text);
            });

            // Update state with filtered shops
            dispatch(addFilteredShops(searchedShops));
            setFilters(true);
        } else {
            // Update state with filtered shops
            dispatch(addFilteredShops([]));
            setFilters(false);
        }
    };

    // const handleSearchText = (value) => {
    //     if (value) {
    //         setSearchText(value);
    //     } else {
    //         // Update state with filtered shops
    //         dispatch(addFilteredShops([]));
    //         setFilters(false);
    //         setSearchText(value);
    //     }
    // };

    const selectedShops = useSelector(selectFilteredShops);

    const allFilteredShops = selectedShops
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredShops = allFilteredShops.map((shop, index) => {
        const key = index + 1;
        return { ...shop, key };
    });

    return (
        <div>
            <PageHeader
                head={"JU Vendors shops"}
                action={
                    <div className="flex flex-row gap-8 justify-end items-end py-4 px-2">
                        <Space.Compact size="large">
                            <Input
                                placeholder="Search shop name here"
                                allowClear
                                onChange={
                                    (e) => handleOnSearchChange(e.target.value)
                                    // handleSearchText(e.target.value)
                                }
                                // onSearch={() => handleOnSearchChange()}
                            />
                        </Space.Compact>
                    </div>
                }
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <Segmented
                        block
                        options={[
                            {
                                label: `All Shops (${shops.length})`,
                                value: "",
                            },
                            {
                                label: `Opened Shops (${openedShops.length})`,
                                value: "isActive",
                            },
                            {
                                label: `Closed Shops (${closedShops.length})`,
                                value: "isClosed",
                            },
                            {
                                label: `Verified Shops (${verifiedShops.length})`,
                                value: "isVerified",
                            },
                            {
                                label: `Suspended Shops (${suspendedShops.length})`,
                                value: "isSuspended",
                            },
                        ]}
                        size="large"
                        value={shopStatus}
                        onChange={onStatusChange}
                        style={{
                            padding: 15,
                            boxShadow: `0, 0, 2px 0`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        }}
                    />

                    <div>
                        {filters ? (
                            <Table
                                columns={columns}
                                dataSource={sortedFilteredShops}
                            />
                        ) : (
                            <Table columns={columns} dataSource={sortedShops} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Shops;
