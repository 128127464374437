import apiClient from "../client/ClientApi";

export const createCategory = async (category) => {
    const response = await apiClient.post("/upload/categories", category, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
};

//updating category image
export const updateCategoryImage = async (category) => {
    const response = await apiClient.post(
        `/upload/categories/${category.get("category_id")}`,
        category,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response;
};
