import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Modal, DatePicker, Select } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { toast } from "react-hot-toast";
import {
  addPromotionProducts,
  addPromotions,
  selectPromotionProducts,
} from "../../features/promotionSlice";
import { selectShops } from "../../features/shopSlice";
import moment from "moment";
import { updatePromotionImage } from "../../api/PromotionAPI";

const EditPromotion = ({ promotion }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   console.log(promotion);

  const [banner, setImage] = useState(promotion?.promotion_banner);
  const [shop, setShop] = useState(
    promotion?.shopId
      ? { id: promotion?.shopId, value: promotion?.shops?.shopName }
      : null
  );
  const [product, setProduct] = useState(
    promotion?.productId
      ? {
          id: promotion?.productId,
          value: `${promotion?.products?.title} ${promotion?.products?.condition} ${promotion?.products?.brand?.brandName} ${promotion?.products?.model?.modelName} ${promotion.products?.year}`,
        }
      : null
  );
  const [promoType, setPromoType] = useState(
    promotion?.productId ? "product" : "shop"
  );
  const [start_date, setStartDate] = useState(moment(promotion?.start_date));
  const [end_date, setEndDate] = useState(moment(promotion?.end_date));
  const [fileList, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };

  const handleShopChange = async (id, value) => {
    //fetch shop products
    try {
      let { data: products, error } = await supabase
        .from("products")
        .select("*, model(*), brand(*), category(*)")
        .eq("shop", value?.id);

      if (error) {
        console.log(error.message);
      } else {
        // console.log(products);
        dispatch(addPromotionProducts(products));
      }
    } catch (error) {
      console.log(error.message);
    }
    setShop(value);
  };

  const handleProductChange = (id, value) => {
    setProduct(value);
  };

  const handlePromoChange = async (value) => {
    //fetch shop products
    if (shop?.id) {
      try {
        let { data: products, error } = await supabase
          .from("products")
          .select("*, model(*), brand(*), category(*)")
          .eq("shop", shop?.id);

        if (error) {
          console.log(error.message);
        } else {
          //console.log(products);
          dispatch(addPromotionProducts(products));
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    setPromoType(value);
  };

  const shops = useSelector(selectShops);
  const products = useSelector(selectPromotionProducts);

  const sortedShops = shops.map((shop) => ({
    id: shop.id,
    value: shop.shopName,
  }));

  const sortedProducts = products.map((product) => ({
    id: product.id,
    value: `${product?.title} ${product?.condition} ${product?.brand?.brandName} ${product?.model?.modelName} ${product?.year}`,
  }));

  const handleSavePromo = async () => {
    //initialize get promos
    const getPromos = async () => {
      let { data: promotions, error } = await supabase
        .from("promotions")
        .select("*, shops(*), products(*)");

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addPromotions(promotions));
      }
    };

    if (!banner) {
      toast.error("Please add banner image");
    } else if (!start_date) {
      toast.error("Please select start date");
    } else if (!end_date) {
      toast.error("Please select end date");
    } else {
      setLoading(true);
      //send data
      if (promoType === "product") {
        //set shop id null
        try {
          const { data, error } = await supabase
            .from("promotions")
            .update([
              {
                shopId: null,
                productId: product?.id,
                start_date: new Date(start_date),
                end_date: new Date(end_date),
              },
            ])
            .eq("id", promotion?.id)
            .select("*");

          if (data) {
            //
            getPromos();
            toast.success("Promotion is updated successfully");
            setLoading(false);
          } else {
            toast.error(error.message);
            setLoading(false);
          }
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        //set product id null
        try {
          const { data, error } = await supabase
            .from("promotions")
            .update([
              {
                shopId: shop?.id,
                productId: null,
                start_date: new Date(start_date),
                end_date: new Date(end_date),
              },
            ])
            .eq("id", promotion?.id)
            .select("*");

          if (data) {
            //
            getPromos();
            toast.success("Promotion is updated successfully");
            setLoading(false);
          } else {
            toast.error(error.message);
            setLoading(false);
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    }
  };

  // const handleImageUrl = (path) => {
  //   //console.log(path);
  //   try {
  //     const { data } = supabase.storage
  //       .from("jack-umeme-store")
  //       .getPublicUrl(path);

  //     if (data) {
  //       setImage(data?.publicUrl);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleUploadImage = async (file) => {
    //console.log(file);
    try {
      //upload image
      // const { data, error } = await supabase.storage
      //   .from("jack-umeme-store")
      //   .upload(`public_images/promotion/${file.name}`, file, {
      //     cacheControl: "3600",
      //     upsert: false,
      //   });
      const formdata = new FormData();
      formdata.append("promotion", file);
      formdata.append("promotion_id", promotion?.id);
      const { data } = await updatePromotionImage(formdata);
      if (!data.data.error) {
        setImage(data.data.data[0].promotion_banner);
        toast.success(data.message);
        //store image on list
        // const list = [...fileList, file];
        // setFiles(list);
        //
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveImage = async (file) => {
    // console.log(file);
    try {
      //remove image
      const { data, error } = await supabase.storage
        .from("jack-umeme-store")
        .remove([`public_images/promotion/${file.name}`]);

      if (data) {
        //
        setFiles([]);
        setImage("");
      } else {
        toast.error(error.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    multiple: "false",
    onRemove: (file) => {
      handleRemoveImage(file);
    },
    beforeUpload: (file) => {
      //check format and size
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJpgOrPng) {
        toast.error("You can only upload JPG/PNG file!");
      } else if (!isLt2M) {
        toast.error("Image must be smaller than 2MB!");
      } else if (fileList.length !== 0) {
        toast.error("Sorry! You can only upload one image file!");
      } else {
        //update file list
        //send image to storage
        handleUploadImage(file);
      }
      return false;
    },
    fileList,
  };

  return (
    <>
      <Button
        type="text"
        shape="circle"
        className="flex justify-center"
        onClick={showModal}
      >
        <MdEdit className="text-red-500 text-xl cursor-pointer" />
      </Button>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        width={700}
      >
        <h4 className="text-center font-semibold text-xl">
          Edit Promotion Details
        </h4>
        <div className="py-2 flex flex-col justify-center items-center">
          <div className="w-[90%] py-2">
            <Upload {...props}>
              <Button
                icon={<UploadOutlined className="text-center" />}
                size="large"
                className="text-center"
                style={{ width: 582 }}
              >
                Click here to upload promotion image
              </Button>
            </Upload>
          </div>
          <div className="w-[90%] py-2">
            <Select
              style={{
                width: 582,
              }}
              size="large"
              placeholder="Select promotion type"
              onChange={handlePromoChange}
              value={promoType}
              options={[
                {
                  value: "shop",
                  label: "Shop",
                },
                {
                  value: "product",
                  label: "Product",
                },
              ]}
            />
          </div>
          <div className="w-[90%] py-2">
            <Select
              showSearch
              style={{
                width: 582,
              }}
              placeholder="Select Shop"
              optionFilterProp="children"
              size="large"
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.value ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.value ?? "").toLowerCase())
              }
              onChange={handleShopChange}
              options={sortedShops}
              value={shop}
            />
          </div>
          {promoType === "product" ? (
            <div className="w-[90%] py-2">
              <Select
                showSearch
                style={{
                  width: 582,
                }}
                placeholder="Select product"
                optionFilterProp="children"
                size="large"
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.value ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.value ?? "").toLowerCase())
                }
                onChange={handleProductChange}
                options={sortedProducts}
                value={product}
              />
            </div>
          ) : null}
          <div className="w-[90%] py-2 flex flex-row gap-2">
            <DatePicker
              onChange={onStartDateChange}
              size="large"
              style={{ width: 350 }}
              placeholder="Start date"
              value={start_date}
            />
            <DatePicker
              onChange={onEndDateChange}
              size="large"
              style={{ width: 350 }}
              placeholder="End date"
              value={end_date}
            />
          </div>
          <div className="w-[90%] py-2 pt-4">
            {loading ? (
              <button
                type="button"
                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
              >
                Loading ...
              </button>
            ) : (
              <button
                type="button"
                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                onClick={() => handleSavePromo()}
              >
                EDIT PROMOTION
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPromotion;
