import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import { addModels } from "../../features/brandSlice";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { toast } from "react-hot-toast";

const { TextArea } = Input;

const EditModel = ({ model }) => {
    const dispatch = useDispatch();
    const { brandID } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [modelName, setModel] = useState(model?.modelName);
    const [description, setDescription] = useState(model?.description);
    const [loading, setLoading] = useState(false);

    const handleEditModel = async () => {
        //
        const getModels = async () => {
            let { data: models, error } = await supabase
                .from("models")
                .select("*, brands(*)")
                .eq("brandId", model?.brandId);

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addModels(models));
            }
        };

        if (!modelName) {
            toast.error("Please enter model name");
        } else if (!brandID) {
            toast.error("Please select brand to add model");
        } else {
            setLoading(true);
            //send data
            try {
                const { data, error } = await supabase
                    .from("models")
                    .update({
                        modelName: modelName,
                        brandId: brandID,
                        description: description,
                    })
                    .eq("id", model?.id)
                    .select();

                if (data) {
                    //
                    setIsModalOpen(false);
                    getModels();
                    toast.success("Model is updated successfully");
                    setLoading(false);
                } else {
                    toast.error(error.message);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    return (
        <>
            <Button
                type="text"
                shape="circle"
                className="flex justify-center"
                onClick={showModal}
            >
                <MdEdit className="text-red-500 text-xl cursor-pointer" />
            </Button>

            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                // style={{ top: 30 }}
                width={600}
            >
                <div className="w-[100%]">
                    <h4 className="text-center font-semibold text-xl">
                        Edit model details
                    </h4>
                    <div className="py-2 flex flex-col justify-center items-center">
                        <div className="w-[90%] py-2">
                            <Input
                                size="large"
                                placeholder="Model name"
                                onChange={(e) => setModel(e.target.value)}
                                value={modelName}
                                className="w-full"
                            />
                        </div>
                        <div className="w-[90%] py-2">
                            <TextArea
                                rows={2}
                                placeholder="Description"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />
                        </div>
                        <div className="w-[90%] py-2 pt-4">
                            {loading ? (
                                <button
                                    type="button"
                                    className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                >
                                    Loading ...
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                    onClick={() => handleEditModel()}
                                >
                                    EDIT MODEL
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EditModel;
