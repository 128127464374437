import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import supabase from "../../supabaseClient";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Cancel,
  CheckCircle,
  LocationOn,
} from "@mui/icons-material";
import {
  addCustomerDetails,
  addCustomerFavProducts,
  addCustomerOrders,
  selectCustomerDetails,
  selectCustomerFavProducts,
  selectCustomerOrders,
} from "../../features/userSlice";
import CustomerOrders from "./CustomerOrders";
import FavoriteProducts from "./FavoriteProducts";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Customer = () => {
  const dispatch = useDispatch();
  const { customerID } = useParams();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getCustomer = async () => {
      let { data: customer, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("usersId", customerID);

      if (customer) {
        dispatch(addCustomerDetails(customer[0]));
      } else {
        console.log(error.message);
      }
    };

    const getCustomerOrders = async () => {
      let { data: orders, error } = await supabase
        .from("orders")
        .select("*")
        .eq("customer", customerID || "");

      if (orders) {
        dispatch(addCustomerOrders(orders));
      } else {
        console.log(error.message);
      }
    };

    const getFavProducts = async () => {
      let { data: favorite_products, error } = await supabase
        .from("favorite_products")
        .select("*")
        .eq("customer", customerID || "");

      if (favorite_products) {
        dispatch(addCustomerFavProducts(favorite_products));
      } else {
        console.log(error.message);
      }
    };

    getCustomer();
    getCustomerOrders();
    getFavProducts();
  }, [dispatch]);

  const customerDetails = useSelector(selectCustomerDetails);
  const customerOrders = useSelector(selectCustomerOrders);
  const customerFavProducts = useSelector(selectCustomerFavProducts);

  return (
    <div className="px-4">
      <div className="w-[100%]">
        <div className="bg-purple-200 px-4 py-2 rounded-t-md">
          <div className="w-[100%] flex flex-row gap-2 justify-between">
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row gap-1 text-lg">
              <LocationOn className="my-2" />
              <p className="text-sm py-2.5">
                {customerDetails ? <>{customerDetails?.region}</> : null}
              </p>
            </div>
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row justify-between gap-1">
              <div>
                {customerDetails ? (
                  <p className="py-2 px-1">
                    {customerDetails?.status ? (
                      <span className="flex flex-row gap-2">
                        <CheckCircle className="text-xl text-green-500" />{" "}
                        <span className="">Active</span>
                      </span>
                    ) : (
                      <span className="flex flex-row gap-2">
                        <Cancel className="text-xl text-red-500" />{" "}
                        <span className="">Deactivated</span>
                      </span>
                    )}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 justify-between pt-2">
            <div className="py-1">
              <p className="text-xl py-1">
                {customerDetails?.first_name} {customerDetails?.last_name}{" "}
              </p>
              <p className="text-base py-1">{customerDetails?.phone}</p>
            </div>
            <div className="py-2">
              {loading ? (
                <button
                  type="button"
                  className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                  // onClick={() => handleCustomer()}
                >
                  {customerDetails?.status ? (
                    <>Deactivate Customer</>
                  ) : (
                    <>Activate Customer</>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ bgcolor: "#d8b4fe" }}
            >
              <Tab
                label={`CUSTOMER ORDERS (${customerOrders.length})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`FAVOURITE PRODUCTS (${customerFavProducts.length})`}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CustomerOrders />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FavoriteProducts/>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Customer;
