import { Box, Divider } from "@mui/material";
import React from "react";
import { colors } from "../assets/utils/colors";

const PageHeader = ({ head, action, startAction }) => {
  return (
    <>
      <Box
        sx={{
          py: 2.5,
          // mb: 2,
          position: "sticky",
          top: 64,
          zIndex: 999,
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `2px 0px 8px 0px ${colors.bgColor4}`,
        }}
      >
        <Box sx={{ position: "absolute", left: 10 }}>{startAction}</Box>
        <Divider>
          <h4
            className="text-lg font-semibold text-center"
            style={{ color: colors.primary }}
          >
            {head}
          </h4>
        </Divider>
        <Box sx={{ position: "absolute", right: 10 }}>{action}</Box>
      </Box>
    </>
  );
};

export default PageHeader;
