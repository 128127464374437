import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Cancel,
    CheckCircle,
    LocationOn,
    RemoveRedEye,
} from "@mui/icons-material";
import {
    addProductDetails,
    addProductImages,
    selectProductDetails,
    selectProductImages,
} from "../../features/productsSlice";
import supabase from "../../supabaseClient";
import { Modal, Tag } from "antd";
import { IconButton } from "@mui/material";
import { toast } from "react-hot-toast";
import { addShopDetails } from "../../features/shopSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const Product = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { productID } = useParams();

    const [loading, setLoading] = useState(false);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formatter = new Intl.NumberFormat("en-US");

    const getProduct = async () => {
        setDataLoad(true);
        let { data: product, error } = await supabase
            .from("products")
            .select("*, shop(*, shopOwner(*)), brand(*), model(*)")
            .eq("id", productID);

        if (product) {
            console.log(product);
            setDataLoad(false);
            dispatch(addProductDetails(product[0]));
        } else {
            setDataLoad(false);
            console.log(error.message);
        }
    };

    useEffect(() => {
        const getProductImages = async () => {
            setImagesLoading(true);
            let { data: product_images, error } = await supabase
                .from("product_images")
                .select("*")
                .eq("productId", productID);

            if (product_images) {
                console.log(product_images);
                setImagesLoading(false);
                dispatch(addProductImages(product_images));
            } else {
                console.log(error.message);
                setImagesLoading(false);
            }
        };

        getProduct();
        getProductImages();
    }, [dispatch]);

    const productDetails = useSelector(selectProductDetails);
    const productImages = useSelector(selectProductImages);

    const sortedImages = productImages.map((image) => ({
        original: image.imageUrl,
        thumbnail: image.imageUrl,
    }));

    const handleShop = (shop) => {
        dispatch(addShopDetails(shop));
        navigate(`/shops/${shop?.id}`);
    };

    const handleStatus = async () => {
        setLoading(true);
        const label =
            productDetails?.status === "approved" ? "suspended" : "approved";
        try {
            const { data, error } = await supabase
                .from("products")
                .update({
                    status: label,
                })
                .eq("id", productID)
                .select("*");

            if (data) {
                //
                getProduct();
                toast.success("Product status is changed successfully");
                setLoading(false);
            } else {
                toast.error(error.message);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            setLoading(false);
        }
    };

    const renderDescription = (description) => {
        return (
            <>
                <IconButton onClick={showModal} className="text-sm">
                    <RemoveRedEye fontSize="small" />
                </IconButton>
                <Modal
                    title=""
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={{
                        className: "hidden",
                    }}
                    cancelButtonProps={{
                        className: "hidden",
                    }}
                    width={"100%"}
                >
                    <h4 className="text-lg font-semibold text-center pb-2">
                        Product Description
                    </h4>
                    <div className="text-sm py-1">
                        <p>{description}</p>
                    </div>
                </Modal>
            </>
        );
    };

    return (
        <div>
            <PageHeader head={"Product details"} />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <div className="w-[100%]">
                        <div className="bg-purple-200 px-4 py-2 rounded-t-md">
                            <div className="w-[100%] flex flex-row gap-2 justify-between">
                                <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row gap-1 text-lg">
                                    <LocationOn className="my-2" />
                                    <p className="text-sm py-2.5">
                                        {productDetails ? (
                                            <>
                                                {productDetails?.shop?.region}{" "}
                                                {productDetails?.shop?.district}{" "}
                                                {productDetails?.shop?.street}
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                                <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row justify-between gap-1">
                                    <div>
                                        {productDetails ? (
                                            <p className="py-2 px-1">
                                                {productDetails?.isActive ? (
                                                    <span className="flex flex-row gap-2">
                                                        <CheckCircle className="text-xl text-green-500" />{" "}
                                                        <span className="">
                                                            Active
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span className="flex flex-row gap-2">
                                                        <Cancel className="text-xl text-red-500" />{" "}
                                                        <span className="">
                                                            Deactivated
                                                        </span>
                                                    </span>
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div>
                                        {productDetails ? (
                                            <p className="py-1 px-1 text-xl">
                                                {productDetails?.status ===
                                                "pending" ? (
                                                    <Tag color={"blue"}>
                                                        Pending
                                                    </Tag>
                                                ) : (
                                                    <>
                                                        {productDetails?.status ===
                                                        "approved" ? (
                                                            <Tag
                                                                color={"green"}
                                                            >
                                                                Approved
                                                            </Tag>
                                                        ) : (
                                                            <>
                                                                <Tag
                                                                    color={
                                                                        "red"
                                                                    }
                                                                >
                                                                    Suspended
                                                                </Tag>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 justify-between pt-2">
                                <div className="py-1">
                                    <div className="flex flex-row gap-2 justify-between">
                                        <p className="text-xl py-1">
                                            {productDetails?.title}{" "}
                                            <span className="capitalize">
                                                {productDetails?.condition}
                                            </span>{" "}
                                        </p>
                                        <p>
                                            {productDetails ? (
                                                <>
                                                    {renderDescription(
                                                        productDetails?.description
                                                    )}
                                                </>
                                            ) : null}
                                        </p>
                                    </div>
                                    <p className="text-base py-1">
                                        {productDetails?.brand?.brandName}{" "}
                                        {productDetails?.model?.modelName}{" "}
                                        {productDetails?.year}{" "}
                                    </p>
                                    <p className="text-sm py-1">
                                        {productDetails ? (
                                            <>
                                                Available:{" "}
                                                {productDetails?.remainQuantity}{" "}
                                                || Sold:{" "}
                                                {productDetails?.soldQuantity}{" "}
                                            </>
                                        ) : null}
                                    </p>
                                    <p className="text-base py-1">
                                        {productDetails ? (
                                            <>
                                                Price:{" "}
                                                <span className="">
                                                    TZS{" "}
                                                    {formatter.format(
                                                        productDetails?.price ||
                                                            0
                                                    )}
                                                </span>
                                            </>
                                        ) : null}
                                    </p>
                                    <p className="text-base py-1">
                                        {productDetails ? (
                                            <>
                                                Shop:{" "}
                                                <span
                                                    className="hover:underline text-violet-600 cursor-pointer"
                                                    onClick={() =>
                                                        handleShop(
                                                            productDetails?.shop
                                                        )
                                                    }
                                                >
                                                    {
                                                        productDetails?.shop
                                                            ?.shopName
                                                    }{" "}
                                                    /{" "}
                                                    {
                                                        productDetails?.shop
                                                            ?.contactNumber
                                                    }
                                                </span>
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                                <div className="py-2">
                                    {loading ? (
                                        <button
                                            type="button"
                                            className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                        >
                                            Loading ...
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                            onClick={() => handleStatus()}
                                        >
                                            {productDetails?.status ===
                                            "approved" ? (
                                                <>Suspend Product</>
                                            ) : (
                                                <>Approve Product</>
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-[100%] flex flex-row justify-center">
                            <div className="w-80% py-3">
                                <h4 className="text-center text-lg py-2">
                                    Product Images
                                </h4>
                                {imagesLoading ? (
                                    <Box sx={{ display: "flex" }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {productImages.length > 0 ? (
                                            <div className="h-[400px] w-[700px] mb-4">
                                                <Swiper
                                                    cssMode={true}
                                                    navigation={true}
                                                    pagination={true}
                                                    mousewheel={true}
                                                    keyboard={true}
                                                    modules={[
                                                        Navigation,
                                                        Pagination,
                                                        Mousewheel,
                                                        Keyboard,
                                                    ]}
                                                    className="mySwiper"
                                                >
                                                    {productImages.map(
                                                        (image, index) => {
                                                            return (
                                                                <SwiperSlide
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        src={
                                                                            image?.imageUrl
                                                                        }
                                                                        className="object-fit"
                                                                    />
                                                                </SwiperSlide>
                                                            );
                                                        }
                                                    )}
                                                </Swiper>
                                            </div>
                                        ) : (
                                            <p className="text-center">
                                                Product has no more images
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Product;
