import apiClient from "../client/ClientApi";

export const createBrand = async (brand) => {
  const response = await apiClient.post("/upload/brands", brand, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

//updating promotion image
export const updateBrand = async (brand) => {
  const response = await apiClient.post(
    `/upload/brands/${brand.get("brand_id")}`,
    brand,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
};
