import React, { useEffect, useState } from "react";
import { Select, Space, Input, Image, Carousel, Modal, Table } from "antd";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import {
    addCategories,
    addFilteredProducts,
    addProducts,
    addToCart,
    clearCart,
    decreaseFromCart,
    getTotals,
    removeFromCart,
    selectCartProducts,
    selectCartTotalAmount,
    selectCategories,
    selectFilteredProducts,
    selectProducts,
} from "../../features/posSlice";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import {
    addProductImages,
    selectProductImages,
} from "../../features/productsSlice";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { confirmOrderPayment, createSelcomOrder } from "../../api/paymentAPI";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const formatter = new Intl.NumberFormat("en-US");

const columns = [
    {
        title: "Image",
        dataIndex: "bannerImage",
        key: "bannerImage",
        render: (_, { bannerImage }) => (
            <>
                <ViewImage image={bannerImage} />
            </>
        ),
    },
    {
        title: "Name",
        key: "title",
        render: (_, product) => (
            <>
                <p className="capitalize">{product?.title}</p>
                <p className="capitalize">{product?.condition}</p>
            </>
        ),
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text) => <p>TZS {formatter.format(text)}</p>,
    },
    {
        title: "Car",
        key: "brand",
        render: (_, product) => (
            <>
                <p className="capitalize">{product?.brand?.brandName}</p>
                <p className="capitalize">
                    {product?.model?.modelName} {product?.year}
                </p>
            </>
        ),
    },
    {
        title: "Action",
        key: "action",
        render: (_, product) => <ViewProduct product={product} />,
    },
];

const ViewImage = ({ image }) => {
    return <Image width={80} height={80} src={image} />;
};

const ViewProduct = ({ product }) => {
    const dispatch = useDispatch();

    const [imagesLoading, setImagesLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getProductImages = async () => {
        setImagesLoading(true);
        let { data: product_images, error } = await supabase
            .from("product_images")
            .select("*")
            .eq("productId", product?.id);

        if (product_images) {
            console.log(product_images);
            dispatch(addProductImages(product_images));
            setImagesLoading(false);
        } else {
            console.log(error.message);
            setImagesLoading(false);
        }
    };

    const showModal = () => {
        getProductImages();
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        dispatch(addProductImages([]));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        dispatch(addProductImages([]));
    };

    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setPrice] = useState(product?.price);

    const handleDecreaseQuantity = () => {
        const value = quantity - 1;
        if (value > 0) {
            const price = product?.price * value;
            setQuantity(value);
            setPrice(price);
        } else {
            setQuantity(1);
            setPrice(product?.price);
        }
    };

    const handleIncreaseQuantity = () => {
        const value = quantity + 1;
        const price = product?.price * value;
        setQuantity(value);
        setPrice(price);
    };

    const handleAddToCart = () => {
        if (quantity && product) {
            if ("cart_quantity" in product) {
                dispatch(addToCart({ product, cart_quantity: quantity }));
                dispatch(getTotals());
                setIsModalOpen(false);
            } else {
                const newProducts = { ...product, cart_quantity: 0 };
                //console.log(newProducts);
                dispatch(
                    addToCart({ product: newProducts, cart_quantity: quantity })
                );
                dispatch(getTotals());
                setIsModalOpen(false);
            }
        }
    };

    const productImages = useSelector(selectProductImages);
    const sortedImages = productImages.map((image) => ({
        original: image.imageUrl,
        // thumbnail: image.imageUrl,
    }));

    // console.log(productImages);

    return (
        <>
            <IconButton onClick={() => showModal()}>
                <RemoveRedEye className="text-red-500 text-xl cursor-pointer" />
            </IconButton>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                width={700}
            >
                <div className="w-[100%] flex flex-row justify-center items-center py-1">
                    {imagesLoading ? (
                        <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {productImages.length > 0 ? (
                                <div className="h-[200px] w-[650px]">
                                    <Swiper
                                        cssMode={true}
                                        navigation={true}
                                        pagination={true}
                                        mousewheel={true}
                                        keyboard={true}
                                        modules={[
                                            Navigation,
                                            Pagination,
                                            Mousewheel,
                                            Keyboard,
                                        ]}
                                        className="mySwiper"
                                    >
                                        {productImages.map((image, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <img
                                                        src={image?.imageUrl}
                                                        className="object-fit"
                                                    />
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            ) : (
                                <p className="text-center">
                                    Product has no more images
                                </p>
                            )}
                        </>
                    )}
                </div>
                <div className="py-1">
                    <p className="capitalize text-lg">
                        <span className="font-semibold">
                            {product?.title} {product?.condition} -
                        </span>{" "}
                        {product?.brand?.brandName} {product?.model?.modelName}{" "}
                        {product?.year}
                    </p>
                    <p className="text-lg font-semibold">
                        TZS {formatter.format(product?.price || 0)}
                    </p>
                    <div className="py-2">
                        <h4 className="font-semibold">Description </h4>
                        <p>{product?.description}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-between py-1">
                    <h4 className="font-semibold text-lg">Quantity : </h4>
                    <div className="h-8 w-26 border-[1px] bg-[#f7f5fb] flex flex-row rounded-md text-lg">
                        <p
                            className="cursor-pointer py-1.5 px-1.5 border-l-[1px] w-8"
                            onClick={handleDecreaseQuantity}
                        >
                            <AiOutlineMinus />
                        </p>
                        <p className="bg-white w-12 text-center">{quantity}</p>
                        <p
                            className="cursor-pointer py-1.5 px-1.5 border-r-[1px] w-8"
                            onClick={handleIncreaseQuantity}
                        >
                            <AiOutlinePlus />
                        </p>
                    </div>
                </div>
                <div>
                    <h4 className="text-lg">
                        Total Price : TZS {formatter.format(totalPrice)}
                    </h4>
                    <div className="flex flex-row justify-center pt-3">
                        <button
                            type="button"
                            onClick={() => handleAddToCart()}
                            className="px-6 h-10 w-[100%] text-lg border rounded-md bg-violet-600 text-gray-50 border-violet-600"
                        >
                            Add to cart
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const PointOfSale = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);
    const [placedLoading, setPlacedLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getCategories = async () => {
            let { data: categories, error } = await supabase
                .from("categories")
                .select("*");

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addCategories(categories));
            }
        };

        const getProducts = async () => {
            let { data: products, error } = await supabase
                .from("products")
                .select("*, model(*), brand(*), category(*)");

            if (error) {
                console.log(error.message);
            } else {
                //console.log(products);
                dispatch(addProducts(products));
            }
        };

        getCategories();
        getProducts();
    }, [dispatch]);

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    const handleIncreaseToCart = (item) => {
        dispatch(addToCart({ product: item, cart_quantity: 1 }));
        dispatch(getTotals());
    };

    const handleRemoveToCart = (item) => {
        dispatch(removeFromCart(item));
        dispatch(getTotals());
    };

    const handleDecreaseToCart = (item) => {
        dispatch(decreaseFromCart(item));
        dispatch(getTotals());
    };

    const categories = useSelector(selectCategories);
    const products = useSelector(selectProducts);

    const cartProducts = useSelector(selectCartProducts);
    const cartTotalAmount = useSelector(selectCartTotalAmount);

    const allProducts = products
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedProducts = allProducts.map((order, index) => {
        const key = index + 1;
        return { ...order, key };
    });

    const sortedCategories = categories.map((category) => ({
        id: category.id,
        value: category.categoryName,
    }));

    const handleOnCategoryChange = (id, value) => {
        if (value) {
            const categoryProducts = products.filter((product) => {
                return product?.category?.id === value?.id;
            });

            // Update state with filtered products
            dispatch(addFilteredProducts(categoryProducts));
            setFilters(true);
        } else {
            // Update state with filtered products
            dispatch(addFilteredProducts([]));
            setFilters(false);
        }
    };

    const handleOnSearchChange = () => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedProducts = products.filter((product) => {
                const title = product?.title.toLocaleLowerCase();
                const condition = product?.condition.toLocaleLowerCase();
                const brand = product?.brand?.brandName.toLocaleLowerCase();
                const model = product?.model?.modelName.toLocaleLowerCase();
                return (
                    title.includes(text) ||
                    condition.includes(text) ||
                    product?.year === searchText ||
                    brand.includes(text) ||
                    model.includes(text)
                );
            });

            // Update state with filtered products
            dispatch(addFilteredProducts(searchedProducts));
            setFilters(true);
        } else {
            // Update state with filtered products
            dispatch(addFilteredProducts([]));
            setFilters(false);
        }
    };

    const handleSearchText = (value) => {
        if (value) {
            setSearchText(value);
        } else {
            // Update state with filtered products
            dispatch(addFilteredProducts([]));
            setFilters(false);
            setSearchText(value);
        }
    };

    const filteredProducts = useSelector(selectFilteredProducts);

    const allFilteredProducts = filteredProducts
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredProducts = allFilteredProducts.map((order, index) => {
        const key = index + 1;
        return { ...order, key };
    });

    const placeOrder = () => {
        return (
            <>
                <button
                    type="button"
                    className="px-6 py-2 border rounded-md bg-violet-600 text-gray-50 border-violet-600"
                    onClick={showModal}
                >
                    Proceed
                </button>
                <Modal
                    title=""
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={{
                        className: "hidden",
                    }}
                    cancelButtonProps={{
                        className: "hidden",
                    }}
                >
                    <h4 className="text-lg font-semibold text-center pb-2">
                        PLEASE CONFINM ORDER PLACE
                    </h4>
                    <Box>
                        <Typography>
                            Remember, once you place the order, it's challenging
                            to make changes. So take a moment to review these
                            details carefully before clicking that final “Place
                            Order” button!
                        </Typography>
                    </Box>
                    <div className="w-full">
                        <div className="py-2 flex flex-col justify-center items-center">
                            <div className="w-[90%] py-2 pt-4">
                                {placedLoading ? (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                    >
                                        Loading ...
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                        onClick={() => handlePlaceOrder()}
                                    >
                                        Place Order
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    };

    const payOrder = () => {
        return (
            <>
                <button
                    type="button"
                    className="px-6 py-2 border rounded-md bg-violet-600 text-gray-50 border-violet-600"
                    onClick={showModal}
                >
                    Proceed
                </button>
                <Modal
                    title=""
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={{
                        className: "hidden",
                    }}
                    cancelButtonProps={{
                        className: "hidden",
                    }}
                >
                    <h4 className="text-lg font-semibold text-center pb-2b mb-2">
                        PROVIDE ORDER PAYMENT DETAILS
                    </h4>
                    <div className="w-full">
                        <div className="px-5">
                            <h3 style={{}}>
                                1) After clicking “Pay,” your mobile phone will
                                receive a USSD-Push request.
                            </h3>
                            <h3 style={{}}>
                                2) Once you’ve entered your PIN, the payment
                                will be confirmed.
                            </h3>
                            <h3 style={{}}>
                                3) Remember to keep your PIN secure and ensure a
                                stable network connection during this process.
                                Happy mobile payments! 📱💸
                            </h3>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="py-2 flex flex-col justify-center items-center">
                            <div className="w-[90%] py-2">
                                <Input
                                    size="large"
                                    placeholder="Full name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="w-full"
                                />
                            </div>
                            <div className="w-[90%] py-2">
                                <Input
                                    size="large"
                                    type="number"
                                    placeholder="Phone number 255XXXXXXXXX"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    className="w-full"
                                />
                            </div>
                            <div className="w-[90%] py-2 pt-4">
                                {placedLoading ? (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                    >
                                        Loading ...
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                        onClick={() => handlePlaceOrder()}
                                    >
                                        Place Order
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    };

    const handlePlaceOrder = async () => {
        if (!name) {
            toast.error("Please enter full name");
        } else if (!phone) {
            toast.error("Please enter phone number");
        } else if (phone?.length < 12) {
            toast.error("Sorry! Phone number must start with 255");
        } else {
            supabase.auth.getSession().then(async ({ data: { session } }) => {
                setPlacedLoading(true);
                if (session?.user?.id) {
                    const customer = session.user.id;
                    const order_number =
                        "ORD-" +
                        Math.random()
                            .toString(36)
                            .substring(2, 8)
                            .toUpperCase();

                    try {
                        const { data, error } = await supabase
                            .from("orders")
                            .insert([
                                {
                                    customer,
                                    order_number,
                                    ordered_products: {
                                        products: cartProducts,
                                        product_count: cartProducts?.length,
                                    },
                                    total_amount: cartTotalAmount,
                                    description,
                                    isPOS: true,
                                    isDispatched: false,
                                },
                            ])
                            .select();
                        if (error) {
                            toast.error(error.message);
                            setPlacedLoading(false);
                            return;
                        } else if (data) {
                            //place selcom order
                            // console.log(data);
                            placeSelcomOrder({
                                order_id: data[0].id,
                                amount: cartTotalAmount,
                            });
                        }
                    } catch (error) {
                        toast.error(error.message);
                        setPlacedLoading(false);
                    }
                } else {
                    setPlacedLoading(false);
                }
            });
        }
    };

    const placeSelcomOrder = async ({ order_id, amount }) => {
        try {
            const response = await createSelcomOrder({
                name,
                phone,
                amount,
                order_id,
            });

            if (response?.data?.success == 1 && response?.data?.data) {
                /**
                 * REQUEST PUSH USSD
                 */
                const payment_token = response?.data?.data.payment_token;
                const payload = {
                    phone: phone,
                    order_id,
                    uid: payment_token,
                };
                const paymentResponse = await confirmOrderPayment(payload);
                if (paymentResponse?.data?.success == 1) {
                    toast.success(
                        "Order is placed successfully. Please proceed with payment!"
                    );
                    setName("");
                    setPhone("");
                    dispatch(clearCart());
                    dispatch(getTotals());
                    setPlacedLoading(false);
                    navigate(`/orders/${order_id}`);
                } else {
                    toast.error(
                        "Failed to make order payment, Please try again"
                    );
                    setPlacedLoading(false);
                }
            } else {
                toast.error("Failed to place order, please try again");
                setPlacedLoading(false);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            setPlacedLoading(false);
        }
    };

    const formatter = new Intl.NumberFormat("en-US");

    return (
        <>
            <div>
                <div className="">
                    <div className="bg-[#f7f5fb] rounded-t-2xl">
                        <h4 className="text-lg py-3 text-center font-semibold">
                            Spares Section
                        </h4>
                    </div>
                    <div className="flex flex-row gap-2 justify-between pt-4 px-2 pb-4">
                        <div className="pl-6">
                            <Select
                                showSearch
                                style={{
                                    width: 250,
                                }}
                                allowClear
                                placeholder="Search Category"
                                optionFilterProp="children"
                                size="large"
                                filterOption={(input, option) =>
                                    (option?.value ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.value ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (optionB?.value ?? "").toLowerCase()
                                        )
                                }
                                onChange={handleOnCategoryChange}
                                options={sortedCategories}
                            />
                        </div>
                        <div className="pr-6">
                            <Space.Compact size="large">
                                <Search
                                    placeholder="Search here"
                                    allowClear
                                    onChange={(e) =>
                                        handleSearchText(e.target.value)
                                    }
                                    onSearch={() => handleOnSearchChange()}
                                />
                            </Space.Compact>
                        </div>
                    </div>
                    <div className="">
                        {filters ? (
                            <Table
                                columns={columns}
                                dataSource={sortedFilteredProducts}
                                pagination={{ defaultPageSize: 3 }}
                            />
                        ) : (
                            <Table
                                columns={columns}
                                dataSource={sortedProducts}
                                pagination={{ defaultPageSize: 3 }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PointOfSale;
