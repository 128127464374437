import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shops: [],
  filteredShops: [],
  shopDetails: "",
  shopOrders: [],
  shopProducts: [],
  product: "",
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    addShops(state, action) {
      state.shops = action.payload;
    },

    addFilteredShops(state, action) {
      state.filteredShops = action.payload;
    },

    addShopDetails(state, action) {
      state.shopDetails = action.payload;
    },

    addShopOrders(state, action) {
      state.shopOrders = action.payload;
    },

    addShopProducts(state, action) {
      state.shopProducts = action.payload;
    },

    addShopProductDetails(state, action) {
      state.product = action.payload;
    },
  },
});

export const {
  addShops,
  addFilteredShops,
  addShopDetails,
  addShopOrders,
  addShopProducts,
  addShopProductDetails,
} = shopSlice.actions;

export const selectShops = (state) => state.shop.shops;
export const selectFilteredShops = (state) => state.shop.filteredShops;
export const selectShopDetails = (state) => state.shop.shopDetails;
export const selectShopOrders = (state) => state.shop.shopOrders;
export const selectShopProducts = (state) => state.shop.shopProducts;
export const selectShopProductDetails = (state) => state.shop.product;

export default shopSlice.reducer;
