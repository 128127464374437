import React, { useEffect, useState } from "react";
import {
    Divider,
    Button,
    Switch,
    Popconfirm,
    Image,
    Table,
    Input,
    Space,
} from "antd";
import { RiDeleteBin5Line } from "react-icons/ri";
import supabase from "../../supabaseClient";
import {
    addCategories,
    addFilteredCategories,
    selectCategories,
    selectFilteredCategories,
} from "../../features/posSlice";
import { useDispatch, useSelector } from "react-redux";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import { toast } from "react-hot-toast";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const { Search } = Input;

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Category Image",
        dataIndex: "categoryImage",
        key: "categoryImage",
        render: (_, { categoryImage }) => (
            <>
                <ViewImage image={categoryImage} />
            </>
        ),
    },
    {
        title: "Category Name",
        dataIndex: "categoryName",
        key: "categoryName",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, category) => (
            <>
                <CategoryStatus category={category} />
            </>
        ),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Actions",
        key: "action",
        render: (_, category) => (
            <p className="flex flex-row justify-start gap-2">
                <EditCategory category={category} />
                <DeleteCategory category={category} />
            </p>
        ),
    },
];

const CategoryStatus = ({ category }) => {
    const dispatch = useDispatch();

    const getCategories = async () => {
        let { data: categories, error } = await supabase
            .from("categories")
            .select("*");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addCategories(categories));
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("categories")
                .update({
                    status: !category?.status,
                })
                .eq("id", category?.id)
                .select("*");

            if (data) {
                //
                getCategories();
                toast.success("Status is changed successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                category?.status ? "deactivate" : "activate"
            } this category?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={category?.status}
                className={category?.status ? null : `bg-zinc-300 rounded-full`}
            />
        </Popconfirm>
    );
};

const DeleteCategory = ({ category }) => {
    const dispatch = useDispatch();

    const getCategories = async () => {
        let { data: categories, error } = await supabase
            .from("categories")
            .select("*");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addCategories(categories));
        }
    };

    const confirmDelete = async () => {
        //detelete category
        try {
            const { data, error } = await supabase
                .from("categories")
                .delete()
                .eq("id", category?.id)
                .select("*");

            if (data) {
                //
                getCategories();
                toast.success("Category is deleted successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Delete Category"
            description="Are you sure to delete this category?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={() => confirmDelete()}
        >
            <Button type="text" shape="circle" className="flex justify-center">
                <RiDeleteBin5Line className="text-red-500 text-xl cursor-pointer" />
            </Button>
        </Popconfirm>
    );
};

const ViewImage = ({ image }) => {
    return (
        <Image
            width={120}
            height={80}
            src={image}
            style={{ objectFit: "cover" }}
        />
    );
};

const Categories = () => {
    const dispatch = useDispatch();

    // const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getCategories = async () => {
            setDataLoad(true);
            let { data: categories, error } = await supabase
                .from("categories")
                .select("*");

            if (error) {
                setDataLoad(false);
                console.log(error.message);
            } else {
                setDataLoad(false);
                dispatch(addCategories(categories));
            }
        };

        getCategories();
    }, [dispatch]);

    const categories = useSelector(selectCategories);
    const allCategories = categories
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedCategory = allCategories.map((category, index) => {
        const key = index + 1;
        return { ...category, key };
    });

    const handleOnSearchChange = (searchText) => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedCategories = categories.filter((category) => {
                const name = category?.categoryName.toLocaleLowerCase();
                return name.includes(text);
            });

            // Update state with filtered categories
            dispatch(addFilteredCategories(searchedCategories));
            setFilters(true);
        } else {
            // Update state with filtered categories
            dispatch(addFilteredCategories([]));
            setFilters(false);
        }
    };

    // const handleSearchText = (value) => {
    //     if (value) {
    //         setSearchText(value);
    //     } else {
    //         // Update state with filtered categories
    //         dispatch(addFilteredCategories([]));
    //         setFilters(false);
    //         setSearchText(value);
    //     }
    // };

    const filteredCategories = useSelector(selectFilteredCategories);

    const allFilteredCategories = filteredCategories
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredCategories = allFilteredCategories.map(
        (category, index) => {
            const key = index + 1;
            return { ...category, key };
        }
    );

    return (
        <div>
            <PageHeader
                head={"JU System categories"}
                startAction={
                    <div style={{ width: 250 }}>
                        <Space.Compact size="large">
                            <Input
                                placeholder="Search category name here"
                                allowClear
                                onChange={
                                    (e) => handleOnSearchChange(e.target.value)
                                    // handleSearchText(e.target.value)
                                }
                                // onSearch={() => handleOnSearchChange()}
                            />
                        </Space.Compact>
                    </div>
                }
                action={<AddCategory />}
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <div>
                        {filters ? (
                            <Table
                                columns={columns}
                                dataSource={sortedFilteredCategories}
                            />
                        ) : (
                            <Table
                                columns={columns}
                                dataSource={sortedCategory}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Categories;
