import { Input, Modal } from "antd";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { confirmOrderPayment } from "../../api/paymentAPI";

const MakeOrderPayment = ({ orderID, transactionID }) => {
    // ########### Comp State ##############
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [placedLoading, setPlacedLoading] = useState(false);

    // ######### FUNC ################
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // ######### Pay order ##############
    const handleOrderPayment = async () => {
        if (!phone) {
            toast.error("Please enter phone number");
        } else if (phone?.length < 12) {
            toast.error("Sorry! Phone number must start with 255");
        } else {
            const payload = {
                phone: phone,
                order_id: orderID,
                uid: transactionID,
            };
            setPlacedLoading(true);
            const paymentResponse = await confirmOrderPayment(payload);
            if (paymentResponse?.data?.success == 1) {
                //
                toast.success("Order payment on progress!");
                setPhone("");
                setIsModalOpen(false);
                setPlacedLoading(false);
            } else {
                toast.error("Failed to make order payment, Please try again");
                setPlacedLoading(false);
            }
        }
    };

    return (
        <div>
            {" "}
            <>
                <button
                    type="button"
                    className="px-6 py-2 border rounded-md bg-violet-600 text-gray-50 border-violet-600"
                    onClick={showModal}
                >
                    Pay order
                </button>
                <Modal
                    title=""
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={{
                        className: "hidden",
                    }}
                    cancelButtonProps={{
                        className: "hidden",
                    }}
                >
                    <h4 className="text-lg font-semibold text-center pb-2 mb-2">
                        PROVIDE ORDER PAYMENT DETAILS
                    </h4>
                    <div className="w-full">
                        <div className="px-5">
                            <h3 style={{}}>
                                1) After clicking “Pay,” your mobile phone will
                                receive a USSD-Push request.
                            </h3>
                            <h3 style={{}}>
                                2) Once you’ve entered your PIN, the payment
                                will be confirmed.
                            </h3>
                            <h3 style={{}}>
                                3) Remember to keep your PIN secure and ensure a
                                stable network connection during this process.
                                Happy mobile payments! 📱💸
                            </h3>
                        </div>
                        <div className="py-2 flex flex-col justify-center items-center">
                            <div className="w-[90%] py-2">
                                <Input
                                    size="large"
                                    type="number"
                                    placeholder="Phone number 255XXXXXXXXX"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    className="w-full"
                                />
                            </div>
                            <div className="w-[90%] py-2 pt-4">
                                {placedLoading ? (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                    >
                                        Loading ...
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                        onClick={() => handleOrderPayment()}
                                    >
                                        Make Order Payment
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </div>
    );
};

export default MakeOrderPayment;
