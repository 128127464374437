import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Button, Modal, Table, Tag } from "antd";
import moment from "moment";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addAllOrders,
  addOrderDetails,
  selectAllOrders,
} from "../features/orderSlice";
import { addProducts, selectAllProducts } from "../features/productsSlice";
import { IoMdArrowDropup } from "react-icons/io";
import { addShops, selectShops } from "../features/shopSlice";
import {
  addCustomers,
  addVendors,
  selectCustomers,
  selectVendors,
} from "../features/userSlice";
import supabase from "../supabaseClient";
import {
  AttachMoneyOutlined,
  Groups2Outlined,
  Inventory2Outlined,
  PeopleOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import { Box } from "@mui/material";

const chartSetting = {
  yAxis: [
    {
      label: "Amount (TZS)",
    },
  ],
  width: 900,
  height: 400,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "rotate(-180deg) translate(0px, -40px)",
    },
  },
};

const formatter = new Intl.NumberFormat("en-US");

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Order #",
    dataIndex: "order_number",
    key: "order_number",
  },
  {
    title: "Ordered Items",
    dataIndex: "ordered_products",
    key: "ordered_products",
    render: (_, { ordered_products }) => (
      <>
        <ViewItems items={ordered_products} />
      </>
    ),
  },
  {
    title: "Total Price",
    dataIndex: "total_amount",
    key: "total_amount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Customer",
    key: "customer",
    render: (_, order) => (
      <>
        <CustomerInfo order={order} />
      </>
    ),
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (_, { created_at }) => (
      <>
        <p>{moment(created_at).format("DD-MM-YYYY")}</p>
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        <Status status={status} />
      </>
    ),
  },
  {
    title: "Payment",
    key: "isPaid",
    dataIndex: "isPaid",
    render: (_, { isPaid }) => (
      <>
        {!isPaid ? (
          <Tag color={"red"}>Not Paid</Tag>
        ) : (
          <Tag color={"green"}>Paid</Tag>
        )}
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, order) => <ViewOrder order={order} />,
  },
];

const CustomerInfo = ({ order }) => {
  return (
    <div>
      <p>
        {order?.customer?.first_name} {order?.customer?.last_name}
      </p>
      <p>{order?.customer?.phone}</p>
    </div>
  );
};

const Status = ({ status }) => {
  if (status === 1) {
    return <Tag color={"blue"}>Placed</Tag>;
  } else if (status === 2) {
    return <Tag color={"green"}>Accepted</Tag>;
  } else if (status === 3) {
    return <Tag color={"green"}>Shipped</Tag>;
  } else if (status === 4) {
    return <Tag color={"green"}>Delivered</Tag>;
  } else if (status === 5) {
    return <Tag color={"red"}>Cancelled</Tag>;
  } else {
    return <Tag color={"green"}>Refunded</Tag>;
  }
};

const ViewOrder = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewOrder = () => {
    dispatch(addOrderDetails(order));
    navigate(`/orders/${order?.id}`);
  };

  return (
    <Button
      type="text"
      shape="circle"
      className="flex justify-center"
      onClick={() => handleViewOrder()}
    >
      <FaEye className="text-red-500 text-xl cursor-pointer" />
    </Button>
  );
};

const ViewItems = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="px-6 py-2 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
        onClick={showModal}
      >
        View
      </button>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        width={600}
      >
        <h4 className="text-lg font-semibold text-center pb-2">ORDRED ITEMS</h4>
        <div
          className={
            items?.length > 2
              ? "h-[420px] px-3 pt-2 overflow-y-scroll"
              : "h-[420px] px-3 pt-2"
          }
        >
          <div className="flex flex-col max-w-3xl px-6 space-y-4 sm:p-10 bg-gray-50 text-gray-800">
            <ul className="flex flex-col divide-y divide-gray-300">
              {items && items.length > 0 ? (
                <>
                  {items.map((product, index) => {
                    const totalAmount = product?.price * product?.cart_quantity;
                    return (
                      <li
                        className="flex flex-col py-6 sm:flex-row sm:justify-between"
                        key={index}
                      >
                        <div className="flex w-full space-x-2 sm:space-x-4">
                          <img
                            className="flex-shrink-0 object-cover w-20 h-20 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                            src={product?.bannerImage}
                            alt="car spare"
                          />
                          <div className="w-full pb-4">
                            <div>
                              <p className="capitalize font-semibold">
                                {product?.title} {product?.condition}
                              </p>
                              <p>
                                {product?.brand?.brandName}{" "}
                                {product?.model?.modelName} {product?.year}
                              </p>
                              <p>TZS {formatter.format(product?.price || 0)}</p>
                              <p className="">
                                Total Items : {product?.cart_quantity}
                              </p>
                            </div>
                            <div className="pt-1">
                              <p className="text-semibold">
                                Total Price : {formatter.format(totalAmount)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <li className="text-center">There is no products</li>
              )}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getOrders = async () => {
      let { data: orders, error } = await supabase
        .from("orders")
        .select("*, customer(*)");

      if (error) {
        console.log(error.message);
      } else {
        // console.log(orders);
        dispatch(addAllOrders(orders));
      }
    };

    const getCustomers = async () => {
      let { data: customUsers, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("role", "client");

      if (customUsers) {
        dispatch(addCustomers(customUsers));
      } else {
        console.log(error.message);
      }
    };

    const getVendors = async () => {
      let { data: vendors, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("role", "vendor");

      if (vendors) {
        dispatch(addVendors(vendors));
      } else {
        console.log(error.message);
      }
    };

    const getShops = async () => {
      let { data: shops, error } = await supabase
        .from("shops")
        .select("*, shopOwner(*)");

      if (shops) {
        dispatch(addShops(shops));
      } else {
        console.log(error.message);
      }
    };

    const getProducts = async () => {
      let { data: products, error } = await supabase
        .from("products")
        .select("*, model(*), brand(*), category(*)");

      if (products) {
        dispatch(addProducts(products));
      } else {
        console.log(error.message);
      }
    };

    getOrders();
    getCustomers();
    getVendors();
    getShops();
    getProducts();
  }, [dispatch]);

  const orders = useSelector(selectAllOrders);
  const customers = useSelector(selectCustomers);
  const vendors = useSelector(selectVendors);
  const shops = useSelector(selectShops);
  const products = useSelector(selectAllProducts);

  const allOrders = orders
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const latestFiveOrders = allOrders.slice(-5);

  const sortedOrders = latestFiveOrders.map((order, index) => {
    const key = index + 1;
    return { ...order, key };
  });

  const placedOrders = orders.filter((order) => order.status === 1);

  const acceptedOrders = orders.filter((order) => order.status === 2);

  const shippedOrders = orders.filter((order) => order.status === 3);

  const deliveredOrders = orders.filter((order) => order.status === 4);

  const cancelledOrders = orders.filter((order) => order.status === 5);

  const refundedOrders = orders.filter((order) => order.status === 6);

  const paidOrders = orders.filter((order) => order.isPaid === true);

  const totalSales = paidOrders.reduce(
    (sum, order) => sum + order.total_amount,
    0
  );

  // Initialize an array to store sales for each month
  const monthlySales = Array(12).fill(0);

  paidOrders.forEach((order) => {
    const orderDate = order.created_at;
    const month = moment(orderDate).format("M");

    if (moment().isSame(moment(orderDate), "year")) {
      monthlySales[month] += order.total_amount;
    }
  });

  const dataset = monthlySales.map((sales, monthIndex) => ({
    sales,
    month: getMonthName(monthIndex + 1), // Convert the month index to a month name
  }));

  function getMonthName(monthIndex) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex - 1];
  }

  const valueFormatter = (value) => `${formatter.format(value)}`;

  return (
    <div className="w-[100%] px-4">
      <h4
        className="text-lg font-semibold py-4"
        style={{ color: colors.primary }}
      >
        JU Dashboard
      </h4>
      <div className="w-[100%] flex flex-row gap-2 py-2">
        <div className="w-[20%] h-40 border rounded-lg bg-[#d8e2fc] p-3 relative">
          <div>
            <div className="w-full flex items-center justify-between">
              <p className="text-base py-3 font-semibold">Total Sales</p>
              <div className="h-10 w-10 rounded-full bg-orange-500 flex justify-center items-center">
                <p className="text-white text-sm">
                  <AttachMoneyOutlined />
                </p>
              </div>
            </div>
            <p className="text-4xl font-semibold py-1">
              {formatter.format(totalSales)} TZS
            </p>
            <span className="py-2 flex items-center gap-x-2">
              <h1>Since last week</h1>
              <span className="flex items-center justify-center bg-white rounded-md px-3 text-blue-500 border border-blue-300/50">
                13.4%
                <IoMdArrowDropup />
              </span>
            </span>
          </div>
        </div>
        <div className="w-[20%] h-40 border rounded-lg bg-[#f9e0e1] p-3 relative">
          <div>
            <div className="w-full flex items-center justify-between">
              <p className="text-base py-3 font-semibold">Total Customers</p>
              <div className="h-10 w-10 rounded-full bg-green-500 flex justify-center items-center">
                <p className="text-white">
                  <PeopleOutlined />
                </p>
              </div>
            </div>
            <p className="text-4xl font-semibold py-1">{customers?.length}</p>
            <span className="py-2 flex items-center gap-x-2">
              <h1>Since last week</h1>
              <span className="flex items-center justify-center bg-white rounded-md px-3 text-blue-500 border border-blue-300/50">
                13.4%
                <IoMdArrowDropup />
              </span>
            </span>
          </div>
        </div>
        <div className="w-[20%] h-40 border rounded-lg bg-[#daeaea] p-3 relative">
          <div>
            <div className="w-full flex items-center justify-between">
              <p className="text-base py-3 font-semibold">Total Vendors</p>
              <div className="h-10 w-10 rounded-full bg-blue-500 flex justify-center items-center">
                <p className="text-white">
                  <Groups2Outlined />{" "}
                </p>
              </div>
            </div>

            <p className="text-4xl font-semibold py-1">{vendors?.length}</p>
            <span className="py-2 flex items-center gap-x-2">
              <h1>Since last week</h1>
              <span className="flex items-center justify-center bg-white rounded-md px-3 text-blue-500 border border-blue-300/50">
                13.4%
                <IoMdArrowDropup />
              </span>
            </span>
          </div>
        </div>
        <div className="w-[20%] h-40 border rounded-lg bg-[#e3dbfa] p-3 relative">
          <div>
            <div className="w-full flex items-center justify-between">
              <p className="text-base py-3 font-semibold">Total Stores</p>
              <div className="h-10 w-10 rounded-full bg-violet-500 flex justify-center items-center">
                <p className="text-white">
                  <StoreOutlined />{" "}
                </p>
              </div>
            </div>

            <p className="text-4xl font-semibold py-1">{shops?.length}</p>
            <span className="py-2 flex items-center gap-x-2">
              <h1>Since last week</h1>
              <span className="flex items-center justify-center bg-white rounded-md px-3 text-blue-500 border border-blue-300/50">
                13.4%
                <IoMdArrowDropup />
              </span>
            </span>
          </div>
        </div>
        <div className="w-[20%] h-40 border rounded-lg bg-[#fbe2f4] p-3 relative">
          <div>
            <div className="w-full flex items-center justify-between">
              <p className="text-base py-3 font-semibold">Total Products</p>
              <div className="h-10 w-10 rounded-full bg-sky-500 flex justify-center items-center">
                <p className="text-white">
                  <Inventory2Outlined />{" "}
                </p>
              </div>
            </div>

            <p className="text-4xl font-semibold py-1">{products?.length}</p>
            <span className="py-2 flex items-center gap-x-2">
              <h1>Since last week</h1>
              <span className="flex items-center justify-center bg-white rounded-md px-3 text-blue-500 border border-blue-300/50">
                13.4%
                <IoMdArrowDropup />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex flex-row gap-x-2 py-4 mt-5">
        <div className="w-[60%] px-3 py-2 bg-gray-100 border border-gray-300 rounded-xl">
          <h4 className="text-2xl font-semibold">Sales Statistics</h4>
          <div className="w-[85%] px-2">
            <Box
              sx={{
                ".MuiBarElement-root": {
                  width: "40px !important",
                },
              }}
            >
              <BarChart
                dataset={dataset}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "month",
                    categoryGapRatio: 0.8,
                    barGapRatio: 0.4,
                  },
                ]}
                color={"#faf5ff"}
                borderRadius={21}
                margin={{ left: 92 }}
                series={[
                  {
                    dataKey: "sales",
                    label: "Revenue",
                    valueFormatter,
                  },
                ]}
                {...chartSetting}
              />
            </Box>
          </div>
        </div>
        <div className="w-[40%] py-2 px-2 border bg-[#d8e2fc] border-blue-500/50 rounded-lg">
          <h4 className="text-2xl font-semibold">
            Orders Statistics ({orders.length})
          </h4>
          <div className="px-2">
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: placedOrders.length,
                      label: "Placed",
                    },
                    {
                      id: 1,
                      value: acceptedOrders.length,
                      label: "Accepted",
                    },
                    {
                      id: 2,
                      value: shippedOrders.length,
                      label: "Shipped",
                    },
                    {
                      id: 2,
                      value: deliveredOrders.length,
                      label: "Delivered",
                    },
                    {
                      id: 2,
                      value: cancelledOrders.length,
                      label: "Cancelled",
                    },
                    {
                      id: 2,
                      value: refundedOrders.length,
                      label: "Refunded",
                    },
                  ],
                },
              ]}
              width={440}
              height={240}
              sx={{ mr: 4, mt: 9 }}
            />
          </div>
        </div>
      </div>
      <div className="w-[100%] py-3">
        <h4 className="font-semibold py-2">Latest Orders</h4>
        <div>
          <Table columns={columns} dataSource={sortedOrders} size="small" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
