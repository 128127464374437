import { createSlice } from "@reduxjs/toolkit";
import { updateArrObj } from "../helpers/arrayHelpers";

const initialState = {
    products: [],
    filteredProducts: [],
    productDetails: "",
    productImages: [],
};

const productsSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        addProducts(state, action) {
            state.products = action.payload;
        },
        updateProducts(state, action) {
            let allProducts = state.products;
            let newProduct = action.payload;
            let updatedProducts = updateArrObj(
                "id",
                newProduct[0],
                allProducts
            );
            state.products = updatedProducts;
        },

        addSearchedProducts(state, action) {
            state.filteredProducts = action.payload;
        },

        addProductDetails(state, action) {
            state.productDetails = action.payload;
        },

        addProductImages(state, action) {
            state.productImages = action.payload;
        },
    },
});

export const {
    addProducts,
    updateProducts,
    addSearchedProducts,
    addProductDetails,
    addProductImages,
} = productsSlice.actions;

export const selectAllProducts = (state) => state.product.products;
export const selectSearchedProducts = (state) => state.product.filteredProducts;
export const selectProductDetails = (state) => state.product.productDetails;
export const selectProductImages = (state) => state.product.productImages;

export default productsSlice.reducer;
