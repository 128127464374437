import { Button, Image, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addShopProductDetails,
  addShopProducts,
  selectShopProducts,
} from "../../features/shopSlice";
import supabase from "../../supabaseClient";

const formatter = new Intl.NumberFormat("en-US");

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Name",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Image",
    dataIndex: "bannerImage",
    key: "bannerImage",
    render: (_, { bannerImage }) => (
      <>
        <ViewImage image={bannerImage} />
      </>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Brand",
    key: "brand",
    render: (_, product) => <ViewBrand product={product} />,
  },
  {
    title: "Model",
    key: "model",
    render: (_, product) => <ViewModel product={product} />,
  },
  {
    title: "Condition",
    dataIndex: "condition",
    key: "condition",
    render: (text) => <p className="capitalize">{text}</p>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        <Status status={status} />
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, product) => <ViewProduct product={product} />,
  },
];

const Status = ({ status }) => {
  if (status === "pending") {
    return <Tag color={"blue"}>Pending</Tag>;
  } else if (status === "approved") {
    return <Tag color={"green"}>Approved</Tag>;
  } else {
    return <Tag color={"red"}>Suspended</Tag>;
  }
};

const ViewImage = ({ image }) => {
  return <Image width={80} height={80} src={image} />;
};

const ViewBrand = ({ product }) => {
  console.log(product);
  return <p>{product?.brand?.brandName}</p>;
};

const ViewModel = ({ product }) => {
  console.log(product);
  return <p>{product?.model?.modelName}</p>;
};

const ViewProduct = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewProduct = () => {
    dispatch(addShopProductDetails(product));
    navigate(`/shops/${product?.shop}/shop-products/${product?.id}`);
  };

  return (
    <Button
      type="text"
      shape="circle"
      className="flex justify-center"
      onClick={() => handleViewProduct()}
    >
      <FaEye className="text-red-500 text-xl cursor-pointer" />
    </Button>
  );
};

const ShopProducts = () => {
  const dispatch = useDispatch();
  const { shopID } = useParams();

  useEffect(() => {
    const getShopProducts = async () => {
      let { data: products, error } = await supabase
        .from("products")
        .select("*, model(*), brand(*), category(*)")
        .eq("shop", shopID);

      if (products) {
      // console.log(products);
        dispatch(addShopProducts(products));
      } else {
        console.log(error.message);
      }
    };

    getShopProducts();
  }, [dispatch]);

  const shopProducts = useSelector(selectShopProducts);
  const allProducts = shopProducts
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const sortedProducts = allProducts.map((order, index) => {
    const key = index + 1;
    return { ...order, key };
  });

  return (
    <div className="w-[100%]">
      <h4 className="text-lg font-semibold text-center py-2"></h4>
      <Table columns={columns} dataSource={sortedProducts} />
    </div>
  );
};

export default ShopProducts;
