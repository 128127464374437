import React, { useEffect, useState } from "react";
import { Button, Input, Switch, Popconfirm, Table, Space } from "antd";
import { RiDeleteBin5Line } from "react-icons/ri";
import supabase from "../../supabaseClient";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addBrandDetails,
    addFilteredModels,
    addModels,
    selectBrandDetails,
    selectFilteredModels,
    selectModels,
} from "../../features/brandSlice";
import EditModel from "./EditModel";
import AddModel from "./AddModel";
import { toast } from "react-hot-toast";
import DataLoader from "../../components/DataLoader";
import PageHeader from "../../components/PageHeader";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Model Name",
        dataIndex: "modelName",
        key: "modelName",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, model) => (
            <>
                <ModelStatus model={model} />
            </>
        ),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Actions",
        key: "action",
        render: (_, model) => (
            <p className="flex flex-row gap-2 justify-start">
                <EditModel model={model} />
                <DeleteModel model={model} />
            </p>
        ),
    },
];

const ModelStatus = ({ model }) => {
    const dispatch = useDispatch();

    const getModels = async () => {
        let { data: models, error } = await supabase
            .from("models")
            .select("*, brands(*)")
            .eq("brandId", model?.brandId);

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addModels(models));
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("models")
                .update({
                    status: !model?.status,
                })
                .eq("id", model?.id)
                .select("*");

            if (data) {
                //
                toast.success("Status is changed successfully");
                try {
                    getModels();
                } catch (error) {
                    console.log(error);
                }
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                model?.status ? "deactivate" : "activate"
            } this model?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={model?.status}
                className={model?.status ? null : `bg-zinc-300 rounded-full`}
            />
        </Popconfirm>
    );
};

const DeleteModel = ({ model }) => {
    const dispatch = useDispatch();

    const getModels = async () => {
        let { data: categories, error } = await supabase
            .from("models")
            .select("*, brands(*)")
            .eq("brandId", model?.brandId);

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addModels(categories));
        }
    };

    const confirmDelete = async () => {
        //detelete category
        try {
            const { data, error } = await supabase
                .from("models")
                .delete()
                .eq("id", model?.id)
                .select("*");

            if (data) {
                //
                toast.success("Model is deleted successfully");
                try {
                    getModels();
                } catch (error) {
                    console.log(error);
                }
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Delete Model"
            description="Are you sure to delete this model?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={() => confirmDelete()}
        >
            <Button type="text" shape="circle" className="flex justify-center">
                <RiDeleteBin5Line className="text-red-500 text-xl cursor-pointer" />
            </Button>
        </Popconfirm>
    );
};

const Models = () => {
    const dispatch = useDispatch();
    const { brandID } = useParams();

    // const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getModels = async () => {
            setDataLoad(true);
            let { data: models, error } = await supabase
                .from("models")
                .select("*, brands(*)")
                .eq("brandId", brandID);

            if (error) {
                dispatch(addModels([]));
                setDataLoad(false);
                console.log(error.message);
            } else {
                //console.log(models);
                setDataLoad(false);
                dispatch(addModels(models));
            }
        };

        const getBrand = async () => {
            let { data: brands, error } = await supabase
                .from("brands")
                .select("*")
                .eq("id", brandID);

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addBrandDetails(brands[0]));
            }
        };

        getModels();
        getBrand();
    }, [dispatch]);

    const models = useSelector(selectModels);
    const brand = useSelector(selectBrandDetails);

    const allModels = models
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedModels = allModels.map((model, index) => {
        const key = index + 1;
        return { ...model, key };
    });

    const handleOnSearchChange = (searchText) => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedModels = models.filter((model) => {
                const name = model?.modelName.toLocaleLowerCase();
                return name.includes(text);
            });

            // Update state with filtered models
            dispatch(addFilteredModels(searchedModels));
            setFilters(true);
        } else {
            // Update state with filtered models
            dispatch(addFilteredModels([]));
            setFilters(false);
        }
    };

    // const handleSearchText = (value) => {
    //     if (value) {
    //         setSearchText(value);
    //     } else {
    //         // Update state with filtered models
    //         dispatch(addFilteredModels([]));
    //         setFilters(false);
    //         setSearchText(value);
    //     }
    // };

    const filteredModels = useSelector(selectFilteredModels);

    const allFilteredModels = filteredModels
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredModels = allFilteredModels.map((model, index) => {
        const key = index + 1;
        return { ...model, key };
    });

    return (
        <div>
            <PageHeader
                head={`JU ${brand?.brandName} brand Models`}
                startAction={
                    <div>
                        <Space.Compact size="large">
                            <Input
                                placeholder="Search model name here"
                                allowClear
                                onChange={
                                    (e) => handleOnSearchChange(e.target.value)
                                    // handleSearchText(e.target.value)
                                }
                                // onSearch={() => handleOnSearchChange()}
                            />
                        </Space.Compact>
                    </div>
                }
                action={<AddModel />}
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    {filters ? (
                        <Table
                            columns={columns}
                            dataSource={sortedFilteredModels}
                        />
                    ) : (
                        <Table columns={columns} dataSource={sortedModels} />
                    )}
                </>
            )}
        </div>
    );
};

export default Models;
