import React, { useEffect } from "react";
import { AccountCircle, Email, Phone } from "@mui/icons-material";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { addProfile, selectProfile } from "../../features/userSlice";
import { isEmpty } from "lodash";

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserDetails = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (user) {
        console.log(user);
        if (!isEmpty(user.user_metadata)) {
          dispatch(addProfile(user.user_metadata));
        } else {
          dispatch(
            addProfile({
              name: "Jack Umeme",
              role: "Super Admin",
              email: "admin@jackumeme.co.tz",
              phone: "0700000000",
            })
          );
        }
      }
    };

    getUserDetails();
  }, [dispatch]);

  const profile = useSelector(selectProfile);

  return (
    <div classsName="w-[100%]">
      <div className="flex flex-row justify-center items-center py-6">
        <div className="w-[50%] h-48 bg-[#faf5ff] rounded-md flex flex-row gap-2">
          <div className="w-[40%] pt-8">
            <div className="w-32 h-32 mx-auto rounded-full bg-gray-500 aspect-square py-6"></div>
          </div>
          <div className="w-[60%] flex flex-col space-y-4 px-4 py-6">
            <div>
              <h2 className="text-2xl font-semibold">{profile?.name}</h2>
              <span className="text-sm text-center">{profile?.role}</span>
            </div>
            <div className="py-2">
              <span className="flex items-center space-x-2">
                <Email fontSize="small" />
                <span className="">{profile?.email}</span>
              </span>
              <span className="flex items-center space-x-2">
                <Phone fontSize="small" />
                <span className="">{profile?.phone}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
