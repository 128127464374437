import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from "../features/authSlice";
import posReducer from "../features/posSlice";
import brandReducer from "../features/brandSlice";
import orderReducer from "../features/orderSlice";
import userReducer from "../features/userSlice";
import shopReducer from "../features/shopSlice";
import productReducer from "../features/productsSlice";
import promotionReducer from "../features/promotionSlice";
import chatsReducer from "../features/chatsSlice";
import deliveryReducer from "../features/deliverySlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    pos: posReducer,
    brand: brandReducer,
    order: orderReducer,
    user: userReducer,
    shop: shopReducer,
    product: productReducer,
    promotion: promotionReducer,
    chat: chatsReducer,
    delivery: deliveryReducer,
  },
  middleware: [thunk]
});