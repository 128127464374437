import React, { useEffect, useState } from "react";
import { Button, Modal, Segmented, Table, Tag } from "antd";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addOrderDetails } from "../../features/orderSlice";
import { addShopOrders, selectShopOrders } from "../../features/shopSlice";
import supabase from "../../supabaseClient";

const formatter = new Intl.NumberFormat("en-US");

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Order #",
    key: "order_number",
    render: (_, order) => <p>{order?.orders?.order_number || ""}</p>,
  },
  {
    title: "Ordered Items",
    dataIndex: "ordered_products",
    key: "ordered_products",
    render: (_, { ordered_products }) => (
      <>
        <ViewItems items={ordered_products} />
      </>
    ),
  },
  {
    title: "Total Price",
    dataIndex: "total_amount",
    key: "total_amount",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Customer",
    key: "customer",
    render: (_, order) => (
      <>
        <CustomerInfo order={order} />
      </>
    ),
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (_, { created_at }) => (
      <>
        <p>{moment(created_at).format("DD-MM-YYYY")}</p>
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        <Status status={status} />
      </>
    ),
  },
  {
    title: "Payment",
    key: "isPaid",
    dataIndex: "isPaid",
    render: (_, { isPaid }) => (
      <>
        {!isPaid ? (
          <Tag color={"red"}>Not Paid</Tag>
        ) : (
          <Tag color={"green"}>Paid</Tag>
        )}
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, order) => <ViewOrder order={order} />,
  },
];

const CustomerInfo = ({ order }) => {
  
  return (
    <div>
      <p>
        {order?.orders?.customer?.first_name} {order?.orders?.customer?.last_name}
      </p>
      <p>{order?.orders?.customer?.phone}</p>
    </div>
  );
};

const Status = ({ status }) => {
  if (status === 1) {
    return <Tag color={"blue"}>Placed</Tag>;
  } else if (status === 2) {
    return <Tag color={"green"}>Accepted</Tag>;
  } else if (status === 3) {
    return <Tag color={"green"}>Shipped</Tag>;
  } else if (status === 4) {
    return <Tag color={"green"}>Delivered</Tag>;
  } else if (status === 5) {
    return <Tag color={"red"}>Cancelled</Tag>;
  } else {
    return <Tag color={"green"}>Refunded</Tag>;
  }
};

const ViewOrder = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewOrder = () => {
    dispatch(addOrderDetails(order));
    navigate(`/orders/${order?.id}`);
  };

  return (
    <Button
      type="text"
      shape="circle"
      className="flex justify-center"
      onClick={() => handleViewOrder()}
    >
      <FaEye className="text-red-500 text-xl cursor-pointer" />
    </Button>
  );
};

const ViewItems = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <>
      <button
        type="button"
        className="px-6 py-2 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
        onClick={showModal}
      >
        View
      </button>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        // style={{ top: 30 }}
        width={600}
      >
        <h4 className="text-lg font-semibold text-center pb-2">ORDRED ITEMS</h4>
        <div
          className={
            items?.length > 2
              ? "h-[420px] px-3 pt-2 overflow-y-scroll"
              : "h-[420px] px-3 pt-2"
          }
        >
          <div className="flex flex-col max-w-3xl px-6 space-y-4 sm:p-10 bg-gray-50 text-gray-800">
            <h2 className="text-xl font-semibold text-center"></h2>
            <ul className="flex flex-col divide-y divide-gray-300">
              {items && items.length > 0 ? (
                <>
                  {items.map((product, index) => {
                    const totalAmount = product?.price * product?.cart_quantity;
                    return (
                      <li
                        className="flex flex-col py-6 sm:flex-row sm:justify-between"
                        key={index}
                      >
                        <div className="flex w-full space-x-2 sm:space-x-4">
                          <img
                            className="flex-shrink-0 object-cover w-20 h-20 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                            src={product?.bannerImage}
                            alt="car spare"
                          />
                          <div className="w-full pb-4">
                            <div>
                              <p className="capitalize font-semibold">
                                {product?.title} {product?.condition}
                              </p>
                              <p>
                                {product?.brand?.brandName}{" "}
                                {product?.model?.modelName} {product?.year}
                              </p>
                              <p>TZS {formatter.format(product?.price || 0)}</p>
                              <p className="">
                                Total Items : {product?.cart_quantity}
                              </p>
                            </div>
                            <div className="pt-1">
                              <p className="text-semibold">
                                Total Price : {formatter.format(totalAmount)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <li className="text-center">There is no products</li>
              )}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ShopOrders = () => {
  const dispatch = useDispatch();
  const { shopID } = useParams();

  const [orderStatus, setOrderStatus] = useState(0);

  useEffect(() => {
    const getShopOrders = async () => {
      let { data: shop_orders, error } = await supabase
        .from("shop_orders")
        .select("*, orders(*, customer(*))")
        .eq("shop_id", shopID || "");

      if (shop_orders) {
        dispatch(addShopOrders(shop_orders));
      } else {
        console.log(error.message);
      }
    };

    getShopOrders();
  }, [dispatch]);

  const shopOrders = useSelector(selectShopOrders);
  const allOrders = shopOrders
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const filteredOrders = allOrders.filter((order) => {
    if (orderStatus < 1) {
      // If value is less than 1, return all orders
      return true;
    } else {
      // Otherwise, filter based on the order's status
      return order.status === orderStatus;
    }
  });

  const sortedOrders = filteredOrders.map((order, index) => {
    const key = index + 1;
    return { ...order, key };
  });

  const placedOrders = shopOrders.filter((order) => order.status === 1);

  const acceptedOrders = shopOrders.filter((order) => order.status === 2);

  const shippedOrders = shopOrders.filter((order) => order.status === 3);

  const deliveredOrders = shopOrders.filter((order) => order.status === 4);

  const cancelledOrders = shopOrders.filter((order) => order.status === 5);

  const refundedOrders = shopOrders.filter((order) => order.status === 6);

  const onStatusChange = (value) => {
    // console.log(value);
    setOrderStatus(value);
  };

  return (
    <>
      <div className="">
        <Segmented
          options={[
            {
              label: `All (${shopOrders.length})`,
              value: 0,
            },
            {
              label: `Placed (${placedOrders.length})`,
              value: 1,
            },
            {
              label: `Accepted (${acceptedOrders.length})`,
              value: 2,
            },
            {
              label: `Shipped (${shippedOrders.length})`,
              value: 3,
            },
            {
              label: `Delivered (${deliveredOrders.length})`,
              value: 4,
            },
            {
              label: `Cancelled (${cancelledOrders.length})`,
              value: 5,
            },
            {
              label: `Refunded (${refundedOrders.length})`,
              value: 6,
            },
          ]}
          size="large"
          value={orderStatus}
          onChange={onStatusChange}
          className="w-[100%]"
        />
        <h4 className="text-lg font-semibold text-center py-4"></h4>
        <Table columns={columns} dataSource={sortedOrders} />
      </div>
    </>
  );
};

export default ShopOrders;
