import React, { useEffect, useState } from "react";
import { DoNotDisturbAlt, Verified } from "@mui/icons-material";
import { Button, Popconfirm, Segmented, Switch, Table, Tag } from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addShopDetails } from "../../features/shopSlice";
import supabase from "../../supabaseClient";
import { addVendorShops, selectVendorShops } from "../../features/userSlice";
import { toast } from "react-hot-toast";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Shop",
    dataIndex: "shopName",
    key: "shopName",
  },
  {
    title: "Location",
    dataIndex: "shopLocation",
    key: "shopLocation",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    key: "isActive",
    render: (_, { isActive }) => (
      <>
        {!isActive ? (
          <Tag color={"red"}>Closed</Tag>
        ) : (
          <Tag color={"green"}>Open</Tag>
        )}
      </>
    ),
  },
  {
    title: "Verification",
    key: "isVerified",
    dataIndex: "isVerified",
    render: (_, { isVerified }) => (
      <>
        {!isVerified ? (
          <p className="flex flex-row justify center items-center">
            <DoNotDisturbAlt className="text-blue-500" />
          </p>
        ) : (
          <p className="flex flex-row justify center items-center">
            <Verified className="text-green-500" />
          </p>
        )}
      </>
    ),
  },
  {
    title: "Suspension",
    key: "isSuspended",
    render: (_, shop) => (
      <>
        <SuspensionStatus shop={shop} />
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, shop) => <ViewShop shop={shop} />,
  },
];

const ViewShop = ({ shop }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewShop = () => {
    dispatch(addShopDetails(shop));
    navigate(`/shops/${shop?.id}`);
  };

  return (
    <Button
      type="text"
      shape="circle"
      className="flex justify-center"
      onClick={() => handleViewShop()}
    >
      <FaEye className="text-red-500 text-xl cursor-pointer" />
    </Button>
  );
};

const SuspensionStatus = ({ shop }) => {
  const dispatch = useDispatch();
  const { vendorID } = useParams();

  const getShops = async () => {
    let { data: shops, error } = await supabase
      .from("shops")
      .select("*, shopOwner(*)")
      .eq("shopOwner", vendorID);

    if (shops) {
      dispatch(addVendorShops(shops));
    } else {
      console.log(error.message);
    }
  };

  const changeStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("shops")
        .update({
          isSuspended: !shop?.isSuspended,
        })
        .eq("id", shop?.id)
        .select("*");

      if (data) {
        //
        getShops();
        toast.success("Suspension status is changed successfully");
      } else {
        toast.error(error.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Popconfirm
      title="Change Suspension Status"
      description={`Are you sure you want to ${
        shop?.isSuspended ? "suspend" : "unsuspend"
      } this shop?`}
      okText="Yes"
      cancelText="No"
      okButtonProps={{
        className: "bg-blue-500",
      }}
      onConfirm={changeStatus}
    >
      <Switch
        checked={shop?.isSuspended}
        className={shop?.isSuspended ? null : `bg-zinc-300 rounded-full`}
      />
    </Popconfirm>
  );
};

const VendorShops = () => {
  const dispatch = useDispatch();
  const { vendorID } = useParams();

  const [shopStatus, setShopStatus] = useState("");

  useEffect(() => {
    const getShops = async () => {
      let { data: shops, error } = await supabase
        .from("shops")
        .select("*, shopOwner(*)")
        .eq("shopOwner", vendorID);

      if (shops) {
        dispatch(addVendorShops(shops));
      } else {
        console.log(error.message);
      }
    };

    getShops();
  }, [dispatch]);

  const shops = useSelector(selectVendorShops);
  const allShops = shops
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const statusFilters = {
    isClosed: (shop) => !shop.isActive,
    isActive: (shop) => shop.isActive,
    isVerified: (shop) => shop.isVerified,
    isSuspended: (shop) => shop.isSuspended,
  };

  const filteredShops = allShops.filter((shop) => {
    if (shopStatus && statusFilters[shopStatus]) {
      return statusFilters[shopStatus](shop);
    } else {
      // Include all items in the result if shopStatus is undefined or invalid
      return true;
    }
  });

  const sortedShops = filteredShops.map((shop, index) => {
    const key = index + 1;
    return { ...shop, key };
  });

  const openedShops = shops.filter((shop) => shop.isActive === true);

  const closedShops = shops.filter((shop) => shop.isActive === false);

  const verifiedShops = shops.filter((shop) => shop.isVerified === true);

  const suspendedShops = shops.filter((shop) => shop.isSuspended === true);

  const onStatusChange = (value) => {
    // console.log(value);
    setShopStatus(value);
  };

  return (
    <div className="py-3">
      <Segmented
        options={[
          {
            label: `All Shops (${shops.length})`,
            value: "",
          },
          {
            label: `Opened Shops (${openedShops.length})`,
            value: "isActive",
          },
          {
            label: `Closed Shops (${closedShops.length})`,
            value: "isClosed",
          },
          {
            label: `Verified Shops (${verifiedShops.length})`,
            value: "isVerified",
          },
          {
            label: `Suspended Shops (${suspendedShops.length})`,
            value: "isSuspended",
          },
        ]}
        size="large"
        value={shopStatus}
        onChange={onStatusChange}
        className="w-[100%]"
      />
      <h4 className="text-lg font-semibold text-center py-4"></h4>
      <Table columns={columns} dataSource={sortedShops} />
    </div>
  );
};

export default VendorShops;
