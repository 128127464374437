import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import { useLocation } from "react-router-dom";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const AppLayout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { pathname } = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Box
            sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                bottom: "100%",
                right: 0,
                left: 0,
            }}
            className=" w-screen md:w-full"
        >
            {/* ############### topbar ############### */}
            <TopBar
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
            />

            {/* ############### sidebar ###############*/}
            <SideBar mobileOpen={mobileOpen} />

            {/* ############### content ###############*/}
            <Box component="main" sx={{ bgcolor: "white", flexGrow: 1 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
};

export default AppLayout;
