import { createClient } from "@supabase/supabase-js";

//development project
// const supabase = createClient(
//   "https://xeirzgtwdcenotpyvmmg.supabase.co",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhlaXJ6Z3R3ZGNlbm90cHl2bW1nIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg5OTU0MjMsImV4cCI6MjAwNDU3MTQyM30.7s6lO5SLpLA3w20-_vp6qyThYpFXr231Y0v23OnlF5M"
// );

const supabase = createClient(
  "https://secure.jackumeme.co.tz",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0"
);

export default supabase;
