import { createSlice } from "@reduxjs/toolkit";
import { removeArrObj, updateArrObj } from "../helpers/arrayHelpers";

const initialState = {
    brands: [],
    filteredBrands: [],
    brandDetails: "",
    models: [],
    filteredModels: [],
};

const brandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        addBrands(state, action) {
            state.brands = action.payload;
        },
        addBrand(state, action) {
            let allBrands = state.brands;
            let newBrand = action.payload.data;
            console.log(newBrand);
            let updatedBrands = [...new Set([...newBrand, ...allBrands])];
            state.brands = updatedBrands;
        },
        updateBrands(state, action) {
            let allBrands = state.brands;
            let newBrand = action.payload;
            let updatedBrands = updateArrObj("id", newBrand.data[0], allBrands);
            state.brands = updatedBrands;
        },
        removeBrand: (state, actions) => {
            let payload = actions.payload;
            let brands = removeArrObj("id", payload, state.brands);
            state.brands = brands;
        },

        addFilteredBrands(state, action) {
            state.filteredBrands = action.payload;
        },

        addBrandDetails(state, action) {
            state.brandDetails = action.payload;
        },

        addModels(state, action) {
            state.models = action.payload;
        },

        addFilteredModels(state, action) {
            state.filteredModels = action.payload;
        },
    },
});

export const {
    addBrands,
    addBrand,
    updateBrands,
    removeBrand,
    addFilteredBrands,
    addBrandDetails,
    addModels,
    addFilteredModels,
} = brandSlice.actions;

export const selectBrands = (state) => state.brand.brands;
export const selectFilteredBrands = (state) => state.brand.filteredBrands;
export const selectBrandDetails = (state) => state.brand.brandDetails;
export const selectModels = (state) => state.brand.models;
export const selectFilteredModels = (state) => state.brand.filteredModels;

export default brandSlice.reducer;
