import React, { useState } from "react";
import { Input, Modal } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import Add from "@mui/icons-material/Add";
import { toast } from "react-hot-toast";
import { addPrices } from "../../features/deliverySlice";

const { TextArea } = Input;

const AddDeliveryPrice = () => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [region, setRegion] = useState("");
    const [district, setDistrict] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSavePrice = async () => {
        //
        const getPrices = async () => {
            let { data: delivery_prices, error } = await supabase
                .from("delivery_prices")
                .select("*");

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addPrices(delivery_prices));
            }
        };

        if (!region) {
            toast.error("Please select region");
        } else if (!price) {
            toast.error("Please enter price");
        } else {
            setLoading(true);
            //send data
            try {
                const { data, error } = await supabase
                    .from("delivery_prices")
                    .insert([
                        {
                            region,
                            district,
                            price,
                            description,
                        },
                    ])
                    .select();

                if (data) {
                    //
                    setRegion("");
                    setDistrict("");
                    setPrice("");
                    setDescription("");
                    getPrices();
                    toast.success("Delivery price is saved successfully");
                    setLoading(false);
                } else {
                    toast.error(error.message);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    return (
        <>
            <div
                onClick={showModal}
                className="h-10 w-40 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white"
            >
                <Add className="mt-2 py-0.5" />{" "}
                <p className="py-2"> Delivery Price</p>
            </div>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                width={700}
            >
                <h4 className="text-center font-semibold text-xl">
                    Setup New Delivery Price
                </h4>
                <div className="py-2 flex flex-col justify-center items-center">
                    <div className="w-[90%] py-2">
                        <Input
                            size="large"
                            placeholder="Region"
                            onChange={(e) => setRegion(e.target.value)}
                            value={region}
                            className="w-full"
                        />
                    </div>
                    <div className="w-[90%] py-2">
                        <Input
                            size="large"
                            placeholder="District"
                            onChange={(e) => setDistrict(e.target.value)}
                            value={district}
                            className="w-full"
                        />
                    </div>
                    <div className="w-[90%] py-2">
                        <Input
                            size="large"
                            placeholder="Delivery Price"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                            type="number"
                            className="w-full"
                        />
                    </div>
                    <div className="w-[90%] py-2">
                        <TextArea
                            rows={2}
                            placeholder="Description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                    </div>
                    <div className="w-[90%] py-2 pt-4">
                        {loading ? (
                            <button
                                type="button"
                                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                            >
                                Loading ...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                onClick={() => handleSavePrice()}
                            >
                                SAVE DELIVERY PRICE
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddDeliveryPrice;
