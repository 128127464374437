import * as React from "react";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
    ExpandLess,
    ExpandMore,
    ShoppingCartOutlined,
    DirectionsCarOutlined,
    CategoryOutlined,
    GroupOutlined,
    PointOfSaleOutlined,
    DashboardOutlined,
    DeliveryDiningOutlined,
    GroupAddOutlined,
    GroupsOutlined,
    StorefrontOutlined,
    Inventory2Outlined,
    AdsClickOutlined,
    ChatOutlined,
    DnsOutlined,
    Group,
} from "@mui/icons-material";
import { colors } from "../assets/utils/colors";

const drawerWidth = 220;

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: 0,
    cursor: "default",
    transition: "all ease-in-out 0.3s",
    "&::before": {
        content: '""',
        position: "absolute",
        height: 0,
        bottom: "50%",
        width: 4,
        transition: "all ease-in-out 0.3s",
        backgroundColor: "purple",
    },
    "&:hover": {
        // backgroundColor: colors.link,
    },
    "& .icon": {
        minWidth: 0,
        justifyContent: "center",
        color: colors.secondary,
        opacity: 0.9,
        fontSize: 25,
    },
    "& .name": {
        color: "black",
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
    const [open] = React.useState(false);

    const [expand, setExpand] = React.useState(true);
    const handleExpandClick = () => {
        setExpand(!expand);
    };

    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ################# LINKS ################
    const links = [
        {
            id: 1,
            name: "Actions",
            icon: <DashboardOutlined className="icon" />,
            url: "",
            tooltip: "Overview",
            children: [
                {
                    id: 1,
                    name: "Overview",
                    icon: <DashboardOutlined className="icon" />,
                    url: "/",
                    tooltip: "Overview",
                },
                {
                    id: 6,
                    name: "Products",
                    icon: <Inventory2Outlined className="icon" />,
                    url: "/products",
                    tooltip: "products",
                },

                {
                    id: 8,
                    name: "Shops",
                    icon: <StorefrontOutlined className="icon" />,
                    url: "/shops",
                    tooltip: "Shops",
                },

                {
                    id: 10,
                    name: "Promotions",
                    icon: <AdsClickOutlined className="icon" />,
                    url: "/promotions",
                    tooltip: "Promotions",
                },

                {
                    id: 12,
                    name: "Chats",
                    icon: <ChatOutlined className="icon" />,
                    url: "/chats",
                    tooltip: "Chats",
                },
            ],
        },
        {
            id: 2,
            name: "Orders",
            icon: <ShoppingCartOutlined className="icon" />,
            url: "",
            tooltip: "Orders",
            children: [
                {
                    id: 1,
                    name: "POS Orders",
                    icon: <PointOfSaleOutlined className="icon" />,
                    url: "/orders/pos",
                    tooltip: "POS",
                },
                {
                    id: 2,
                    name: "Customer Orders",
                    icon: <ShoppingCartOutlined className="icon" />,
                    url: "/orders/customers",
                    tooltip: "Orders",
                },
            ],
        },
        {
            id: 3,
            name: "Users",
            icon: <Group className="icon" />,
            url: "",
            tooltip: "Users",
            children: [
                {
                    id: 3,
                    name: "Admins",
                    icon: <GroupAddOutlined className="icon" />,
                    url: "/users",
                    tooltip: "Admins",
                },
                {
                    id: 2,
                    name: "Vendors",
                    icon: <GroupsOutlined className="icon" />,
                    url: "/vendors",
                    tooltip: "Vendors",
                },
                {
                    id: 1,
                    name: "Customers",
                    icon: <GroupOutlined className="icon" />,
                    url: "/customers",
                    tooltip: "Customers",
                },
            ],
        },
        {
            id: 4,
            name: "Settings",
            icon: <DirectionsCarOutlined className="icon" />,
            url: "",
            tooltip: "Settings",
            children: [
                {
                    id: 1,
                    name: "Brands",
                    icon: <DirectionsCarOutlined className="icon" />,
                    url: "/brands",
                    tooltip: "Brands",
                },
                {
                    id: 2,
                    name: "Categories",
                    icon: <CategoryOutlined className="icon" />,
                    url: "/categories",
                    tooltip: "Categories",
                },
                {
                    id: 3,
                    name: "Delivery",
                    icon: <DeliveryDiningOutlined className="icon" />,
                    url: "/delivery",
                    tooltip: "Delivery",
                },
            ],
        },
    ];

    //################################################
    const renderLink = (link) => {
        if (link?.children) {
            return (
                <>
                    <NavLink key={link.id} style={{ cursor: "default" }}>
                        {({ isActive }) => (
                            <SideNavListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    my: 0,
                                    bgcolor: isActive && {
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    },
                                    cursor: "default",
                                    "&:hover": !isActive && {
                                        cursor: "default",
                                        transition: "all ease-in-out 0.3s",
                                        "&::before": {
                                            transition: "all ease-in-out 0.3s",
                                            height: "100%",
                                            bottom: 0,
                                        },
                                    },
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        py: 0.5,
                                    }}
                                    // onClick={handleExpandClick}
                                >
                                    <ListItemIcon fontSize={14} sx={{ p: 0 }}>
                                        {link.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        className="name"
                                        primary={link.name}
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            color: colors.secondary,
                                        }}
                                    />
                                    {/* {expand ? <ExpandLess /> : <ExpandMore />} */}
                                </ListItemButton>
                            </SideNavListItem>
                        )}
                    </NavLink>

                    <Collapse in={expand} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {link?.children.map((link) => (
                                <NavLink to={link.url} key={link.id}>
                                    {({ isActive }) => (
                                        <SideNavListItem
                                            disablePadding
                                            sx={{
                                                display: "block",
                                                my: 0,
                                                bgcolor: isActive && {
                                                    background: "white",
                                                    borderRight: `5px solid ${colors.bgColor4}`,
                                                },
                                                "&:hover": !isActive && {
                                                    transition:
                                                        "all ease-in-out 0.3s",
                                                    "&::before": {
                                                        transition:
                                                            "all ease-in-out 0.3s",
                                                        height: "100%",
                                                        bottom: 0,
                                                    },
                                                },
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    py: 0.5,
                                                    pl: 3,
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {/* {link.icon} */}
                                                    <DnsOutlined
                                                        sx={{
                                                            fontSize: 15,
                                                            color:
                                                                isActive &&
                                                                "red",
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    className="name"
                                                    primary={link.name}
                                                    primaryTypographyProps={{
                                                        fontSize: 11,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </SideNavListItem>
                                    )}
                                </NavLink>
                            ))}
                        </List>
                    </Collapse>
                </>
            );
        } else {
            return (
                <NavLink to={link.url} key={link.id}>
                    {({ isActive }) => (
                        <SideNavListItem
                            disablePadding
                            sx={{
                                display: "block",
                                my: 0.5,
                                bgcolor: isActive && {
                                    background: "white",
                                },
                                "&:hover": !isActive && {
                                    transition: "all ease-in-out 0.3s",
                                    "&::before": {
                                        transition: "all ease-in-out 0.3s",
                                        height: "100%",
                                        bottom: 0,
                                    },
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    py: 0.5,
                                }}
                            >
                                <ListItemIcon fontSize={"small"}>
                                    {link.icon}
                                </ListItemIcon>
                                <ListItemText
                                    className="name"
                                    primary={link.name}
                                    primaryTypographyProps={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    }}
                                />
                            </ListItemButton>
                        </SideNavListItem>
                    )}
                </NavLink>
            );
        }
    };

    // ################# DRAWER CONTENT ################
    const drawer = () => {
        return <Box>{links?.map((link) => renderLink(link))}</Box>;
    };

    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "75%", // Change the width of drawer in mobile
                        // backgroundColor: colors.primary,
                        py: 4,
                    },
                }}
            >
                <Toolbar />
                {drawer()}
            </Drawer>

            {/* ##################### desktop ################ */}
            <Drawer
                variant="permanent"
                open={open}
                color="primary"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    display: { xs: "none", sm: "block" },
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        whiteSpace: "nowrap",
                    },
                    [`& .MuiPaper-root`]: { backgroundColor: "#F3E8FF" },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <List sx={{ p: 0 }}>
                        {links?.map((link) => renderLink(link))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default SideBar;
