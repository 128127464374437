import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addCustomerDetails,
    addCustomers,
    selectCustomers,
} from "../../features/userSlice";
import { FaEye } from "react-icons/fa";
import supabase from "../../supabaseClient";
import { toast } from "react-hot-toast";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "First name",
        dataIndex: "first_name",
        key: "first_name",
    },
    {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
    },
    {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "Region",
        dataIndex: "region",
        key: "region",
    },
    {
        title: "Status",
        key: "status",
        render: (_, customer) => (
            <>
                <CustomerStatus customer={customer} />
            </>
        ),
    },

    {
        title: "Action",
        key: "action",
        render: (_, customer) => <ViewCustomer customer={customer} />,
    },
];

const CustomerStatus = ({ customer }) => {
    const dispatch = useDispatch();

    const getCustomers = async () => {
        let { data: customUsers, error } = await supabase
            .from("customUsers")
            .select("*")
            .eq("role", "client");

        if (customUsers) {
            dispatch(addCustomers(customUsers));
        } else {
            console.log(error.message);
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("customerUsers")
                .update({
                    status: !customer?.status,
                })
                .eq("id", customer?.id)
                .select("*");

            if (data) {
                //
                getCustomers();
                toast.success("Status is changed successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                customer?.status ? "deactivate" : "activate"
            } this customer?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={customer?.status}
                className={customer?.status ? null : `bg-zinc-300 rounded-full`}
            />
        </Popconfirm>
    );
};

const ViewCustomer = ({ customer }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleViewCustomer = () => {
        dispatch(addCustomerDetails(customer));
        navigate(`/customers/${customer?.usersId}`);
    };

    return (
        <Button
            type="text"
            shape="circle"
            className="flex justify-center"
            onClick={() => handleViewCustomer()}
        >
            <FaEye className="text-red-500 text-xl cursor-pointer" />
        </Button>
    );
};

const Customers = () => {
    const dispatch = useDispatch();
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getCustomers = async () => {
            setDataLoad(true);
            let { data: customUsers, error } = await supabase
                .from("customUsers")
                .select("*")
                .eq("role", "client");

            if (customUsers) {
                setDataLoad(false);
                dispatch(addCustomers(customUsers));
            } else {
                setDataLoad(false);
                console.log(error.message);
            }
        };

        getCustomers();
    }, [dispatch]);

    const customers = useSelector(selectCustomers);
    const sortedCustomers = customers.map((customer, index) => {
        const key = index + 1;
        return { ...customer, key };
    });

    return (
        <>
            <div>
                <PageHeader head={"JU System customers"} />
                {dataLoad ? (
                    <>
                        <DataLoader />
                    </>
                ) : (
                    <>
                        <Table columns={columns} dataSource={sortedCustomers} />
                    </>
                )}
            </div>
        </>
    );
};

export default Customers;
