import { create } from "apisauce";

const APP_ENV = "pro";
const devBaseURL = "http://localhost:7500/api/v1";
const proBaseURL = "https://secure.jackumeme.co.tz/api/v1";

const apiClient = create({
    baseURL: APP_ENV == "pro" ? proBaseURL : devBaseURL,
});

export default apiClient;
