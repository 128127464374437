import React, { useEffect, useState } from "react";
import { Table, Tag, Modal, Button, Segmented } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    addOrderDetails,
    addPosOrders,
    selectPosOrders,
} from "../../features/orderSlice";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import PageHeader from "../../components/PageHeader";
import { Add } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import DataLoader from "../../components/DataLoader";
import { Box, Typography } from "@mui/material";
import { addShops, selectShops } from "../../features/shopSlice";
import { filter } from "lodash";

const formatter = new Intl.NumberFormat("en-US");

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Order #",
        dataIndex: "order_number",
        key: "order_number",
    },
    {
        title: "Ordered Items",
        dataIndex: "ordered_products",
        key: "ordered_products",
        render: (_, order) => (
            <>
                <ViewItems order={order} />
            </>
        ),
    },
    {
        title: "Total Price",
        dataIndex: "total_amount",
        key: "total_amount",
        render: (text) => <p>TZS {formatter.format(text)}</p>,
    },
    {
        title: "Customer",
        key: "customer",
        render: (_, order) => (
            <>
                <CustomerInfo order={order} />
            </>
        ),
    },
    {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (_, { created_at }) => (
            <>
                <p>{moment(created_at).format("DD-MM-YYYY")}</p>
            </>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => (
            <>
                <Status status={status} />
            </>
        ),
    },
    {
        title: "Payment",
        key: "isPaid",
        dataIndex: "isPaid",
        render: (_, { isPaid }) => (
            <>
                {!isPaid ? (
                    <Tag color={"red"}>Not Paid</Tag>
                ) : (
                    <Tag color={"green"}>Paid</Tag>
                )}
            </>
        ),
    },
    {
        title: "Action",
        key: "action",
        render: (_, order) => <ViewOrder order={order} />,
    },
];

const CustomerInfo = ({ order }) => {
    return (
        <div>
            <p>
                {order?.customer?.first_name} {order?.customer?.last_name}
            </p>
            <p>{order?.customer?.phone}</p>
        </div>
    );
};

const Status = ({ status }) => {
    if (status === 1) {
        return <Tag color={"blue"}>Placed</Tag>;
    } else if (status === 2) {
        return <Tag color={"green"}>Accepted</Tag>;
    } else if (status === 3) {
        return <Tag color={"green"}>Shipped</Tag>;
    } else if (status === 4) {
        return <Tag color={"green"}>Delivered</Tag>;
    } else if (status === 5) {
        return <Tag color={"red"}>Cancelled</Tag>;
    } else {
        return <Tag color={"green"}>Refunded</Tag>;
    }
};

const ViewOrder = ({ order }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleViewOrder = () => {
        dispatch(addOrderDetails(order));
        navigate(`/orders/pos/${order?.id}`);
    };

    return (
        <Button
            type="text"
            shape="circle"
            className="flex justify-center"
            onClick={() => handleViewOrder()}
        >
            <FaEye className="text-red-500 text-xl cursor-pointer" />
        </Button>
    );
};

const ViewItems = ({ order }) => {
    const dispatch = useDispatch();
    const items = order?.ordered_products.products;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getTotalOrderPrice = () => {
        let totalPrice = 0;
        items.forEach((item) => {
            totalPrice += item?.price * item?.cart_quantity;
        });
        return totalPrice;
    };

    const formatter = new Intl.NumberFormat("en-US");

    // ############## GET Shops ###################
    const getShops = async () => {
        let { data: shops, error } = await supabase
            .from("shops")
            .select("*, shopOwner(*)");

        if (shops) {
            dispatch(addShops(shops));
        } else {
            console.log(error.message);
        }
    };

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getShops();
        }

        return () => {
            isSubscribed = false;
        };
    }, [getShops]);

    const shops = useSelector(selectShops);
    const getProductShop = (product) => {
        let productShop = filter(shops, { id: product.shop });
        return productShop[0];
    };

    return (
        <>
            <button
                type="button"
                className="px-6 py-2 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                onClick={showModal}
            >
                View
            </button>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                // style={{ top: 30 }}
                width={"65%"}
                footer={
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: 2,
                            borderRadius: 3,
                            background: `linear-gradient(180deg, ${colors.bgColor1},  ${colors.bgColor2})`,
                        }}
                    >
                        <Box>
                            <div className="">
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography component={"span"}>
                                        Items price :
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                    <span className="font-semibold">
                                        TZS{" "}
                                        {formatter.format(getTotalOrderPrice())}
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography
                                        component={"span"}
                                        sx={{
                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: 14,
                                            mt: 2,
                                            textAlign: "left",
                                        }}
                                    >
                                        Shipping cost :
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                    <span className="font-semibold">
                                        Not included
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography component={"span"}>
                                        Total amount : &nbsp;&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                    <span className="font-semibold">
                                        TZS{" "}
                                        {formatter.format(
                                            getTotalOrderPrice() +
                                                order?.delivery_price
                                        )}
                                    </span>
                                </Typography>
                            </div>
                        </Box>
                        <Box>
                            <div className="flex justify-end space-x-4">
                                <ViewOrder order={order} />
                            </div>
                        </Box>
                    </Box>
                }
            >
                <h4
                    className="text-lg font-semibold text-center py-4"
                    style={{ color: colors.primary }}
                >
                    ORDRED ITEMS
                </h4>
                <div
                    className={
                        items?.length > 2
                            ? "h-[320px] px-3 pt-2 overflow-y-scroll"
                            : "h-[320px] px-3 pt-2"
                    }
                >
                    <div className="h-[350px] overflow-y-auto">
                        <ul className="flex flex-col">
                            {items && items.length > 0 ? (
                                <>
                                    {items.map((product, index) => {
                                        const totalAmount =
                                            parseInt(product?.product?.price) *
                                            parseInt(product?.product_count);
                                        return (
                                            <li
                                                className="flex flex-col py-3 sm:flex-row sm:justify-between"
                                                key={index}
                                            >
                                                <div
                                                    className="flex w-full space-x-2 sm:space-x-4 pb-2"
                                                    style={{
                                                        // background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                                                        borderBottom: `1px dotted ${colors.primary}`,
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div className="flex space-x-2 sm:space-x-4 pb-2 ">
                                                        <img
                                                            className="flex-shrink-0 object-cover w-20 h-16 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                                                            src={
                                                                product?.product
                                                                    ?.bannerImage
                                                            }
                                                            alt="car spare"
                                                        />
                                                        <div className="w-full">
                                                            <div>
                                                                <p className="capitalize font-semibold">
                                                                    {
                                                                        product?.title
                                                                    }{" "}
                                                                    {
                                                                        product
                                                                            ?.product
                                                                            ?.title
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        product
                                                                            ?.brand
                                                                            ?.brandName
                                                                    }{" "}
                                                                    {
                                                                        product
                                                                            ?.model
                                                                            ?.modelName
                                                                    }{" "}
                                                                    {
                                                                        product?.year
                                                                    }
                                                                </p>
                                                                <p>
                                                                    TZS{" "}
                                                                    {formatter.format(
                                                                        product?.price ||
                                                                            0
                                                                    )}
                                                                </p>
                                                                <p className="">
                                                                    Total Items
                                                                    :{" "}
                                                                    {
                                                                        product?.cart_quantity
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="pt-1">
                                                                <p className="text-semibold">
                                                                    Total Price
                                                                    :{" "}
                                                                    {formatter.format(
                                                                        product?.price *
                                                                            product?.cart_quantity
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h1
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                                textTransform:
                                                                    "uppercase",
                                                            }}
                                                        >
                                                            Shop details
                                                        </h1>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginRight: 10,
                                                                }}
                                                            >
                                                                <h1 className="font-semibold">
                                                                    Vendor
                                                                </h1>
                                                                <p className="capitalize">
                                                                    {`${
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.shopOwner
                                                                            .first_name
                                                                    } ${
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.shopOwner
                                                                            .last_name
                                                                    }`}
                                                                </p>
                                                                <p className="capitalize">
                                                                    {
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.shopOwner
                                                                            .phone
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <h1 className="font-semibold">
                                                                    Shop
                                                                </h1>
                                                                <p className="capitalize">
                                                                    {
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.shopName
                                                                    }
                                                                </p>
                                                                <p className="capitalize no-wrap">
                                                                    {`${
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.district
                                                                    }, ${
                                                                        getProductShop(
                                                                            product
                                                                        )
                                                                            ?.region
                                                                    }`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </>
                            ) : (
                                <li className="text-center">
                                    There is no products
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const POSOrders = () => {
    const dispatch = useDispatch();

    const [orderStatus, setOrderStatus] = useState(0);
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getOrders = async () => {
            setDataLoad(true);
            let { data: orders, error } = await supabase
                .from("orders")
                .select("*, customer(*)")
                .eq("isPOS", true);

            if (error) {
                setDataLoad(false);
                console.log(error.message);
            } else {
                // console.log(orders);
                setDataLoad(false);
                dispatch(addPosOrders(orders));
            }
        };

        getOrders();
    }, [dispatch]);

    const posOrders = useSelector(selectPosOrders);
    const allOrders = posOrders
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const filteredOrders = allOrders.filter((order) => {
        if (orderStatus < 1) {
            // If value is less than 1, return all orders
            return true;
        } else {
            // Otherwise, filter based on the order's status
            return order.status === orderStatus;
        }
    });

    const sortedOrders = filteredOrders.map((order, index) => {
        const key = index + 1;
        return { ...order, key };
    });

    const placedOrders = posOrders.filter((order) => order.status === 1);

    const acceptedOrders = posOrders.filter((order) => order.status === 2);

    const shippedOrders = posOrders.filter((order) => order.status === 3);

    const deliveredOrders = posOrders.filter((order) => order.status === 4);

    const cancelledOrders = posOrders.filter((order) => order.status === 5);

    const refundedOrders = posOrders.filter((order) => order.status === 6);

    const onStatusChange = (value) => {
        // console.log(value);
        setOrderStatus(value);
    };

    return (
        <>
            <div>
                <PageHeader
                    head={"Point of sell (POS) Orders"}
                    action={
                        <Link to={"create"}>
                            <div className="h-10 w-44 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white">
                                <Add className="mt-2 py-0.5" />{" "}
                                <p className="py-2">New order</p>
                            </div>
                        </Link>
                    }
                />
                {dataLoad ? (
                    <>
                        <DataLoader />
                    </>
                ) : (
                    <>
                        <Segmented
                            block
                            options={[
                                {
                                    label: `All (${posOrders.length})`,
                                    value: 0,
                                },
                                {
                                    label: `Placed (${placedOrders.length})`,
                                    value: 1,
                                },
                                {
                                    label: `Accepted (${acceptedOrders.length})`,
                                    value: 2,
                                },
                                {
                                    label: `Shipped (${shippedOrders.length})`,
                                    value: 3,
                                },
                                {
                                    label: `Delivered (${deliveredOrders.length})`,
                                    value: 4,
                                },
                                {
                                    label: `Cancelled (${cancelledOrders.length})`,
                                    value: 5,
                                },
                                {
                                    label: `Refunded (${refundedOrders.length})`,
                                    value: 6,
                                },
                            ]}
                            size="large"
                            value={orderStatus}
                            onChange={onStatusChange}
                            style={{
                                padding: 15,
                                boxShadow: `0, 0, 2px 0`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        />
                        <Table columns={columns} dataSource={sortedOrders} />
                    </>
                )}
            </div>
        </>
    );
};

export default POSOrders;
