import { createSlice } from "@reduxjs/toolkit";
import { parseInt } from "lodash";
import { toast } from "react-hot-toast";

const initialState = {
  categories: [],
  filteredCategories: [],
  products: [],
  shops: [],
  cartItems: [],
  cartSubTotalAmount: 0,
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  posOrders: [],
  filteredProducts: [],
};

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    addCategories(state, action) {
      state.categories = action.payload;
    },

    addFilteredCategories(state, action) {
      state.filteredCategories = action.payload;
    },

    addProducts(state, action) {
      state.products = action.payload;
    },

    addPosShops(state, action) {
      state.shops = action.payload;
    },

    addPosOrders(state, action) {
      state.posOrders = action.payload;
    },

    //cart
    addToCart(state, action) {
      //-> check if product is in cart,
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.product.id
      );

      //-> if item is already in cart, increment it by 1 using its index
      if (itemIndex >= 0) {
        const tempItem =
          state.cartItems[itemIndex].cart_quantity +
          parseInt(action.payload.cart_quantity);

        //check if cart quantity is equal or less to product quantity
        if (tempItem > action.payload.product.remainQuantity) {
          // console.log("yes 1");
          toast.error(
            `Sorry! Maximun quantity for this product is ${action.payload.product.remainQuantity}, Please try again!`
          );
        } else {
          //update cart item
          state.cartItems[itemIndex].cart_quantity = parseInt(tempItem);
          //sessionStorage.setItem("cart", JSON.stringify(state.cartItems));
        }
      } else {
        //-> set item quantity when pressed
        const item = action.payload.product;

        if ("cart_quantity" in item) {
          if (item.cart_quantity !== 0) {
            const cart_quantity = parseInt(action.payload.cart_quantity);
            //check if cart quantity is equal or less to product quantity
            if (cart_quantity > action.payload.product.remainQuantity) {
              // console.log("yes 2");
              toast.error(
                `Sorry! Maximun quantity for this product is ${action.payload.product.remainQuantity}, Please try again!`
              );
            } else {
              //put item into cart
              const tempItem = { ...action.payload.product, cart_quantity };
              state.cartItems.push(tempItem);
            }
          } else {
            const cart_quantity =
              item.cart_quantity + action.payload.cart_quantity;
            const temp_quantity = parseInt(cart_quantity);
            //check if cart quantity is equal or less to product quantity
            if (cart_quantity > action.payload.product.remainQuantity) {
              toast.error(
                `Sorry! Maximun quantity for this product is ${action.payload.product.remainQuantity}, Please try again!`
              );
            } else {
              //put item into cart
              const tempItem = {
                ...action.payload.product,
                cart_quantity: temp_quantity,
              };
              state.cartItems.push(tempItem);
            }
          }
        } else {
          //check if cart quantity is equal or less to product quantity
          if (
            action.payload.cart_quantity > action.payload.product.remainQuantity
          ) {
            toast.error(
              `Sorry! Maximun quantity for this product is ${action.payload.product.remainQuantity}, Please try again!`
            );
          } else {
            //put item into cart
            const tempItem = {
              ...action.payload.product,
              cart_quantity: parseInt(action.payload.cart_quantity),
            };
            state.cartItems.push(tempItem);
          }
        }
      }
    },

    removeFromCart(state, action) {
      //-> return items not equal to our action.payload.id
      const nextCartItems = state.cartItems.filter(
        (cartItem) => cartItem.id !== action.payload.id
      );

      //-> update the state
      state.cartItems = nextCartItems;
    },

    decreaseFromCart(state, action) {
      //-> check if product is in cart, find its index
      const itemIndex = state.cartItems.findIndex(
        (cartItem) => cartItem.id === action.payload.id
      );

      //-> if item is in cart, using its index, decrease cartQuantity by 1,
      //-> if quantity is greater than 1 and if its 1 respectively then remove the product
      if (state.cartItems.length !== 0 && state.cartItems[itemIndex]) {
        if (state.cartItems[itemIndex].cart_quantity > 1) {
          const tempItem = state.cartItems[itemIndex].cart_quantity - 1;
          state.cartItems[itemIndex].cart_quantity = parseInt(tempItem);
        } else if (state.cartItems[itemIndex].cart_quantity === 1) {
          const nextCartItems = state.cartItems.filter(
            (cartItem) => cartItem.id !== action.payload.id
          );
          //-> update the state
          state.cartItems = nextCartItems;
        }
      }
    },

    clearCart(state, action) {
      state.cartItems = [];
    },

    //-> calculate charges
    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          //-> take price & quantity at a single cartItem
          const { price, cart_quantity } = cartItem;
          const itemTotal = price * cart_quantity;

          //-> calculate total cart price
          cartTotal.total += itemTotal;
          cartTotal.quantity += cart_quantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },

    addFilteredProducts(state, action) {
      state.filteredProducts = action.payload;
    },
  },
});

export const {
  addCategories,
  addFilteredCategories,
  addProducts,
  addPosShops,
  addToCart,
  removeFromCart,
  decreaseFromCart,
  clearCart,
  getTotals,
  addPosOrders,
  addFilteredProducts,
} = posSlice.actions;

export const selectCategories = (state) => state.pos.categories;
export const selectFilteredCategories = (state) => state.pos.filteredCategories;
export const selectProducts = (state) => state.pos.products;
export const selectPosShops = (state) => state.pos.shops;
export const selectCartProducts = (state) => state.pos.cartItems;
export const selectCartTotalAmount = (state) => state.pos.cartTotalAmount;
export const selectPosOrders = (state) => state.pos.posOrders;
export const selectFilteredProducts = (state) => state.pos.filteredProducts;

export default posSlice.reducer;
