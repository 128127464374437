import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  orders: [],
  posOrders: [],
  customerOrders: [],
  orderDetails: "",
  orderShopOrders: [],
  pendingOrders: [],
  shippedOrders: [],
  deliveredOrders: [],
  refundedOrders: [],
  cancelledOrders: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addAllOrders(state, action) {
      state.orders = action.payload;
    },
    addPosOrders(state, action) {
      state.posOrders = action.payload;
    },
    addCustomerOrders(state, action) {
      state.customerOrders = action.payload;
    },

    addPendingOrders(state, action) {
      state.pendingOrders = action.payload;
    },

    addShippedOrders(state, action) {
      state.shippedOrders = action.payload;
    },

    addDeliveredOrders(state, action) {
      state.deliveredOrders = action.payload;
    },

    addRefundedOrders(state, action) {
      state.refundedOrders = action.payload;
    },

    addCancelledOrders(state, action) {
      state.cancelledOrders = action.payload;
    },

    addOrderDetails(state, action) {
      if (!isEmpty(action.payload)) {
        state.orderDetails = action.payload;
      }
    },

    addOrderShopOrders(state, action) {
      state.orderShopOrders = action.payload;
    },
    addOrderShopOrder(state, action) {
      state.orderShopOrders = [...state.orderShopOrders, action.payload];
    },
  },
});

export const {
  addAllOrders,
  addOrderDetails,
  addOrderShopOrders,
  addOrderShopOrder,
  addPendingOrders,
  addShippedOrders,
  addDeliveredOrders,
  addRefundedOrders,
  addCancelledOrders,
  addPosOrders,
  addCustomerOrders,
} = orderSlice.actions;

export const selectAllOrders = (state) => state.order.orders;
export const selectPosOrders = (state) => state.order.posOrders;
export const selectCustomerOrders = (state) => state.order.customerOrders;
export const selectOrderDetails = (state) => state.order.orderDetails;
export const selectOrderShopOrders = (state) => state.order.orderShopOrders;

export default orderSlice.reducer;
