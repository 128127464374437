import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    promotions: [],
    promotionDetails: "",
    promotionProducts: [],
};

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {

    addPromotions(state, action) {
      state.promotions = action.payload;
    },

    addPromotionDetails(state, action) {
      state.promotionDetails = action.payload;
    },

    addPromotionProducts(state, action) {
      state.promotionProducts = action.payload;
    },

  },
});

export const {
  addPromotions,
  addPromotionDetails,
  addPromotionProducts
} = promotionSlice.actions;

export const selectAllPromotions = (state) => state.promotion.promotions;
export const selectPromotionDetails = (state) => state.promotion.promotionDetails;
export const selectPromotionProducts = (state) => state.promotion.promotionProducts;

export default promotionSlice.reducer;
