import { Button, Image, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addShopProductDetails,
} from "../../features/shopSlice";
import { addCustomerFavProducts, selectCustomerFavProducts } from "../../features/userSlice";
import supabase from "../../supabaseClient";

const formatter = new Intl.NumberFormat("en-US");

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Name",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Image",
    dataIndex: "bannerImage",
    key: "bannerImage",
    render: (_, { bannerImage }) => (
      <>
        <ViewImage image={bannerImage} />
      </>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (text) => <p>TZS {formatter.format(text)}</p>,
  },
  {
    title: "Brand",
    key: "brand",
    render: (_, product) => (
      <>
        <p className="capitalize">{product?.brand?.brandName}</p>
      </>
    ),
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
    render: (_, { model }) => (
      <>
        <p className="capitalize">{model?.modelName}</p>
      </>
    ),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    key: "condition",
    render: (text) => <p className="capitalize">{text}</p>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        <Status status={status} />
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, product) => <ViewProduct product={product} />,
  },
];

const Status = ({ status }) => {
  if (status === "pending") {
    return <Tag color={"blue"}>Pending</Tag>;
  } else if (status === "approved") {
    return <Tag color={"green"}>Approved</Tag>;
  } else {
    return <Tag color={"red"}>Suspended</Tag>;
  }
};

const ViewImage = ({ image }) => {
  return <Image width={80} height={80} src={image} />;
};

const ViewProduct = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewProduct = () => {
    dispatch(addShopProductDetails(product));
    navigate(`/shops/${product?.shop}/products/${product?.id}`);
  };

  return (
    <Button
      type="text"
      shape="circle"
      className="flex justify-center"
      onClick={() => handleViewProduct()}
    >
      <FaEye className="text-red-500 text-xl cursor-pointer" />
    </Button>
  );
};

const FavoriteProducts = () => {
  const dispatch = useDispatch();
  const { customerID } = useParams();

  useEffect(() => {
    const getFavProducts = async () => {
        let { data: favorite_products, error } = await supabase
          .from("favorite_products")
          .select("*, product(*)")
          .eq("customer", customerID || "");
  
        if (favorite_products) {
          dispatch(addCustomerFavProducts(favorite_products));
        } else {
          console.log(error.message);
        }
      };

      getFavProducts();
  }, [dispatch]);

  const products = useSelector(selectCustomerFavProducts);
  const allProducts = products
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const sortedProducts = allProducts.map((order, index) => {
    const key = index + 1;
    return { ...order, key };
  });

  return (
    <div className="w-[100%]">
      <h4 className="text-lg font-semibold text-center py-2"></h4>
      <Table columns={columns} dataSource={sortedProducts} />
    </div>
  );
};

export default FavoriteProducts;
