import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Avatar, Badge, Box, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import supabase from "../supabaseClient";
import { colors } from "../assets/utils/colors";
import logo from "../assets/images/logo.jpg";
import { ChatOutlined } from "@mui/icons-material";

// ################# CONSTANT ###############
const drawerWidth = 65;
const date = moment().format("ddd, DD MMMM");

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    // background: `linear-gradient(270deg, #D8B4FE, #27272A) !important`,
    background: colors.primary,
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    // backgroundColor: "white",
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################

const currentTime = new Date();
currentTime.getHours();
const currentHour = currentTime.getHours();
let greetings = "Hi";
switch (true) {
    case currentHour < 12:
        greetings = "Good morning";
        break;
    case currentHour > 11 && currentHour < 17:
        greetings = "Good afternoon";
        break;
    default:
        greetings = "Good evening";
        break;
}

const SignOutButton = ({ handleClose = () => {} }) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const logout = async () => {
        setIsLoading(true);

        try {
            const { error } = await supabase.auth.signOut();

            if (error) {
                message.error(error.message);
                setIsLoading(false);
            } else {
                message.success("Logged out");
                setIsLoading(false);
            }
        } catch (error) {
            message.error(error.message);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <MenuItem
                onClick={(e) => {
                    e.preventDefault();
                    logout();
                }}
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <div className="flex items-center">
                            <span>Logging out </span>
                            <CircularProgress size={20} />
                        </div>
                    </>
                ) : (
                    <>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </>
                )}
            </MenuItem>
        </div>
    );
};

const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        navigate(`/users/profile`);
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    // width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                onClick={handleDrawerToggle}
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                }}
                            >
                                <CloseIcon className="text-slate-900 h-12 w-12" />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={handleDrawerToggle}
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                }}
                            >
                                <MenuIcon className="text-slate-900 h-12 w-12" />
                            </IconButton>
                        )}
                        <Box>
                            <Avatar
                                sx={{
                                    border: `1px solid white`,
                                    height: 55,
                                    width: 55,
                                    p: 0.5,
                                    bgcolor: "#fff",
                                    img: {
                                        objectFit: "cover",
                                    },
                                }}
                                src={logo}
                                aria-controls={
                                    open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                className="cursor-pointer"
                                onClick={handleClick}
                            />
                        </Box>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                noWrap
                                component="div"
                                sx={{
                                    display: "flex",
                                    fontSize: 16,
                                    color: "white",
                                    fontWeight: "bold",
                                    ml: 1,
                                    fontStyle: "italic",
                                }}
                            >
                                Jack Umeme
                            </Typography>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    display: { xs: "none", sm: "block" },
                                }}
                            >
                                {greetings} Admin
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <Typography
                            sx={{
                                color: "white",
                                fontSize: 14,
                                fontWeight: "bold",
                                display: { xs: "none", sm: "block" },
                            }}
                        >
                            {greetings} Admin
                        </Typography> */}
                    </Box>
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                color: "white",
                                fontWeight: "bold",
                                display: { xs: "none", sm: "block" },
                            }}
                        >
                            {date}
                        </Typography>
                        <Link to={"/chats"}>
                            <IconButton
                                size="small"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                                onClick={() => {}}
                            >
                                <Badge badgeContent={0} showZero color="error">
                                    <ChatOutlined
                                        sx={{ fontSize: 25, color: "#fff" }}
                                    />
                                </Badge>
                            </IconButton>
                        </Link>
                        <Avatar
                            sx={{ border: `1px solid white` }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            className="cursor-pointer"
                            onClick={handleClick}
                        />
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                            "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            {/* <MenuItem onClick={handleProfile}>
                                <Avatar /> Profile
                            </MenuItem> */}
                            {/* <Divider /> */}
                            <SignOutButton handleClose={handleClose} />
                        </Menu>
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
