import React, { useState } from "react";
import { Input, message, Modal, Select } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import { addUsers } from "../../features/userSlice";
import Add from "@mui/icons-material/Add";

const AddUser = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const handleSaveUser = async () => {
    //
    const getUsers = async () => {
      let { data: customUsers, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("role", "admin");

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addUsers(customUsers));
      }
    };

    if (!firstName) {
      message.error("Please enter first name");
    } else if (!lastName) {
      message.error("Please enter last name");
    } else if (!phoneNumber) {
      message.error("Please enter phone number");
    } else if (!email) {
      message.error("Please enter email");
    } else if (!gender) {
      message.error("Please enter gender");
    } else if (!password) {
      message.error("Please enter password");
    } else if (password !== confirmPassword) {
      message.error("Sorry! password should match confirm password");
    } else {
      setLoading(true);
      //send data

      const { data, error } = await supabase.auth.admin.createUser({
        first_name: firstName,
        last_name: lastName,
        gender,
        email,
        password,
        phone: phoneNumber,
        role: "admin",
        region: null,
        email_confirm: true,
      });

      if (data) {
        //add data
        const { data: customData, error: customError } = await supabase
          .from("customUsers")
          .insert([
            {
              first_name: firstName,
              last_name: lastName,
              gender,
              email,
              role: "admin",
              region: null,
              phone: phoneNumber,
              usersId: data?.user?.id,
            },
          ])
          .select("*");

        if (customData) {
          //
          message.success("User is saved successfully");
          setFirstName("");
          setLastName("");
          setPhone("");
          setEmail("");
          setGender("");
          setPassword("");
          setConfirmPassword("");
          getUsers();
          setLoading(false);
        } else {
          message.error(customError.message);
          setLoading(false);
        }
      } else {
        message.error(error.message);
        setLoading(false);
      }

      try {
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <>
      <div
        onClick={showModal}
        className="h-10 w-48 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white"
      >
        <Add className="mt-2 py-0.5" /> <p className="py-2">Create New User</p>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        width={700}
      >
        <h4 className="text-center font-semibold text-xl">Add New User</h4>
        <div className="py-2 flex flex-col justify-center items-center">
          <div className="w-[90%] flex flex-row gap-2 py-2">
            <Input
              size="large"
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              className="w-full"
            />
            <Input
              size="large"
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              className="w-full"
            />
          </div>
          <div className="w-[90%] flex flex-row gap-2 py-2">
            <Input
              size="large"
              placeholder="Phone number"
              onChange={(e) => setPhone(e.target.value)}
              value={phoneNumber}
              className="w-full"
            />
            <Input
              size="large"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-full"
            />
            <Select
              size="large"
              className="w-full"
              placeholder="Gender"
              value={gender}
              onChange={handleGenderChange}
              options={[
                {
                  value: "female",
                  label: "Female",
                },
                {
                  value: "male",
                  label: "Male",
                },
              ]}
            />
          </div>
          <div className="w-[90%] flex flex-row gap-2 py-2">
            <Input
              size="large"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="w-full"
            />
            <Input
              size="large"
              placeholder="confirm password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              className="w-full"
            />
          </div>
          <div className="w-[90%] py-2 pt-4">
            {loading ? (
              <button
                type="button"
                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
              >
                Loading ...
              </button>
            ) : (
              <button
                type="button"
                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                onClick={() => handleSaveUser()}
              >
                SAVE USER
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUser;
