import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./assets/sass/app.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
import App from "./App";

// * redux configuration
import { store } from "./app/store";
import { Provider } from "react-redux";

import Login from "./pages/auth/Login";
import supabase from "./supabaseClient";

const Apps = () => {
  const [session, setSession] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      // console.log(session);
      setSession(session)
    })

    const { data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (session) {
    return <App/>
  } else if(session === false) {
    return <div></div>
  } else {
    return <Login/>
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Apps/>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

