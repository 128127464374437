import React, { useState } from "react";
import { Input, Modal } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import { addModels } from "../../features/brandSlice";
import { useParams } from "react-router-dom";
import Add from "@mui/icons-material/Add";
import { toast } from "react-hot-toast";

const { TextArea } = Input;

const AddModel = () => {
  const dispatch = useDispatch();
  const { brandID } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [modelName, setModel] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSaveModel = async () => {
    //
    const getModels = async () => {
      let { data: models, error } = await supabase
        .from("models")
        .select("*, brands(*)")
        .eq("brandId", brandID);

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addModels(models));
      }
    };

    if (!modelName) {
      toast.error("Please enter model name");
    } else if (!brandID) {
      toast.error("Please select brand to add model");
    } else {
      setLoading(true);
      //send data
      try {
        const { data, error } = await supabase
          .from("models")
          .insert([
            {
              modelName: modelName,
              brandId: brandID,
              description: description,
            },
          ])
          .select("*");

        if (data) {
          //
          setModel("");
          setDescription("");
          getModels();
          toast.success("Model is saved successfully");
          setLoading(false);
        } else {
          toast.error(error.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div
        onClick={showModal}
        className="h-10 w-44 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white"
      >
        <Add className="mt-2 py-0.5" /> <p className="py-2">Add New Model</p>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        width={700}
      >
        <h4 className="text-center font-semibold text-xl">Setup New Model</h4>
        <div className="py-2 flex flex-col justify-center items-center">
          <div className="w-[90%] py-2">
            <Input
              size="large"
              placeholder="Model name"
              onChange={(e) => setModel(e.target.value)}
              value={modelName}
              className="w-full"
            />
          </div>
          <div className="w-[90%] py-2">
            <TextArea
              rows={2}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div className="w-[90%] py-2 pt-4">
            {loading ? (
              <button
                type="button"
                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
              >
                Loading ...
              </button>
            ) : (
              <button
                type="button"
                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                onClick={() => handleSaveModel()}
              >
                SAVE MODEL
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddModel;
