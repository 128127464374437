import { find, isEmpty } from "lodash";
import React, { Fragment } from "react";
import { formatPrice } from "../utils";
import { Tag } from "antd";

function CustomerOrderTable({
  shops,
  products,
  status,
  handleModalOpen,
  orderStatus,
  shopOrder,
}) {
  const Status = ({ status }) => {
    if (status === 1) {
      return <Tag color={"blue"}>Placed</Tag>;
    } else if (status === 2) {
      return <Tag color={"green"}>Accepted</Tag>;
    } else if (status === 3) {
      return <Tag color={"green"}>Shipped</Tag>;
    } else if (status === 4) {
      return <Tag color={"green"}>Delivered</Tag>;
    } else if (status === 5) {
      return <Tag color={"red"}>Cancelled</Tag>;
    } else {
      return <Tag color={"green"}>Refunded</Tag>;
    }
  };

  const getProductShop = (product) => {
    let productShop = find(shops, { id: product.product.shop });
    return productShop;
  };
  const getOrderStatus = (product) => {
    // console.log("product id", product);
    var shoProduct = shopOrder.find(
      (o) => o.ordered_products.id === product.product.id
    );
    if (!isEmpty(shoProduct)) {
      return shoProduct.status;
    } else {
      return orderStatus;
    }
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-2 py-4">
              #
            </th>
            <th scope="col" className="px-6 py-5">
              Shop Name
            </th>
            <th scope="col" className="px-6 py-5">
              Shop Location
            </th>
            <th scope="col" className="px-6 py-5">
              Product
            </th>
            <th scope="col" className="px-2 py-5">
              Total Price
            </th>
            <th scope="col" className="px-2 py-5">
              Status
            </th>
            <th scope="col" className="px-2 py-5">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(products) ? (
            <Fragment>
              {products.map((product, i) => (
                <tr className="bg-white border-b" key={i}>
                  <th
                    scope="row"
                    className="px-2 py-8 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {i + 1}
                  </th>
                  <td className="px-6 py-8">
                    {getProductShop(product).shopName}
                  </td>
                  <td className="px-6 py-8">
                    {`${getProductShop(product)?.district}, ${
                      getProductShop(product)?.region
                    }`}
                  </td>
                  <td className="px-2 py-2 flex items-center">
                    <div className="w-24 h-24 mr-4">
                      <img
                        src={product.product.banner}
                        alt="Headlights"
                        className="w-24 h-24 object-cover"
                      />
                    </div>
                    <div>
                      <h1>{product.product.title}</h1>
                      <h1>Quantity: {product.product_quantity}</h1>
                    </div>
                  </td>
                  <td className="px-2 py-8">
                    {formatPrice(parseInt(product.product.price))}
                  </td>
                  <td className="px-2 py-8">
                    {getOrderStatus(product) === "rejected"
                      ? "Rejected"
                      : getOrderStatus(product) === "accepted"
                      ? "Accepted"
                      : typeof getOrderStatus(product) === "number" &&
                        Status({ status: getOrderStatus(product) })}
                  </td>
                  <td className="px-2 py-8">
                    {getOrderStatus(product) === 1 ||
                    getOrderStatus(product) === "rejected" ? (
                      <button
                        onClick={() =>
                          handleModalOpen({
                            item: product.product,
                            count: product.product_count,
                            selectedShop: getProductShop(product),
                          })
                        }
                        className="px-5 py-3 text-white bg-purple-600 rounded-lg"
                      >
                        Dispatch
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </Fragment>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}

export default CustomerOrderTable;
