import { ShoppingCart } from "@mui/icons-material";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import { Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCartProducts } from "../../features/posSlice";
import { colors } from "../../assets/utils/colors";
import PosCartItems from "./PosCartItems";
import PayPosOrder from "./PayPosOrder";

const PosCart = () => {
    const cartProducts = useSelector(selectCartProducts);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <Modal
                title="Billing Section"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                width={"60%"}
                style={{ padding: `0 !important` }}
                footer={
                    <Box>
                        <PayPosOrder />
                    </Box>
                }
            >
                <PosCartItems />
            </Modal>
            <Box>
                <IconButton
                    size="small"
                    color="primary"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        mr: 2,
                    }}
                    onClick={() => {
                        showModal();
                    }}
                >
                    <Badge
                        badgeContent={cartProducts.length}
                        showZero
                        color="error"
                    >
                        <ShoppingCart />
                    </Badge>
                    <Typography
                        sx={{
                            fontSize: 10,
                            color: colors.primary,
                            fontWeight: "bold",
                        }}
                    >
                        Cart items
                    </Typography>
                </IconButton>
            </Box>
        </div>
    );
};

export default PosCart;
