import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Input, Button, Upload, Modal } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import { addBrand, addBrands } from "../../features/brandSlice";
import Add from "@mui/icons-material/Add";
import { toast } from "react-hot-toast";
import { createBrand } from "../../api/BrandAPI";

const { TextArea } = Input;

const AddBrand = () => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [brandName, setBrand] = useState("");
    const [brandImage, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [fileList, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSaveBrand = async () => {
        //initialize get brands
        const getBrands = async () => {
            setLoading(true);
            let { data: brands, error } = await supabase
                .from("brands")
                .select("*");

            if (error) {
                setLoading(false);
                console.log(error.message);
            } else {
                setLoading(false);
                dispatch(addBrands(brands));
            }
        };

        if (!brandName) {
            toast.error("Please enter brand name");
        } else if (!brandImage) {
            toast.error("Please add brand image");
        } else {
            setLoading(true);
            //send data
            try {
                const formdata = new FormData();
                formdata.append("brand", brandImage);
                formdata.append("brandName", brandName);
                formdata.append("brandDescription", description);
                const response = await createBrand(formdata);
                if (response?.data?.success === 1 && response?.data?.data) {
                    if (response?.data.data.error) {
                        toast.error("Failed to save brand, please try again");
                    } else {
                        //
                        setImage("");
                        setBrand("");
                        setFiles([]);
                        getBrands();
                        dispatch(addBrand(response?.data?.data));
                        setIsModalOpen(false);
                        toast.success("Brand is saved successfully");
                    }
                    setLoading(false);
                } else {
                    toast.error(response?.data?.message);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        }
    };

    const handleImageUrl = (path) => {
        //console.log(path);
        try {
            const { data } = supabase.storage
                .from("jack-umeme-store")
                .getPublicUrl(path);

            if (data) {
                setImage(data?.publicUrl);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUploadImage = async (file) => {
        //console.log(file);
        try {
            //upload image
            const { data, error } = await supabase.storage
                .from("jack-umeme-store")
                .upload(`public_images/brands/${file.name}`, file, {
                    cacheControl: "3600",
                    upsert: false,
                });

            if (data) {
                //store image on list
                const list = [...fileList, file];
                setFiles(list);
                //
                handleImageUrl(data?.path);
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveImage = async (file) => {
        console.log(file);
        try {
            //remove image
            const { data, error } = await supabase.storage
                .from("jack-umeme-store")
                .remove([`public_images/brands/${file.name}`]);

            if (data) {
                //
                setFiles([]);
                setImage("");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const props = {
        name: "file",
        headers: {
            authorization: "authorization-text",
        },
        multiple: "false",
        onRemove: (file) => {
            handleRemoveImage(file);
        },
        beforeUpload: (file) => {
            //check format and size
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJpgOrPng) {
                toast.error("You can only upload JPG/PNG file!");
            } else if (!isLt2M) {
                toast.error("Image must be smaller than 2MB!");
            } else if (fileList.length !== 0) {
                toast.error("Sorry! You can only upload one image file!");
            } else {
                //update file list
                //send image to storage
                // handleUploadImage(file);
                setImage(file);
            }
            return false;
        },
        fileList,
    };

    return (
        <>
            <div
                onClick={showModal}
                className="h-10 w-40 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white"
            >
                <Add className="mt-2 py-0.5" />{" "}
                <p className="py-2">New Brand</p>
            </div>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                width={700}
            >
                <h4 className="text-center font-semibold text-xl">
                    Setup New Brand
                </h4>
                <div className="py-2 flex flex-col justify-center items-center">
                    <div className="w-[90%] py-2 flex flex-row gap-2">
                        <Input
                            size="large"
                            placeholder="Brand name"
                            onChange={(e) => setBrand(e.target.value)}
                            value={brandName}
                            className="w-full"
                        />
                        <Upload {...props}>
                            <Button
                                icon={
                                    <UploadOutlined className="text-center" />
                                }
                                size="large"
                                className="w-full text-center"
                            >
                                Click here to Upload new brand image
                            </Button>
                        </Upload>
                    </div>
                    <div className="w-[90%] py-2">
                        <TextArea
                            rows={2}
                            placeholder="Description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                    </div>
                    <div className="w-[90%] py-2 pt-4">
                        {loading ? (
                            <button
                                type="button"
                                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                            >
                                Loading ...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                                onClick={() => handleSaveBrand()}
                            >
                                SAVE BRAND
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddBrand;
