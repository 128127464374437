import React, { useState } from "react";
import { Button, Input, message, Modal, Select } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { addUsers } from "../../features/userSlice";

const { TextArea } = Input;

const EditUser = ({ user }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [firstName, setFirstName] = useState(user?.first_name);
  const [lastName, setLastName] = useState(user?.last_name);
  const [phoneNumber, setPhone] = useState(user?.phone);
  const [gender, setGender] = useState(user?.gender);
  const [loading, setLoading] = useState(false);

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const handleEditUser = async () => {
    //
    const getUsers = async () => {
      let { data: customUsers, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("role", "admin");

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addUsers(customUsers));
      }
    };

    if (!firstName) {
      message.error("Please enter first name");
    } else if (!lastName) {
      message.error("Please enter last name");
    } else if (!phoneNumber) {
      message.error("Please enter phone number");
    } else if (!gender) {
      message.error("Please enter gender");
    } else {
      setLoading(true);
      //send data

     
    }
  };

  return (
    <>
      <Button
        type="text"
        shape="circle"
        className="flex justify-center"
        onClick={showModal}
      >
        <MdEdit className="text-red-500 text-xl cursor-pointer" />
      </Button>

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        // style={{ top: 30 }}
        width={600}
      >
        <div className="w-[100%]">
          <div className="py-2 flex flex-col justify-center items-center">
            <div className="w-[80%] flex flex-row gap-2 py-2">
              <Input
                size="large"
                placeholder="First name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                className="w-full"
              />
              <Input
                size="large"
                placeholder="Last name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                className="w-full"
              />
            </div>
            <div className="w-[80%] flex flex-row gap-2 py-2">
              <Input
                size="large"
                placeholder="Phone number"
                onChange={(e) => setPhone(e.target.value)}
                value={phoneNumber}
                className="w-full"
              />
              <Select
              size="large"
              value={gender}
              className="w-full"
              // style={{
              //   width: 120,
              // }}
              onChange={handleGenderChange}
              options={[
                {
                  value: "female",
                  label: "Female",
                },
                {
                  value: "male",
                  label: "Male",
                },
              ]}
            />
            </div>
            <div className="w-[80%] py-2 pt-4">
              {loading ? (
                <button
                  type="button"
                  className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                  onClick={() => handleEditUser()}
                >
                  EDIT USER
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditUser;
