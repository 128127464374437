import apiClient from "../client/ClientApi";

export const createSelcomOrder = async ({ name, phone, amount, order_id }) => {
    const response = await apiClient.post("/payment/mobile/" + order_id, {
        name,
        phone,
        amount,
        order_id,
    });
    return response;
};

export const confirmOrderPayment = async (payload) => {
    const response = await apiClient.post("/payment/wallet-pay", payload);
    return response;
};

export const cancelSelcomOrder = async ({ phone, uid, order_id }) => {
    const response = await apiClient.post("/payment/cancel", {
        uid,
        order_id,
    });
    return response;
};
