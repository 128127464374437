import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Modal, DatePicker, Select } from "antd";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import Add from "@mui/icons-material/Add";
import { toast } from "react-hot-toast";
import {
  addPromotionProducts,
  addPromotions,
  selectPromotionProducts,
} from "../../features/promotionSlice";
import { selectShops } from "../../features/shopSlice";
import { createPromotion } from "../../api/PromotionAPI";

const AddPromotion = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [banner, setImage] = useState("");
  const [shop, setShop] = useState(null);
  const [product, setProduct] = useState(null);
  const [promoType, setPromoType] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [fileList, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };

  const handleShopChange = async (id, value) => {
    //fetch shop products
    try {
      let { data: products, error } = await supabase
        .from("products")
        .select("*, model(*), brand(*), category(*)")
        .eq("shop", value?.id);

      if (error) {
        console.log(error.message);
      } else {
        // console.log(products);
        dispatch(addPromotionProducts(products));
      }
    } catch (error) {
      console.log(error.message);
    }
    setShop(value);
  };

  const handleProductChange = (id, value) => {
    setProduct(value);
  };

  const handlePromoChange = async (value) => {
    //fetch shop products
    if (shop?.id) {
      try {
        let { data: products, error } = await supabase
          .from("products")
          .select("*, model(*), brand(*), category(*)")
          .eq("shop", shop?.id);

        if (error) {
          console.log(error.message);
        } else {
          //console.log(products);
          dispatch(addPromotionProducts(products));
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    setPromoType(value);
  };
  console.log(promoType);
  const shops = useSelector(selectShops);
  const products = useSelector(selectPromotionProducts);

  const sortedShops = shops.map((shop) => ({
    id: shop.id,
    value: shop.shopName,
  }));

  const sortedProducts = products.map((product) => ({
    id: product.id,
    value: `${product?.title} ${product?.condition} ${product?.brand?.brandName} ${product?.model?.modelName} ${product?.year}`,
  }));

  const handleSavePromo = async () => {
    //initialize get promos
    const getPromos = async () => {
      let { data: promotions, error } = await supabase
        .from("promotions")
        .select("*, shops(*), products(*)");

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addPromotions(promotions));
      }
    };

    if (!banner) {
      toast.error("Please add banner image");
    } else if (!start_date) {
      toast.error("Please select start date");
    } else if (!end_date) {
      toast.error("Please select end date");
    } else {
      setLoading(true);
      //send data
      if (promoType === "product") {
        //set shop id null
        try {
          const formdata = new FormData();
          formdata.append("promotion", banner);
          // formdata.append("shopId", null);
          formdata.append("productId", product?.id);
          formdata.append("start_date", start_date);
          formdata.append("end_date", end_date);
          const response = await createPromotion(formdata);
          console.log(response);

          if (response?.data?.success === 1 && response?.data?.data) {
            if (response?.data.data.error) {
              toast.error("Failed to save promotion, please try again");
            } else {
              //
              setShop("");
              setProduct("");
              setPromoType("");
              setImage("");
              setFiles([]);
              setStartDate("");
              setEndDate("");

              getPromos();
              toast.success("Promotion is saved successfully");
            }
            setLoading(false);
          } else {
            toast.error("Failed to save promotion, please try again");
            setLoading(false);
          }
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }
      } else {
        //set product id nulls
        try {
          const formdata = new FormData();
          formdata.append("promotion", banner);
          formdata.append("shopId", shop?.id);
          // formdata.append("productId", null);
          formdata.append("start_date", start_date);
          formdata.append("end_date", end_date);
          const response = await createPromotion(formdata);
          console.log(response);

          if (response?.data?.success === 1 && response?.data?.data) {
            //
            setShop("");
            setProduct("");
            setPromoType("");
            setImage("");
            setFiles([]);
            setStartDate("");
            setEndDate("");

            getPromos();
            toast.success("Promotion is saved successfully");
            setLoading(false);
          } else {
            toast.error("Failed to save promotion, please try again");
            setLoading(false);
          }
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }
      }
    }
  };

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    multiple: "false",
    beforeUpload: (file) => {
      //check format and size
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJpgOrPng) {
        toast.error("You can only upload JPG/PNG file!");
      } else if (!isLt2M) {
        toast.error("Image must be smaller than 2MB!");
      } else if (fileList.length !== 0) {
        toast.error("Sorry! You can only upload one image file!");
      } else {
        //update file list
        setImage(file);
      }
      return false;
    },
    fileList,
  };

  return (
    <>
      <div
        onClick={showModal}
        className="h-10 w-44 bg-purple-500 cursor-pointer rounded-full flex flex-row gap-1 justify-center text-white"
      >
        <Add className="mt-2 py-0.5" /> <p className="py-2">Add Promotion</p>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
        width={700}
      >
        <h4 className="text-center font-semibold text-xl">
          Create New Promotion
        </h4>
        <div className="py-2 flex flex-col justify-center items-center">
          <div className="w-[90%] py-2">
            <Upload {...props}>
              <Button
                icon={<UploadOutlined className="text-center" />}
                size="large"
                className="text-center"
                style={{ width: 582 }}
              >
                Click here to upload promotion image
              </Button>
            </Upload>
          </div>
          <div className="w-[90%] py-2">
            <Select
              style={{
                width: 582,
              }}
              size="large"
              placeholder="Select promotion type"
              onChange={handlePromoChange}
              options={[
                {
                  value: "shop",
                  label: "Shop",
                },
                {
                  value: "product",
                  label: "Product",
                },
              ]}
            />
          </div>
          <div className="w-[90%] py-2">
            <Select
              showSearch
              style={{
                width: 582,
              }}
              placeholder="Select Shop"
              optionFilterProp="children"
              size="large"
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.value ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.value ?? "").toLowerCase())
              }
              onChange={handleShopChange}
              options={sortedShops}
            />
          </div>
          {promoType === "product" ? (
            <div className="w-[90%] py-2">
              <Select
                showSearch
                style={{
                  width: 582,
                }}
                placeholder="Select product"
                optionFilterProp="children"
                size="large"
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.value ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.value ?? "").toLowerCase())
                }
                onChange={handleProductChange}
                options={sortedProducts}
              />
            </div>
          ) : null}
          <div className="w-[90%] py-2 flex flex-row gap-2">
            <DatePicker
              onChange={onStartDateChange}
              size="large"
              style={{ width: 350 }}
              placeholder="Start date"
            />
            <DatePicker
              onChange={onEndDateChange}
              size="large"
              style={{ width: 350 }}
              placeholder="End date"
            />
          </div>
          <div className="w-[90%] py-2 pt-4">
            {loading ? (
              <button
                type="button"
                className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
              >
                Loading ...
              </button>
            ) : (
              <button
                type="button"
                className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                onClick={() => handleSavePromo()}
              >
                SAVE PROMOTION
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddPromotion;
