import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Divider,
    Button,
    Switch,
    Popconfirm,
    Image,
    Table,
    Input,
    Space,
} from "antd";
import { FaEye } from "react-icons/fa";
import supabase from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import {
    addBrandDetails,
    addBrands,
    addFilteredBrands,
    removeBrand,
    selectBrands,
    selectFilteredBrands,
} from "../../features/brandSlice";
import AddBrand from "./AddBrand";
import { RiDeleteBin5Line } from "react-icons/ri";
import EditBrand from "./EditBrand";
import { toast } from "react-hot-toast";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const { Search } = Input;

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Brand Image",
        dataIndex: "brandImage",
        key: "brandImage",
        render: (_, { brandImage }) => (
            <>
                <ViewImage image={brandImage} />
            </>
        ),
    },
    {
        title: "Brand Name",
        dataIndex: "brandName",
        key: "brandName",
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (_, brand) => (
            <>
                <BrandStatus brand={brand} />
            </>
        ),
    },
    {
        title: "Models",
        key: "models",
        render: (_, brand) => (
            <>
                <ViewBrandModels brand={brand} />
            </>
        ),
    },
    {
        title: "Description",
        dataIndex: "brandDescription",
        key: "description",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Actions",
        key: "action",
        render: (_, brand) => (
            <p className="flex flex-row justify-start gap-2">
                <EditBrand brand={brand} />
                <DeleteBrand brand={brand} />
            </p>
        ),
    },
];

const BrandStatus = ({ brand }) => {
    const dispatch = useDispatch();

    const getBrands = async () => {
        let { data: brands, error } = await supabase.from("brands").select("*");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addBrands(brands));
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("brands")
                .update({
                    status: !brand?.status,
                })
                .eq("id", brand?.id)
                .select("*");

            if (data) {
                //
                getBrands();
                toast.success("Status is changed successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                brand?.status ? "deactivate" : "activate"
            } this brand?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={brand?.status}
                className={brand?.status ? null : `bg-zinc-300 rounded-full`}
            />
        </Popconfirm>
    );
};

const ViewBrandModels = ({ brand }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBrand = () => {
        //get brand details the navigate to brand page
        dispatch(addBrandDetails(brand));
        navigate(`/brands/${brand?.id}/models`);
    };

    return (
        <Button
            type="text"
            shape="circle"
            className="flex justify-center"
            onClick={() => handleBrand()}
        >
            <FaEye className="text-red-500 text-xl cursor-pointer" />
        </Button>
    );
};

const DeleteBrand = ({ brand }) => {
    const dispatch = useDispatch();

    const getBrands = async () => {
        let { data: brands, error } = await supabase.from("brands").select("*");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addBrands(brands));
        }
    };

    const confirmDelete = async () => {
        //detelete category
        try {
            const { data, error } = await supabase
                .from("brands")
                .delete()
                .eq("id", brand?.id)
                .select("*");

            if (data) {
                //
                // getBrands();
                dispatch(removeBrand(brand?.id));
                toast.success("Brand is deleted successfully");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Delete Brand"
            description="Are you sure to delete this brand?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={() => confirmDelete()}
        >
            <Button type="text" shape="circle" className="flex justify-center">
                <RiDeleteBin5Line className="text-red-500 text-xl cursor-pointer" />
            </Button>
        </Popconfirm>
    );
};

const ViewImage = ({ image }) => {
    return (
        <Image
            width={120}
            height={80}
            src={image}
            style={{ objectFit: "cover" }}
        />
    );
};

const Brands = () => {
    const dispatch = useDispatch();

    // const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);

    useEffect(() => {
        const getBrands = async () => {
            setDataLoad(true);
            let { data: categories, error } = await supabase
                .from("brands")
                .select("*");

            if (error) {
                setDataLoad(false);
                console.log(error.message);
            } else {
                setDataLoad(false);
                dispatch(addBrands(categories));
            }
        };

        getBrands();
    }, [dispatch]);

    const brands = useSelector(selectBrands);
    const allBrands = brands
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedBrands = allBrands.map((brand, index) => {
        const key = index + 1;
        return { ...brand, key };
    });

    const handleOnSearchChange = (searchText) => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedBrands = brands.filter((brand) => {
                const name = brand?.brandName.toLocaleLowerCase();
                return name.includes(text);
            });

            // Update state with filtered brands
            dispatch(addFilteredBrands(searchedBrands));
            setFilters(true);
        } else {
            // Update state with filtered brands
            dispatch(addFilteredBrands([]));
            setFilters(false);
        }
    };

    // const handleSearchText = (value) => {
    //     if (value) {
    //         setSearchText(value);
    //     } else {
    //         // Update state with filtered brands
    //         dispatch(addFilteredBrands([]));
    //         setFilters(false);
    //         setSearchText(value);
    //     }
    // };

    const filteredBrands = useSelector(selectFilteredBrands);

    const allFilteredBrands = filteredBrands
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredBrands = allFilteredBrands.map((brand, index) => {
        const key = index + 1;
        return { ...brand, key };
    });

    return (
        <div>
            <PageHeader
                head={"JU System brands"}
                startAction={
                    <div className="flex flex-row gap-4 justify-end items-end px-2 ">
                        <div style={{ width: 250 }}>
                            <Space.Compact size="large">
                                <Input
                                    placeholder="Search brand name here"
                                    allowClear
                                    onChange={(e) => {
                                        // handleSearchText(e.target.value);
                                        handleOnSearchChange(e.target.value);
                                    }}
                                    // onSearch={() => handleOnSearchChange()}
                                />
                            </Space.Compact>
                        </div>
                    </div>
                }
                action={
                    <div className="flex flex-row gap-4 justify-end items-end px-2 ">
                        <AddBrand />
                    </div>
                }
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <div>
                        {filters ? (
                            <Table
                                columns={columns}
                                dataSource={sortedFilteredBrands}
                            />
                        ) : (
                            <Table
                                columns={columns}
                                dataSource={sortedBrands}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Brands;
