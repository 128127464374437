export const colors = {
    primary: "#7D62D4",
    secondary: "#004e98",
    bgColor1: "#e5dff6",
    bgColor2: "#d8cff2",
    bgColor3: "#cbc0ed",
    bgColor4: "#beb0e9",
    yellow: "#FFD900",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};
