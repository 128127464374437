import React from "react";
import { Route, Routes } from "react-router-dom";
import Brands from "../pages/brands/Brands";
import Categories from "../pages/categories/Categories";
import Customer from "../pages/customers/Customer";
import Customers from "../pages/customers/Customers";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import Models from "../pages/models/Models";
import Order from "../pages/order/Order";
import Product from "../pages/products/Product";
import Products from "../pages/products/Products";
import Shop from "../pages/shops/Shop";
import ShopProduct from "../pages/shops/ShopProduct";
import Shops from "../pages/shops/Shops";
import Profile from "../pages/users/Profile";
import Users from "../pages/users/Users";
import Vendor from "../pages/vendors/Vendor";
import Vendors from "../pages/vendors/Vendors";
import Promotions from "../pages/promo/Promotions";
import Conversations from "../pages/chats/Conversations";
import Delivery from "../pages/delivery/Delivery";
import POSOrders from "../pages/order/POSOrders";
import CustomerOrders from "../pages/order/CustomerOrders";
import CreatePosOrder from "../pages/pos/CreatePosOrder";
import CustomerOrderDetails from "../pages/order/CustomerOrderDetails";
import POSOrderDetails from "../pages/order/POSOrderDetails";

// ?############## GUEST ELEMENTS ###########

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
  <AppLayout>
    <Dashboard />
  </AppLayout>
);

const CreatePosOrderElement = () => (
  <AppLayout>
    <CreatePosOrder />
  </AppLayout>
);

const PosOrdersElement = () => (
  <AppLayout>
    <POSOrders />
  </AppLayout>
);
const PosOrderDetailsElement = () => (
  <AppLayout>
    <POSOrderDetails />
  </AppLayout>
);

const OrdersElement = () => (
  <AppLayout>
    <CustomerOrders />
  </AppLayout>
);
const OrderDetailsElement = () => (
  <AppLayout>
    <CustomerOrderDetails />
  </AppLayout>
);

const CategoriesElement = () => (
  <AppLayout>
    <Categories />
  </AppLayout>
);

const BrandsElement = () => (
  <AppLayout>
    <Brands />
  </AppLayout>
);

const ModelsElement = () => (
  <AppLayout>
    <Models />
  </AppLayout>
);

const UsersElement = () => (
  <AppLayout>
    <Users />
  </AppLayout>
);

const OrderElement = () => (
  <AppLayout>
    <Order />
  </AppLayout>
);

const VendorsElement = () => (
  <AppLayout>
    <Vendors />
  </AppLayout>
);

const CustomersElement = () => (
  <AppLayout>
    <Customers />
  </AppLayout>
);

const CustomerElement = () => (
  <AppLayout>
    <Customer />
  </AppLayout>
);

const ProfileElement = () => (
  <AppLayout>
    <Profile />
  </AppLayout>
);

const ShopsElement = () => (
  <AppLayout>
    <Shops />
  </AppLayout>
);

const ShopElement = () => (
  <AppLayout>
    <Shop />
  </AppLayout>
);

const VendorElement = () => (
  <AppLayout>
    <Vendor />
  </AppLayout>
);

const ProductsElement = () => (
  <AppLayout>
    <Products />
  </AppLayout>
);

const ProductElement = () => (
  <AppLayout>
    <Product />
  </AppLayout>
);

const ShopProductElement = () => (
  <AppLayout>
    <ShopProduct />
  </AppLayout>
);

const PromotionsElement = () => (
  <AppLayout>
    <Promotions />
  </AppLayout>
);

const ChatsElement = () => (
  <AppLayout>
    <Conversations />
  </AppLayout>
);

const DeliveryElement = () => (
  <AppLayout>
    <Delivery />
  </AppLayout>
);
const ModelElement = () => (
  <AppLayout>
    <Models />
  </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<DashboardElement />} />
        <Route path="/orders/pos/create" element={<CreatePosOrderElement />} />
        <Route path="/orders/pos" element={<PosOrdersElement />} />
        <Route
          path="/orders/pos/:orderID"
          element={<PosOrderDetailsElement />}
        />
        <Route path="/categories" element={<CategoriesElement />} />
        <Route path="/brands" element={<BrandsElement />} />
        <Route path="/orders/customers" element={<OrdersElement />} />
        <Route
          path="/orders/customers/:orderID"
          element={<OrderDetailsElement />}
        />
        <Route path="/users" element={<UsersElement />} />
        <Route path="/vendors" element={<VendorsElement />} />
        <Route path="/shops" element={<ShopsElement />} />
        <Route path="/customers" element={<CustomersElement />} />
        <Route path="/products" element={<ProductsElement />} />
        <Route path="/promotions" element={<PromotionsElement />} />
        <Route path="/chats" element={<ChatsElement />} />
        <Route path="/delivery" element={<DeliveryElement />} />
        <Route path="/models" element={<ModelElement />} />

        <Route path="/brands/:brandID/models" element={<ModelsElement />} />
        <Route path="/orders/:orderID" element={<OrderElement />} />
        <Route path="/customers/:customerID" element={<CustomerElement />} />
        <Route path="/users/profile" element={<ProfileElement />} />
        <Route path="/shops/:shopID" element={<ShopElement />} />
        <Route
          path="/shops/:shopID/shop-products/:productID"
          element={<ShopProductElement />}
        />
        <Route path="/vendors/:vendorID" element={<VendorElement />} />
        <Route path="/products/:productID" element={<ProductElement />} />
      </Routes>
    </React.Fragment>
  );
};

export default App;
