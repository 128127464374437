import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dialogues: [],
  messages: [],
};

const chatsSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addDialogues(state, action) {
      state.dialogues = action.payload;
    },

    addMessages(state, action) {
      state.messages = action.payload;
    },
  },
});

export const { addDialogues, addMessages } = chatsSlice.actions;

export const selectDialogues = (state) => state.chat.dialogues;
export const selectMessages = (state) => state.chat.messages;

export default chatsSlice.reducer;
