import React from "react";
import AppRoutes from "./routes/App.routes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <React.Fragment>
      <Toaster />
      <AppRoutes />
    </React.Fragment>
  );
}

export default App;
