import { Card, CardContent } from "@mui/material";
import { Button, Divider, Modal, Select, Tag } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addOrderDetails,
  addOrderShopOrder,
  addOrderShopOrders,
  selectOrderDetails,
  selectOrderShopOrders,
} from "../../features/orderSlice";
import supabase from "../../supabaseClient";
// import MakeOrderPayment from "./MakeOrderPayment";
import { isEmpty } from "lodash";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";
import { addShops, selectShops } from "../../features/shopSlice";
import CustomerOrderTable from "../../components/CustomerOrderTable";
import { IoMdClose } from "react-icons/io";
import { RotatingLines } from "react-loader-spinner";
import apiClient from "../../client/ClientApi";

// const Status = ({ status }) => {
//   if (status === "placed") {
//     return <Tag color={"blue"}>Placed</Tag>;
//   } else if (status === "accepted") {
//     return <Tag color={"green"}>Accepted</Tag>;
//   } else if (status === "shipped") {
//     return <Tag color={"green"}>Shipped</Tag>;
//   } else if (status === "delivered") {
//     return <Tag color={"green"}>Delivered</Tag>;
//   } else if (status === "cancelled") {
//     return <Tag color={"red"}>Cancelled</Tag>;
//   } else {
//     return <Tag color={"green"}>Refunded</Tag>;
//   }
// };

const EditStatus = () => {
  const dispatch = useDispatch();
  const { orderID } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [orderStatus, setStatus] = useState("");

  const onChange = (id, value) => {
    if (value) {
      setStatus(value?.id);
    } else {
      setStatus("");
    }
  };
  const onSearch = (id, value) => {
    console.log("search:", value, id);
  };

  const getOrderDetails = async () => {
    let { data: orders, error } = await supabase
      .from("orders")
      .select("*")
      .eq("id", orderID);

    if (error) {
      console.log(error.message);
    } else {
      //console.log(orders);
      dispatch(addOrderDetails(orders[0]));
    }
  };

  const handleChangeOrderStatus = async () => {
    if (!orderStatus) {
      toast.warning("Please select order status");
    } else {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("orders")
          .update({
            status: orderStatus,
          })
          .eq("id", orderID)
          .select("*");

        if (data) {
          //
          getOrderDetails();
          setLoading(false);
          toast.success("Status is changed successfully");
        } else {
          toast.error(error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Button
        type="text"
        shape="circle"
        className="flex justify-center"
        onClick={showModal}
      >
        <BiEditAlt className="text-lg cursor-pointer" />
      </Button>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "hidden",
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <h4 className="text-lg font-semibold text-center pb-2">
          CHANGE ORDER STATUS
        </h4>
        <div className="w-full">
          <div className="flex flex-row justify-center py-3">
            <Select
              showSearch
              style={{
                width: 350,
              }}
              placeholder="Select status"
              optionFilterProp="children"
              size="large"
              allowClear
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  id: 2,
                  value: "Accepted",
                },
                {
                  id: 3,
                  value: "Shipped",
                },
                {
                  id: 4,
                  value: "Delivered",
                },
                {
                  id: 5,
                  value: "Cancelled",
                },
                {
                  id: 6,
                  value: "Refunded",
                },
              ]}
            />
          </div>
          <div className="flex flex-row justify-center py-2 pt-4">
            {loading ? (
              <button
                type="button"
                className="px-6 py-2 w-[74%] cursor-not-allowed opacity-25 border rounded-md border-purple-500 hover:bg-purple-500 hover:text-white"
              >
                Loading ...
              </button>
            ) : (
              <button
                type="button"
                className="px-6 py-2 w-[74%] border rounded-md border-purple-500 hover:bg-purple-500 hover:text-white"
                onClick={() => handleChangeOrderStatus()}
              >
                SAVE CHANGES
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const CustomerOrderDetails = () => {
  const dispatch = useDispatch();
  const { orderID } = useParams();
  const orderShopOrder = useSelector(selectOrderShopOrders);
  const [dispatchModal, setdispatchModal] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [count, setCount] = useState(0);
  const [selectedShop, setSelectedShop] = useState(null);
  const [deliverLoading, setDeliverLoading] = useState(false);

  const handleModalOpen = (item) => {
    if (!isEmpty(item)) {
      setSelectedItem(item.item);
      setCount(item.count);
      setSelectedShop(item.selectedShop);
      setdispatchModal(!dispatchModal);
    }
  };

  const [dataLoadng, setDataLoading] = useState(true);

  const handleDeliver = async () => {
    setDeliverLoading(true);
    try {
      var res = await apiClient.put(
        `/shop-orders/deliver-order`,
        { orderId: orderID },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!isEmpty(res)) {
        dispatch(addOrderDetails(res.data.data));
      }
    } catch (error) {
      console.warn(error);
    }
    setDeliverLoading(false);
  };

  useEffect(() => {
    const getShopOrders = async () => {
      setDataLoading(true);
      let { data: shop_orders, error } = await supabase
        .from("shop_orders")
        .select("*, shops(*), orders(*)")
        .eq("order_id", orderID);

      if (shop_orders) {
        dispatch(addOrderShopOrders(shop_orders));
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
      // handling the error
      if (error) {
        console.log("the error", error);
      }
    };

    const getOrderDetails = async () => {
      let { data: orders, error } = await supabase
        .from("orders")
        .select("*, customer(*)")
        .select("*, transactions(*)")
        .eq("id", orderID);

      if (error) {
        console.log(error.message);
      } else {
        dispatch(addOrderDetails(orders[0]));
      }
    };

    getShopOrders();
    getOrderDetails();
    return () => {
      dispatch(addOrderDetails([]));
    };
  }, [dispatch, orderID]);

  const getShops = useCallback(async () => {
    let { data: shops, error } = await supabase
      .from("shops")
      .select("*, shopOwner(*)");

    if (shops) {
      dispatch(addShops(shops));
    } else {
      console.log(error.message);
    }
  }, [dispatch]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getShops();
    }

    return () => {
      isSubscribed = false;
    };
  }, [getShops]);

  const shops = useSelector(selectShops);
  const orderDetails = useSelector(selectOrderDetails);

  const renderStatus = (status) => {
    if (status === 1) {
      return <Tag color={"blue"}>Placed</Tag>;
    } else if (status === 2) {
      return <Tag color={"green"}>Processed</Tag>;
    } else if (status === 3) {
      return <Tag color={"green"}>Shipped</Tag>;
    } else if (status === 4) {
      return <Tag color={"green"}>Delivered</Tag>;
    } else if (status === 5) {
      return <Tag color={"red"}>Cancelled</Tag>;
    } else {
      return <Tag color={"green"}>Refunded</Tag>;
    }
  };

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <div className="relative">
      <div>
        <PageHeader head={"Customer Order Details "} />
      </div>
      {dataLoadng ? (
        <>
          <DataLoader />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-center items-center">
            <Card sx={{ width: "100%", background: "#F3E8FF" }}>
              <CardContent>
                <div className="flex flex-row gap-2 py-1">
                  <p className="py-1 w-[50%]">
                    Order Number : {orderDetails?.order_number}
                  </p>
                  <p className="py-1 w-[50%]">
                    Order Type : {orderDetails?.isPOS ? <>POS</> : <>DIRECT</>}
                  </p>
                </div>
                <div className="flex flex-row gap-2 py-1">
                  <p className="py-1 w-[50%]">
                    Total Amount: TZS{" "}
                    {formatter.format(orderDetails?.total_amount)}
                  </p>
                  <p className="py-1 w-[50%]">
                    Date :{" "}
                    {moment(orderDetails?.created_at).format(
                      "DD-MM-YYYY, h:mm a"
                    )}
                  </p>
                </div>
                <p className="py-1">Customer: {orderDetails?.order_number}</p>
                <div className="flex flex-row gap-2 py-1">
                  <p className="py-1 w-[50%]">
                    Payment:{" "}
                    {orderDetails?.isPaid ? (
                      <Tag color={"green"}>Paid</Tag>
                    ) : (
                      <Tag color={"red"}>Not Paid</Tag>
                    )}
                  </p>
                  <div className="py-1 w-[45%]">
                    Status: {renderStatus(orderDetails?.status)}
                  </div>
                  <div className="py-1 w-[5%]">
                    <EditStatus />
                  </div>
                </div>
                <div>
                  {orderDetails.status > 1 ? null : (
                    <button
                      onClick={handleDeliver}
                      className="bg-green-500 rounded-lg text-white px-4 py-2"
                    >
                      {deliverLoading ? (
                        <span className="w-full flex items-center justify-center gap-x-3  text-white">
                          <RotatingLines
                            visible={deliverLoading}
                            height="25"
                            width="25"
                            color="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                          Loading...
                        </span>
                      ) : (
                        "Deliver Order"
                      )}
                    </button>
                  )}
                </div>
                {/* {!isEmpty(orderDetails?.transactions) && (
                  <div className="flex flex-row gap-2 py-1">
                    <MakeOrderPayment
                      orderID={orderDetails?.id}
                      transactionID={
                        orderDetails?.transactions?.[0].transaction_id
                      }
                    />
                  </div>
                )} */}
              </CardContent>
            </Card>
          </div>
          <div className="py-3">
            <Divider plain className="py-2">
              <p className="text-lg font-semibold">Shop Orders</p>
            </Divider>
            <CustomerOrderTable
              shops={shops}
              handleModalOpen={handleModalOpen}
              products={orderDetails.ordered_products}
              status={renderStatus(orderDetails?.status)}
              orderStatus={orderDetails?.status}
              shopOrder={orderShopOrder}
            />
          </div>
        </>
      )}
      {dispatchModal && !isEmpty(selectedShop) && !isEmpty(selectedItem) && (
        <DispatchOrderModal
          dispatchModal={dispatchModal}
          setdispatchModal={setdispatchModal}
          product={selectedItem}
          count={count}
          shop={selectedShop}
          shops={shops}
          order_id={orderID}
        />
      )}
    </div>
  );
};

const DispatchOrderModal = ({
  dispatchModal,
  setdispatchModal,
  product,
  count,
  shop,
  shops,
  order_id,
}) => {
  const [currentShop, setCurrentShop] = useState(shop.id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //handling shop change
  const handleShopChange = (e) => {
    setCurrentShop(e.target.value);
  };
  const handleClose = () => {
    setdispatchModal(!dispatchModal);
  };

  const handleDispatchOrder = async () => {
    setLoading(true);
    const shopOrder = {
      order_id,
      shop_id: currentShop,
      ordered_products: { ...product, product_count: count },
      total_amount: product.price,
    };
    //sending the order to the server
    try {
      const response = await apiClient.post("/shop-orders/create", shopOrder, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!isEmpty(response) && !isEmpty(response.data)) {
        dispatch(addOrderShopOrder(response.data.data[0]));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="fixed w-screen h-screen top-0 left-0 backdrop-blur-md bg-black/50 flex items-center justify-center z-[9999]">
      <div className="w-[40%] bg-white py-6 rounded-xl">
        <div className="w-full flex items-center justify-end px-8">
          <div
            onClick={handleClose}
            className="p-2 text-lg rounded-full bg-blue-500/50 border border-blue-500 text-blue-700 cursor-pointer"
          >
            <IoMdClose />
          </div>
        </div>
        <div className="max-w-sm mx-auto">
          <div className="mb-5">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Choose shop
            </label>

            <select
              id="shops"
              onChange={handleShopChange}
              defaultValue={shop.id}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="" selected={currentShop === ""}>
                Choose a shop
              </option>
              {!isEmpty(shops) &&
                shops.map((shop) => (
                  <option
                    key={shop.id}
                    value={shop.id}
                    selected={currentShop === shop.id}
                  >
                    {shop.shopName}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full">
            <button
              onClick={handleDispatchOrder}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center"
            >
              {loading ? (
                <span className="w-full flex items-center justify-center gap-x-3 ">
                  <RotatingLines
                    visible={loading}
                    height="25"
                    width="25"
                    color="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  Loading...
                </span>
              ) : (
                "Dispatch Order"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderDetails;
