import React, { useEffect, useState } from "react";
import { Button, Image, Segmented, Table, Tag, Input, Space } from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addProductDetails,
    addProducts,
    addSearchedProducts,
    selectAllProducts,
    selectSearchedProducts,
    updateProducts,
} from "../../features/productsSlice";
import supabase from "../../supabaseClient";
import PageHeader from "../../components/PageHeader";
import { colors } from "../../assets/utils/colors";
import DataLoader from "../../components/DataLoader";
import { Bookmark, BookmarkBorderOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import toast from "react-hot-toast";

const { Search } = Input;

const formatter = new Intl.NumberFormat("en-US");

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Image",
        dataIndex: "bannerImage",
        key: "bannerImage",
        render: (_, { bannerImage }) => (
            <>
                <ViewImage image={bannerImage} />
            </>
        ),
    },
    {
        title: "Name",
        dataIndex: "title",
        key: "title",
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text) => <p>TZS {formatter.format(text)}</p>,
    },
    {
        title: "Brand",
        key: "brand",
        render: (_, product) => (
            <>
                <p className="capitalize">{product?.brand?.brandName}</p>
            </>
        ),
    },
    {
        title: "Model",
        key: "model",
        render: (_, product) => (
            <>
                <p className="capitalize">{product?.model?.modelName}</p>
                <p className="">{product?.year}</p>
            </>
        ),
    },
    {
        title: "Condition",
        dataIndex: "condition",
        key: "condition",
        render: (text) => <p className="capitalize">{text}</p>,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => (
            <>
                <Status status={status} />
            </>
        ),
    },
    {
        title: "Action",
        key: "action",
        render: (_, product) => <ViewProduct product={product} />,
    },
];

const Status = ({ status }) => {
    if (status === "pending") {
        return <Tag color={"blue"}>Pending</Tag>;
    } else if (status === "approved") {
        return <Tag color={"green"}>Approved</Tag>;
    } else {
        return <Tag color={"red"}>Suspended</Tag>;
    }
};

const ViewImage = ({ image }) => {
    return <Image width={80} height={80} src={image} />;
};

const ViewProduct = ({ product }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataUpdateLoad, setDataUpdateLoad] = useState(false);

    const handleViewProduct = () => {
        dispatch(addProductDetails(product));
        navigate(`/products/${product?.id}`);
    };

    const handleFeatureProduct = async (isFeature) => {
        setDataUpdateLoad(true);
        try {
            const { data, error } = await supabase
                .from("products")
                .update({
                    isFeature: isFeature,
                })
                .eq("id", product?.id)
                .select();
            if (data) {
                dispatch(updateProducts(data));
                setDataUpdateLoad(false);
                toast.success(
                    isFeature
                        ? "Product added to featured successfully"
                        : "Product removed from featured successfully"
                );
            } else {
                setDataUpdateLoad(false);
                toast.error(error.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <>
            <Button
                type="text"
                shape="circle"
                className="flex justify-center"
                onClick={() => handleViewProduct()}
            >
                <FaEye className="text-red-500 text-xl cursor-pointer" />
            </Button>
            {product.isFeature === true ? (
                <Tooltip title="Remove from featured">
                    <IconButton
                        size="small"
                        color="success"
                        onClick={() => handleFeatureProduct(false)}
                    >
                        {dataUpdateLoad ? (
                            <CircularProgress size={18} />
                        ) : (
                            <Bookmark />
                        )}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Add to featured">
                    <IconButton
                        size="small"
                        color="warning"
                        onClick={() => handleFeatureProduct(true)}
                    >
                        {dataUpdateLoad ? (
                            <CircularProgress size={18} />
                        ) : (
                            <BookmarkBorderOutlined />
                        )}
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

const Products = () => {
    const dispatch = useDispatch();

    const [productStatus, setProductStatus] = useState("");
    const [dataLoad, setDataLoad] = useState(false);

    // const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState(false);

    useEffect(() => {
        const getProducts = async () => {
            setDataLoad(true);
            let { data: products, error } = await supabase
                .from("products")
                .select("*, model(*), brand(*), category(*)");

            if (products) {
                setDataLoad(false);
                dispatch(addProducts(products));
            } else {
                console.log(error.message);
                setDataLoad(false);
            }
        };

        getProducts();
    }, [dispatch]);

    const products = useSelector(selectAllProducts);
    const statusFilters = {
        pending: (product) => product.status === "pending",
        approved: (product) => product.status === "approved",
        suspended: (product) => product.status === "suspended",
        featured: (product) => product.isFeature === true,
    };

    const filteredProducts = products.filter((product) => {
        if (productStatus && statusFilters[productStatus]) {
            return statusFilters[productStatus](product);
        } else {
            // Include all items in the result if product status is undefined or invalid
            return true;
        }
    });

    const allProducts = filteredProducts
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedProducts = allProducts.map((order, index) => {
        const key = index + 1;
        return { ...order, key };
    });

    const pendingProducts = products.filter(
        (product) => product.status === "pending"
    );

    const approvedProducts = products.filter(
        (product) => product.status === "approved"
    );

    const suspendedProducts = products.filter(
        (product) => product.status === "suspended"
    );

    const featuredProducts = products.filter(
        (product) => product.isFeature === true
    );

    const onStatusChange = (value) => {
        // console.log(value);
        setProductStatus(value);
    };

    const handleOnSearchChange = (searchText) => {
        if (searchText) {
            const text = searchText.toLocaleLowerCase();
            const searchedProducts = products.filter((product) => {
                const name = product?.title.toLocaleLowerCase();
                return name.includes(text);
            });

            // Update state with filtered products
            dispatch(addSearchedProducts(searchedProducts));
            setFilters(true);
        } else {
            // Update state with filtered products
            dispatch(addSearchedProducts([]));
            setFilters(false);
        }
    };

    // const handleSearchText = (value) => {
    //     if (value) {
    //         setSearchText(value);
    //     } else {
    //         // Update state with filtered products
    //         dispatch(addSearchedProducts([]));
    //         setFilters(false);
    //         setSearchText(value);
    //     }
    // };

    const selectedProducts = useSelector(selectSearchedProducts);

    const allFilteredProducts = selectedProducts
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedFilteredProducts = allFilteredProducts.map((product, index) => {
        const key = index + 1;
        return { ...product, key };
    });

    return (
        <div>
            <PageHeader
                head={"JU Vendors products"}
                action={
                    <div className="flex flex-row gap-8 justify-end items-end py-4 px-2">
                        <Space.Compact size="large">
                            <Input
                                placeholder="Search product name here"
                                allowClear
                                onChange={(e) =>
                                    handleOnSearchChange(e.target.value)
                                    // handleSearchText(e.target.value)
                                }
                                // onSearch={() => handleOnSearchChange()}
                            />
                        </Space.Compact>
                    </div>
                }
            />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <Segmented
                        block
                        options={[
                            {
                                label: `All Products (${products.length})`,
                                value: "",
                            },
                            {
                                label: `Pending Products (${pendingProducts.length})`,
                                value: "pending",
                            },
                            {
                                label: `Approved Products (${approvedProducts.length})`,
                                value: "approved",
                            },
                            {
                                label: `Suspended Products (${suspendedProducts.length})`,
                                value: "suspended",
                            },
                            {
                                label: `Featured Products (${featuredProducts.length})`,
                                value: "featured",
                            },
                        ]}
                        size="large"
                        value={productStatus}
                        onChange={onStatusChange}
                        style={{
                            padding: 15,
                            boxShadow: `0, 0, 2px 0`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        }}
                    />
                    <div>
                        {filters ? (
                            <Table
                                columns={columns}
                                dataSource={sortedFilteredProducts}
                            />
                        ) : (
                            <Table
                                columns={columns}
                                dataSource={sortedProducts}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Products;
