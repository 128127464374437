import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addShopDetails,
  addShopOrders,
  addShopProducts,
  selectShopDetails,
  selectShopOrders,
  selectShopProducts,
} from "../../features/shopSlice";
import supabase from "../../supabaseClient";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  AccountBox,
  Cancel,
  LocationOn,
  Phone,
  VerifiedOutlined,
} from "@mui/icons-material";
import ShopProducts from "./ShopProducts";
import ShopOrders from "./ShopOrders";
import { FaMedal } from "react-icons/fa";
import { Tag } from "antd";
import { Card } from "@mui/material";
import { toast } from "react-hot-toast";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShopManagement = ({ shop }) => {
  const dispatch = useDispatch();
  const {shopID} = useParams();

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [suspendLoading, setSuspendLoading] = useState(false);

  const getShop = async () => {
    let { data: shop, error } = await supabase
      .from("shops")
      .select("*, shopOwner(*)")
      .eq("id", shopID);

    if (shop) {
      dispatch(addShopDetails(shop[0]));
    } else {
      console.log(error.message);
    }
  };

  const handleVerifyStore = async () => {
    setVerifyLoading(true);
    try {
      const { data, error } = await supabase
        .from("shops")
        .update({
          isVerified: !shop?.isVerified,
        })
        .eq("id", shop?.id)
        .select("*");

      if (data) {
        //
        getShop();
        toast.success("Verification status is changed successfully");
        setVerifyLoading(false);
        
      } else {
        toast.error(error.message);
        setVerifyLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setVerifyLoading(false);
    }
  };

  const handleSuspendStore = async () => {
    setSuspendLoading(true);
    try {
      const { data, error } = await supabase
        .from("shops")
        .update({
          isSuspended: !shop?.isSuspended,
        })
        .eq("id", shop?.id)
        .select("*");

      if (data) {
        //
        getShop();
        toast.success("Suspension status is changed successfully");
        setSuspendLoading(false);
        
      } else {
        toast.error(error.message);
        setSuspendLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setSuspendLoading(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="w-[100%] h-[40%]">
        <div className="flex flex-row gap-2 justify-between">
          <Card sx={{ width: 400, height: 200, bgcolor: "#faf5ff" }}>
            <p className="text-center py-2 font-semibold">OWNER'S PROFILE</p>
            <div className="text-lg px-4 py-2">
              <p className="py-1">
                <AccountBox /> {shop?.shopOwner?.first_name}{" "}
                {shop?.shopOwner?.last_name}
              </p>
              <p className="py-1">
                <Phone /> {shop?.shopOwner?.phone}
              </p>
              <p className="py-1">
                <LocationOn /> {shop?.shopOwner?.region}
              </p>
              <p className="py-1">
                Status :{" "}
                {shop?.shopOwner?.status ? (
                  <Tag color={"green"}>Active</Tag>
                ) : (
                  <Tag color={"red"}>Deactivated</Tag>
                )}
              </p>
            </div>
          </Card>
          <Card
            sx={{ width: 400, height: 200, bgcolor: "#faf5ff" }}
            className="flex flex-col gap-4 justify-center"
          >
            <p className="text-center font-semibold">MANAGEMENT ACTIONS</p>
            <div className="py-4 px-6">
              {verifyLoading ? (
                <button
                  type="button"
                  className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                  onClick={() => handleVerifyStore()}
                >
                  {shop?.isVerified ? <>Remove Verification</> : <>Verify Store</>}
                </button>
              )}
            </div>
            <div className="py-1 px-6">
              {suspendLoading ? (
                <button
                  type="button"
                  className="px-6 py-2 w-full cursor-not-allowed opacity-25 border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="px-6 py-2 w-full border rounded-md border-violet-600 hover:bg-violet-600 hover:text-white"
                  onClick={() => handleSuspendStore()}
                >
                  {shop?.isSuspended ? (
                    <>Remove Suspension</>
                  ) : (
                    <>Suspend Store</>
                  )}
                </button>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const Shop = () => {
  const dispatch = useDispatch();
  const { shopID } = useParams();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getShop = async () => {
      let { data: shop, error } = await supabase
        .from("shops")
        .select("*, shopOwner(*)")
        .eq("id", shopID);

      if (shop) {
        dispatch(addShopDetails(shop[0]));
      } else {
        console.log(error.message);
      }
    };

    const getShopOrders = async () => {
      let { data: shop_orders, error } = await supabase
        .from("shop_orders")
        .select("*")
        .eq("shop_id", shopID || "");

      if (shop_orders) {
        dispatch(addShopOrders(shop_orders));
      } else {
        console.log(error.message);
      }
    };

    const getShopProducts = async () => {
      let { data: products, error } = await supabase
        .from("products")
        .select("*")
        .eq("shop", shopID);

      if (products) {
        dispatch(addShopProducts(products));
      } else {
        console.log(error.message);
      }
    };

    getShop();
    getShopOrders();
    getShopProducts();
  }, [dispatch]);

  const shopDetails = useSelector(selectShopDetails);
  const shopOrders = useSelector(selectShopOrders);
  const shopProducts = useSelector(selectShopProducts);

  return (
    <div className="px-4">
      <div className="w-[100%]">
        <div className="bg-purple-200 px-4 py-2 rounded-t-md">
          <div className="w-[100%] flex flex-row gap-2 justify-between">
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row gap-1 text-lg">
              <LocationOn className="my-2" />
              <p className="text-sm py-2.5">
                {shopDetails ? (
                  <>
                    {shopDetails?.region}, {shopDetails?.district},{" "}
                    {shopDetails?.street}
                  </>
                ) : null}
              </p>
            </div>
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row justify-between gap-1">
              <div>
                {shopDetails ? (
                  <p className="py-2 px-1">
                    {shopDetails?.isVerified ? (
                      <span className="flex flex-row gap-2">
                        <VerifiedOutlined className="text-xl text-green-500" />{" "}
                        <span className="">Verified</span>
                      </span>
                    ) : (
                      <span className="flex flex-row gap-2">
                        <Cancel className="text-xl text-red-500" />{" "}
                        <span className="">Not Verified</span>
                      </span>
                    )}
                  </p>
                ) : null}
              </div>
              <div>
                {shopDetails ? (
                  <p className="py-2 px-1">
                    {shopDetails?.shopRank === "silver" ? (
                      <span className="flex flex-row gap-2">
                        <FaMedal className="text-xl text-[#C0C0C0] mt-0.5" />{" "}
                        <span className="pr-1 py-0.5">Silver</span>
                      </span>
                    ) : (
                      <>
                        {shopDetails?.shopRank === "gold" ? (
                          <span className="flex flex-row gap-2">
                            <FaMedal className="text-xl text-[#FFD700] mt-0.5" />
                            <span className="pr-1 py-0.5">Gold</span>
                          </span>
                        ) : (
                          <span className="flex flex-row gap-2">
                            <FaMedal className="text-xl text-[#CD7F32] mt-0.5" />{" "}
                            <span className="pr-1 py-0.5">Bronze</span>
                          </span>
                        )}
                      </>
                    )}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 justify-between pt-2">
            <div className="py-1">
              <p className="text-xl py-1">{shopDetails?.shopName}</p>
              <p className="text-base py-1">{shopDetails?.contactNumber}</p>
            </div>
            <div className="py-1 flex flex-col gap-2">
              <p
                className={
                  shopDetails?.isActive
                    ? "w-[150px] h-8 border-2 bg-white border-green-500 rounded-full py-0.5 text-center text-green-500"
                    : "w-[150px] h-8 border-2 bg-white border-red-500 rounded-full py-0.5 text-center text-red-500"
                }
              >
                {shopDetails ? (
                  <>
                    {shopDetails?.isActive ? (
                      <span className="text-sm">OPEN</span>
                    ) : (
                      <span className="text-sm">CLOSED</span>
                    )}
                  </>
                ) : null}
              </p>
              <p
                className={
                  shopDetails?.isSupended
                    ? "w-[150px] h-8 bg-red-500 rounded-full py-1 text-center text-white base"
                    : "w-[150px] h-8  bg-green-500 rounded-full py-1 text-center text-white base"
                }
              >
                {shopDetails ? (
                  <>
                    {shopDetails?.isSupended ? (
                      <span className="text-sm">SUSPENDED</span>
                    ) : (
                      <span className="text-sm">NOT SUSPENDED</span>
                    )}
                  </>
                ) : null}
              </p>
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ bgcolor: "#d8b4fe" }}
            >
              <Tab
                label={`STORE PRODUCTS (${shopProducts.length})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`STORE ORDERS (${shopOrders.length})`}
                {...a11yProps(1)}
              />
              <Tab label="STORE MANAGEMENT" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ShopProducts />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ShopOrders />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ShopManagement shop={shopDetails} />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Shop;
