import React, { useEffect, useState } from "react";
import { Divider, message, Popconfirm, Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addUsers, selectUsers } from "../../features/userSlice";
import AddUser from "./AddUser";
import supabase from "../../supabaseClient";
import EditUser from "./EditUser";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Full name",
        key: "fullName",
        render: (_, user) => (
            <>
                <p>
                    {user?.first_name} {user?.last_name}
                </p>
            </>
        ),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Phone number",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, user) => (
            <>
                <UserStatus user={user} />
            </>
        ),
    },
    {
        title: "Actions",
        key: "action",
        render: (_, user) => (
            <p className="flex flex-row gap-2 justify-start">
                <EditUser user={user} />
            </p>
        ),
    },
];

const UserStatus = ({ user }) => {
    const dispatch = useDispatch();

    const getUsers = async () => {
        let { data: customUsers, error } = await supabase
            .from("customUsers")
            .select("*")
            .eq("role", "admin");

        if (error) {
            console.log(error.message);
        } else {
            dispatch(addUsers(customUsers));
        }
    };

    const changeStatus = async () => {
        try {
            const { data, error } = await supabase
                .from("customUsers")
                .update({
                    status: !user?.status,
                })
                .eq("id", user?.id)
                .select("*");

            if (data) {
                //
                message.success("Status is changed successfully");
                try {
                    getUsers();
                } catch (error) {
                    console.log(error);
                }
            } else {
                message.error(error.message);
            }
        } catch (error) {
            console.log(error);
            message.error(error.message);
        }
    };

    return (
        <Popconfirm
            title="Change Status"
            description={`Are you sure you want to ${
                user?.status ? "deactivate" : "activate"
            } this user?`}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                className: "bg-blue-500",
            }}
            onConfirm={changeStatus}
        >
            <Switch
                checked={user?.status}
                className={user?.status ? null : `bg-zinc-300 rounded-full`}
            />
        </Popconfirm>
    );
};

const Users = () => {
    const dispatch = useDispatch();
    const [dataLoad, setDataLoad] = useState(false)

    useEffect(() => {
        const getUsers = async () => {
            setDataLoad(true)
            let { data: customUsers, error } = await supabase
                .from("customUsers")
                .select("*")
                .eq("role", "admin");

            if (error) {
            setDataLoad(false)
                console.log(error.message);
            } else {
            setDataLoad(false)
                dispatch(addUsers(customUsers));
            }
        };

        getUsers();
    }, [dispatch]);

    const users = useSelector(selectUsers);
    const allUsers = users
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedUsers = allUsers.map((user, index) => {
        const key = index + 1;
        return { ...user, key };
    });

    return (
        <div>
            <PageHeader head={"JU System admins"} action={<AddUser />} />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <div>
                        <Table columns={columns} dataSource={sortedUsers} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Users;
