import React from "react";
import Box from "@mui/material/Box";
import PointOfSale from "./PointOfSale";
import PageHeader from "../../components/PageHeader";
import PosCart from "./PosCart";

const CreatePosOrder = () => {
  return (
    <div>
      <PageHeader
        head={"JU Create POS Order"}
        action={
          <>
            <PosCart />
          </>
        }
      />
      <Box>
        <PointOfSale />
      </Box>
    </div>
  );
};

export default CreatePosOrder;
