import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendors: [],
  vendorDetails: "",
  vendorShops: [],
  customers: [],
  customerDetails: "",
  customerOrders: [],
  customerFavProducts: [],
  users: [],
  profile: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addVendors(state, action) {
      state.vendors = action.payload;
    },

    addVendorDetails(state, action) {
      state.vendorDetails = action.payload;
    },

    addVendorShops(state, action) {
      state.vendorShops = action.payload;
    },

    addCustomers(state, action) {
      state.customers = action.payload;
    },

    addCustomerDetails(state, action) {
      state.customerDetails = action.payload;
    },

    addCustomerOrders(state, action) {
      state.customerOrders = action.payload;
    },

    addCustomerFavProducts(state, action) {
      state.customerFavProducts = action.payload;
    },

    addUsers(state, action) {
      state.users = action.payload;
    },

    addProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const {
  addVendors,
  addVendorDetails,
  addVendorShops,
  addCustomers,
  addUsers,
  addCustomerDetails,
  addCustomerOrders,
  addCustomerFavProducts,
  addProfile,
} = userSlice.actions;

export const selectVendors = (state) => state.user.vendors;
export const selectVendorDetails = (state) => state.user.vendorDetails;
export const selectVendorShops = (state) => state.user.vendorShops;
export const selectCustomers = (state) => state.user.customers;
export const selectCustomerDetails = (state) => state.user.customerDetails;
export const selectCustomerOrders = (state) => state.user.customerOrders;
export const selectCustomerFavProducts = (state) =>
  state.user.customerFavProducts;
export const selectUsers = (state) => state.user.users;
export const selectProfile = (state) => state.user.profile;

export default userSlice.reducer;
