import * as React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

const variants = ["h1", "h3", "body1", "caption"];

function TypographyDemo(props) {
    const { loading = false } = props;

    return (
        <div>
            {variants.map((variant) => (
                <Typography component="div" key={variant} variant={variant}>
                    {loading ? <Skeleton /> : variant}
                </Typography>
            ))}
        </div>
    );
}

TypographyDemo.propTypes = {
    loading: PropTypes.bool,
};

const DataLoader = () => {
    return (
        <div>
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    position: "fixed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid container spacing={8}>
                    <Grid item xs>
                        <TypographyDemo loading />
                        <TypographyDemo loading />
                        <TypographyDemo loading />
                        <TypographyDemo loading />
                    </Grid>
                </Grid>
                <CircularProgress />
            </Box>
        </div>
    );
};

export default DataLoader;
