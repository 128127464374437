import React, { useState } from "react";
import { message } from "antd";
import supabase from "../../supabaseClient";
import { Avatar, Box, Button, LinearProgress, Typography } from "@mui/material";
import CustomSwiper from "../../components/swipers/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import app1 from "../../assets/images/app1.jpg";
import app2 from "../../assets/images/app2.jpg";
import app3 from "../../assets/images/app3.jpg";
import app4 from "../../assets/images/app4.jpg";
import logo from "../../assets/images/logo.jpg";
import { colors } from "../../assets/utils/colors";
import { LoginOutlined } from "@mui/icons-material";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [messageApi, contextHolder] = message.useMessage();

    const userLogin = async (e) => {
        e.preventDefault();

        if (!email) {
            messageApi.open({
                type: "warning",
                content: "Please enter your email",
            });
        } else if (!password) {
            messageApi.open({
                type: "warning",
                content: "Please enter your password",
            });
        } else {
            //
            setLoading(true);
            //check if user has access
            // let { data: customUsers, error } = await supabase
            // .from('customUsers')
            // .select('*')
            // .eq('email', email)

            const { data, error } = await supabase.auth.signInWithPassword({
                email,
                password,
            });

            if (error) {
                setLoading(false);
                messageApi.open({
                    type: "error",
                    content: error.message,
                });
            } else if (data) {
                setLoading(false);
                messageApi.open({
                    type: "success",
                    content: "User logged in",
                });
            }
        }
    };

    const renderButton = () => {
        return (
            <Button
                variant="contained"
                fullWidth
                endIcon={<LoginOutlined />}
                onClick={(e) => userLogin(e)}
                sx={{ background: colors.primary }}
                disabled={loading ? true : false}
            >
                Sign In
            </Button>
        );
    };

    const appMockup = [
        {
            id: 1,
            img: app1,
        },
        {
            id: 3,
            img: app3,
        },
        {
            id: 2,
            img: app2,
        },
        {
            id: 4,
            img: app4,
        },
    ];

    return (
        <>
            {contextHolder}
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        height: "100vh",
                        width: "50%",
                        display: { md: "flex", sm: "none", xs: "none" },
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        px: 5,
                        background: `linear-gradient(360deg, ${colors.primary}, ${colors.bgColor2})`,
                    }}
                >
                    <Box sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: 40,
                                color: colors.primary,
                                textAlign: "center",
                                // textTransform: "uppercase",
                            }}
                        >
                            Jack Umeme
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: 16,
                                color: colors.primary,
                                textAlign: "center",
                                fontStyle: "italic",
                            }}
                        >
                            Admin dashboard
                        </Typography>
                    </Box>
                    <CustomSwiper element={"blog"} conHeight="100%">
                        {appMockup.map((app) => (
                            <SwiperSlide
                                key={app.id}
                                style={{
                                    border: `4px solid ${colors.bgColor4}`,
                                    borderRadius: 25,
                                    background: "white",
                                }}
                            >
                                <Box>
                                    <Avatar
                                        variant="square"
                                        src={app.img}
                                        sx={{
                                            height: "75vh",
                                            width: "100%",
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                </Box>
                <Box
                    sx={{
                        height: "100vh",
                        width: { md: "50%", sm: "100%", xs: "100%" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor2})`,
                    }}
                >
                    <Box
                        sx={{
                            height: 500,
                            width: { md: 450, sm: 450, xs: "90%" },
                            borderRadius: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            px: 5,
                            position: "relative",
                            boxShadow: `1px 1px 35px 5px ${colors.primary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 20,
                                right: 20,
                            }}
                        >
                            {loading && (
                                <LinearProgress
                                    sx={{ height: 10, borderRadius: 15 }}
                                />
                            )}
                        </Box>
                        <Box sx={{ position: "absolute" }}>
                            <Avatar
                                src={logo}
                                sx={{
                                    height: 300,
                                    width: 300,
                                    p: 2,
                                    bgcolor: "#fff",
                                    opacity: 0.1,
                                    img: {
                                        objectFit: "contain",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ position: "absolute", top: -50 }}>
                            <Avatar
                                src={logo}
                                sx={{
                                    height: 100,
                                    width: 100,
                                    // p: 0.5,
                                    bgcolor: "#fff",
                                    img: {
                                        objectFit: "contain",
                                    },
                                }}
                            />
                        </Box>
                        <div style={{ width: "100%", zIndex: 999 }}>
                            <div className="mb-8 text-center">
                                <h1
                                    className="my-3 text-4xl font-bold"
                                    style={{ color: colors.primary }}
                                >
                                    Sign in
                                </h1>
                                <p
                                    className="text-sm"
                                    style={{ color: colors.primary }}
                                >
                                    Sign in to access your account
                                </p>
                            </div>
                            <div className="space-y-12">
                                <div className="space-y-4">
                                    <div>
                                        <label
                                            className="block mb-2 text-sm"
                                            style={{
                                                color: colors.primary,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                            className="w-full px-3 py-2 border rounded-md"
                                        />
                                    </div>
                                    <div>
                                        <div className="flex justify-between mb-2">
                                            <label
                                                className="text-sm"
                                                style={{
                                                    color: colors.primary,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Password
                                            </label>
                                            {/* <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                className="text-xs hover:underline text-violet-600"
                                            >
                                                Forgot password?
                                            </a> */}
                                        </div>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            value={password}
                                            className="w-full px-3 py-2 border rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div>{renderButton()}</div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Login;
