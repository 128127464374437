import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    addUserInfo(state, action) {
      state.userInfo = action.payload;
    },
  },
});

export const { addUserInfo } = authSlice.actions;

export const selectUserInfo = (state) => state.authentication.userInfo;

export default authSlice.reducer;