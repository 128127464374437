import React, { useEffect, useRef, useState } from "react";
import { Empty, Image, Input, Modal, Spin, Upload } from "antd";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { addDialogues, selectDialogues } from "../../features/chatsSlice";
import { isEmpty } from "lodash";
import supabase from "../../supabaseClient";
import { toast } from "react-hot-toast";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { IoTimerOutline } from "react-icons/io5";
import moment from "moment/moment";
import { AiOutlinePlus } from "react-icons/ai";
import { SendOutlined } from "@mui/icons-material";

const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ChatMessage = ({ message, outgoing }) => {
    return (
        <div
            className={`${
                outgoing
                    ? `min-h-42 purple_gradient max-w-[40%] min-w-[20%] bg-emerald-600 rounded-br-xl rounded-bl-xl rounded-tl-xl p-3 mr-4 relative ml-auto my-3`
                    : `min-h-42 sky_gradient max-w-[40%] min-w-[20%] bg-sky-400 rounded-br-xl rounded-bl-xl rounded-tr-xl p-3 ml-4 relative mr-auto my-3`
            }`}
        >
            {message?.isImage ? (
                <>
                    <Image width={200} src={message?.message} />
                    <p className="text-[14px] text-gray-50">
                        {message?.caption}
                    </p>
                </>
            ) : (
                <>
                    {message?.message ? (
                        <p className="text-[14px] text-gray-50">
                            {message.message}
                        </p>
                    ) : null}
                </>
            )}
            {message?.createdAt ? (
                <p className="mt-2 flex flex-row items-center">
                    <IoTimerOutline className="text-xs text-gray-50" />
                    <span className="ml-1 text-xs text-gray-50">
                        {moment(message?.createdAt).format(
                            "DD-MM-YYYY, h:mm a"
                        )}
                    </span>
                </p>
            ) : null}
            {outgoing ? (
                <BsFillCaretRightFill className="absolute -top-[3px] -right-4 text-2xl text-[#059669]" />
            ) : (
                <BsFillCaretLeftFill className="absolute -top-[3px] -left-4 text-2xl text-sky-400" />
            )}
        </div>
    );
};

const Conversations = () => {
    const dispatch = useDispatch();

    const [messagesLoading, setMessageLoading] = useState(false);
    const [chatConvo, setChatConvo] = useState(null);
    const [selectedConvo, setSelectedConvo] = useState(null);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);

    const [message, setMessage] = useState("");
    const [messageImage, setMessageImage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getDialogues = async () => {
            let { data: dialogue, error } = await supabase
                .from("dialogue")
                .select("*, customUsers(*)");

            if (dialogue) {
                console.log(dialogue);
                dispatch(addDialogues(dialogue));
            }
        };

        getDialogues();
        scrollToBottom();
    }, [dispatch]);

    const chatContainerRef = useRef(null);

    // Scroll to the bottom whenever chatMessages updates
    useEffect(() => {
        scrollToBottom();
    }, [chatConvo]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    };

    const dialogues = useSelector(selectDialogues);
    const sortedDialogues = dialogues
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const renderChats = () => {
        if (!isEmpty(sortedDialogues)) {
            return (
                <div className="my-2 grid grid-cols-1 gap-2 overflow-y-auto">
                    {sortedDialogues.map((dialogue) => {
                        const fName = dialogue?.customUsers?.first_name;
                        const lName = dialogue?.customUsers?.last_name;
                        const name1 = fName.charAt(0);
                        const name2 = lName.charAt(0);
                        return (
                            <div className="border-b-[1px] border-gray-50">
                                <div
                                    className="flex flex-row gap-2 px-2 cursor-pointer py-1"
                                    onClick={() => {
                                        getConvoMessages({
                                            dialogueID: dialogue?.id,
                                        });
                                        setSelectedConvo(dialogue);
                                    }}
                                >
                                    <div className="my-0.5">
                                        <Avatar>
                                            {name1}
                                            {name2}
                                        </Avatar>
                                    </div>
                                    <div className="text-sm">
                                        <p>
                                            {fName} {lName}
                                        </p>
                                        <p>{dialogue?.customUsers?.phone}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className="py-2">
                    <Empty />
                </div>
            );
        }
    };

    const renderSelectedChat = () => {
        if (selectedConvo) {
            const fName = selectedConvo?.customUsers?.first_name;
            const lName = selectedConvo?.customUsers?.last_name;
            const name1 = fName.charAt(0);
            const name2 = lName.charAt(0);
            return (
                <div className="h-screen flex flex-col">
                    <div className="w-[100%] h-16 rounded-md border-2 border-gray">
                        <div className="flex flex-row gap-2 px-2 my-2">
                            <div className="my-0.5">
                                <Avatar>
                                    {name1}
                                    {name2}
                                </Avatar>
                            </div>
                            <div className="text-sm">
                                <p>
                                    {fName} {lName}
                                </p>
                                <p>{selectedConvo?.customUsers?.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="h-[320px] px-2 pt-2 overflow-y-scroll"
                        ref={chatContainerRef}
                    >
                        {messagesLoading ? (
                            <Spin
                                spinning={messagesLoading}
                                tip="Loading..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        ) : (
                            <>{renderCustomerConvo()}</>
                        )}
                    </div>
                    <div className="h-20 w-[100%] pt-2 px-2">
                        {renderSendMessage()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="py-2 w-[100%] h-[100%] flex flex-row justify-center items-center">
                    <p>View conversation</p>
                </div>
            );
        }
    };

    const renderCustomerConvo = () => {
        if (!isEmpty(chatConvo)) {
            return (
                <div>
                    {chatConvo.map((message) => {
                        return (
                            <ChatMessage
                                key={message?.id}
                                message={message}
                                outgoing={
                                    message.sender.toLowerCase() === "admin"
                                        ? true
                                        : false
                                }
                            />
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className="py-2">
                    <Empty />
                </div>
            );
        }
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <div className="flex flex-col justify-center items-center">
            <AiOutlinePlus />
            <div
            // style={{
            //   marginTop: 8,
            // }}
            >
                Upload
            </div>
        </div>
    );

    const handleImageUrl = (path) => {
        //console.log(path);
        try {
            const { data } = supabase.storage
                .from("jack-umeme-store")
                .getPublicUrl(path);

            if (data) {
                setMessageImage(data?.publicUrl);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUploadImage = async (file) => {
        //console.log(file);
        try {
            //upload image
            const { data, error } = await supabase.storage
                .from("jack-umeme-store")
                .upload(`public_images/chats/${file.name}`, file, {
                    cacheControl: "3600",
                    upsert: false,
                });

            if (data) {
                //store image on list
                const list = [...fileList, file];
                setFileList(list);
                //
                handleImageUrl(data?.path);
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveImage = async (file) => {
        console.log(file);
        try {
            //remove image
            const { data, error } = await supabase.storage
                .from("jack-umeme-store")
                .remove([`public_images/chats/${file.name}`]);

            if (data) {
                //
                setFileList([]);
                setMessageImage("");
            } else {
                toast.error(error.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const props = {
        name: "file",
        headers: {
            authorization: "authorization-text",
        },
        multiple: "false",
        onRemove: (file) => {
            handleRemoveImage(file);
        },
        beforeUpload: (file) => {
            //check format and size
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJpgOrPng) {
                toast.error("You can only upload JPG/PNG file!");
            } else if (!isLt2M) {
                toast.error("Image must be smaller than 2MB!");
            } else if (fileList.length !== 0) {
                toast.error("Sorry! You can only upload one image file!");
            } else {
                //update file list
                //send image to storage
                handleUploadImage(file);
            }
            return false;
        },
        fileList,
    };

    const sendMessage = async () => {
        if (!message && !messageImage) {
            toast.error("Please enter message or upload an image");
        } else {
            if (!isEmpty(selectedConvo)) {
                //
                setLoading(true);
                if (messageImage) {
                    try {
                        const { data, error } = await supabase
                            .from("chats")
                            .insert([
                                {
                                    message: messageImage,
                                    caption: message,
                                    customer_id: selectedConvo?.customer_id,
                                    sender: "admin",
                                    isImage: true,
                                    dialogue_id: selectedConvo?.id,
                                },
                            ])
                            .select("*");

                        if (data) {
                            //
                            getMessages({ dialogueID: selectedConvo?.id });
                            setMessage("");
                            setMessageImage("");
                            setFileList([]);
                            setLoading(false);
                        } else {
                            toast.error(error.message);
                            setLoading(false);
                        }
                    } catch (error) {
                        toast.error(error.message);
                        setLoading(false);
                    }
                } else {
                    try {
                        const { data, error } = await supabase
                            .from("chats")
                            .insert([
                                {
                                    message,
                                    caption: "",
                                    customer_id: selectedConvo?.customer_id,
                                    sender: "admin",
                                    isImage: false,
                                    dialogue_id: selectedConvo?.id,
                                },
                            ])
                            .select("*");

                        if (data) {
                            //
                            getMessages({ dialogueID: selectedConvo?.id });
                            setMessage("");
                            setMessageImage("");
                            setFileList([]);
                            setLoading(false);
                        } else {
                            toast.error(error.message);
                            setLoading(false);
                        }
                    } catch (error) {
                        toast.error(error.message);
                        setLoading(false);
                    }
                }
            } else {
                //
                toast.error("Please select conversation");
            }
        }
    };

    const renderSendMessage = () => {
        if (!isEmpty(selectedConvo)) {
            return (
                <div className="w-[100%] flex flex-row gap-2">
                    <div className="w-[70%] py-2">
                        <TextArea
                            rows={4}
                            placeholder="Type here...."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="py-1">
                        <Upload
                            {...props}
                            listType="picture-card"
                            // fileList={fileList}
                            onPreview={handlePreview}
                            // onChange={handleChange}
                        >
                            {fileList.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                        >
                            <img
                                alt="example"
                                style={{
                                    width: "100%",
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                    {loading ? (
                        <div className="bg-violet-500 opacity-25 rounded-md text-white cursor-not-allowed py-2 h-16 w-[20%] flex flex-col justify-center items-center">
                            <SendOutlined />
                            <p>Sending...</p>
                        </div>
                    ) : (
                        <div
                            onClick={() => sendMessage()}
                            className="bg-violet-500 rounded-md text-white cursor-pointer py-2 h-16 w-[20%] flex flex-col justify-center items-center"
                        >
                            <SendOutlined />
                            <p>Send</p>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    const getConvoMessages = async ({ dialogueID }) => {
        setMessageLoading(true);
        try {
            let { data: chats, error } = await supabase
                .from("chats")
                .select("*")
                .eq("dialogue_id", dialogueID);

            if (chats) {
                //update state
                console.log(chats);
                const sortedConvo = chats
                    .slice()
                    .sort(
                        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    );
                setChatConvo(sortedConvo);
                setMessageLoading(false);
            } else {
                setChatConvo([]);
                setMessageLoading(false);
            }
        } catch (error) {
            toast.error(error.message);
            setChatConvo([]);
            setMessageLoading(false);
        }
    };

    const getMessages = async ({ dialogueID }) => {
        try {
            let { data: chats, error } = await supabase
                .from("chats")
                .select("*")
                .eq("dialogue_id", dialogueID);

            if (chats) {
                //update state
                const sortedConvo = chats
                    .slice()
                    .sort(
                        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    );
                setChatConvo(sortedConvo);
            } else {
                setChatConvo([]);
            }
        } catch (error) {
            setChatConvo([]);
        }
    };

    const onSearchCustomer = (value) => {
        console.log(value);
    };

    return (
        <div className="">
            <div>
                <div className="flex flex-row items-start">
                    <div className="w-[25%] h-[90vh] flex-auto bg-blue-200 rounded-tl-md rounded-bl-md p-2">
                        <div className="text-center">Conversation List</div>
                        <div className="h-14 w-full border-b-[1px] border-primary">
                            {/* Search box */}
                            <div className="flex flex-row justify-center py-1 px-4">
                                <Input
                                    placeholder="Search customer name"
                                    allowClear
                                    // onChange={(e) => onSearchCustomer()}
                                />
                            </div>
                        </div>
                        {/* Chats */}
                        {renderChats()}
                    </div>

                    <div className="w-[75%] h-[500px] flex flex-col bg-gray-100 rounded-tr-md rounded-br-md overflow-hidden">
                        {renderSelectedChat()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Conversations;
