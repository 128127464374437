import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addVendorDetails,
    addVendors,
    selectVendors,
} from "../../features/userSlice";
import supabase from "../../supabaseClient";
import PageHeader from "../../components/PageHeader";
import DataLoader from "../../components/DataLoader";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Full name",
        key: "fullName",
        render: (_, vendor) => (
            <>
                <p>
                    {vendor?.first_name} {vendor?.last_name}
                </p>
            </>
        ),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Phone number",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "Location",
        dataIndex: "region",
        key: "region",
        render: (text) => <p className="capitalize">{text}</p>,
    },
    {
        title: "Actions",
        key: "action",
        render: (_, vendor) => <ViewVendor vendor={vendor} />,
    },
];

const ViewVendor = ({ vendor }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleViewVendor = () => {
        dispatch(addVendorDetails(vendor));
        navigate(`/vendors/${vendor?.usersId}`);
    };

    return (
        <Button
            type="text"
            shape="circle"
            className="flex justify-center"
            onClick={() => handleViewVendor()}
        >
            <FaEye className="text-red-500 text-xl cursor-pointer" />
        </Button>
    );
};

const Vendors = () => {
    const dispatch = useDispatch();
    const [dataLoad, setDataLoad] = useState(false)

    useEffect(() => {
        const getVendors = async () => {
            setDataLoad(true)
            let { data: vendors, error } = await supabase
                .from("customUsers")
                .select("*")
                .eq("role", "vendor");

            if (vendors) {
            setDataLoad(false)
                dispatch(addVendors(vendors));
            } else {
            setDataLoad(false)
                console.log(error.message);
            }
        };

        getVendors();
    }, [dispatch]);

    const vendors = useSelector(selectVendors);
    // const allVendors = vendors
    //   .slice()
    //   .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const sortedVendors = vendors.map((user, index) => {
        const key = index + 1;
        return { ...user, key };
    });

    return (
        <div>
            <PageHeader head={"JU System vendors"} />
            {dataLoad ? (
                <>
                    <DataLoader />
                </>
            ) : (
                <>
                    <Table columns={columns} dataSource={sortedVendors} />
                </>
            )}
        </div>
    );
};

export default Vendors;
