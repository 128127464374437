import { Card, CardContent } from "@mui/material";
import { Button, Divider, Modal, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    addOrderDetails,
    addOrderShopOrders,
    selectOrderDetails,
    selectOrderShopOrders,
} from "../../features/orderSlice";
import supabase from "../../supabaseClient";
import MakeOrderPayment from "./MakeOrderPayment";
import { isEmpty } from "lodash";

const formatter = new Intl.NumberFormat("en-US");

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Shop",
        key: "shopName",
    },
    {
        title: "Shop Location",
        key: "shopLocation",
    },
    {
        title: "Ordered Items",
        dataIndex: "ordered_products",
        key: "ordered_products",
        render: (_, { ordered_products }) => (
            <>
                <ViewItems items={ordered_products} />
            </>
        ),
    },
    {
        title: "Total Price",
        dataIndex: "total_amount",
        key: "total_amount",
        render: (text) => <p>TZS {formatter.format(text)}</p>,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => (
            <>
                <Status status={status} />
            </>
        ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, order) => <ViewOrder order={order} />,
    // },
];

const Status = ({ status }) => {
    if (status === "placed") {
        return <Tag color={"blue"}>Placed</Tag>;
    } else if (status === "accepted") {
        return <Tag color={"green"}>Accepted</Tag>;
    } else if (status === "shipped") {
        return <Tag color={"green"}>Shipped</Tag>;
    } else if (status === "delivered") {
        return <Tag color={"green"}>Delivered</Tag>;
    } else if (status === "cancelled") {
        return <Tag color={"red"}>Cancelled</Tag>;
    } else {
        return <Tag color={"green"}>Refunded</Tag>;
    }
};

const ViewItems = ({ items }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formatter = new Intl.NumberFormat("en-US");

    return (
        <>
            <button
                type="button"
                className="px-6 py-2 border rounded-md border-purple-500 hover:bg-purple-500 hover:text-white"
                onClick={showModal}
            >
                View
            </button>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
                // style={{ top: 30 }}
                width={600}
            >
                <h4 className="text-lg font-semibold text-center pb-2">
                    ORDRED ITEMS
                </h4>
                <div
                    className={
                        items?.length > 2
                            ? "h-[420px] px-3 pt-2 overflow-y-scroll"
                            : "h-[420px] px-3 pt-2"
                    }
                >
                    <div className="flex flex-col max-w-3xl px-6 space-y-4 sm:p-10 bg-gray-50 text-gray-800">
                        <h2 className="text-xl font-semibold text-center"></h2>
                        <ul className="flex flex-col divide-y divide-gray-300">
                            {items && items.length > 0 ? (
                                <>
                                    {items.map((product, index) => {
                                        const totalAmount =
                                            product?.price *
                                            product?.cart_quantity;
                                        return (
                                            <li
                                                className="flex flex-col py-6 sm:flex-row sm:justify-between"
                                                key={index}
                                            >
                                                <div className="flex w-full space-x-2 sm:space-x-4">
                                                    <img
                                                        className="flex-shrink-0 object-cover w-20 h-20 border-transparent rounded outline-none sm:w-32 sm:h-32 bg-gray-500"
                                                        src={
                                                            product?.bannerImage
                                                        }
                                                        alt="car spare"
                                                    />
                                                    <div className="w-full pb-4">
                                                        <div>
                                                            <p className="capitalize font-semibold">
                                                                {product?.title}{" "}
                                                                {
                                                                    product?.condition
                                                                }
                                                            </p>
                                                            <p>
                                                                {
                                                                    product
                                                                        ?.brand
                                                                        ?.brandName
                                                                }{" "}
                                                                {
                                                                    product
                                                                        ?.model
                                                                        ?.modelName
                                                                }{" "}
                                                                {product?.year}
                                                            </p>
                                                            <p>
                                                                TZS{" "}
                                                                {formatter.format(
                                                                    product?.price ||
                                                                        0
                                                                )}
                                                            </p>
                                                            <p className="">
                                                                Total Items :{" "}
                                                                {
                                                                    product?.cart_quantity
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="pt-1">
                                                            <p className="text-semibold">
                                                                Total Price :{" "}
                                                                {formatter.format(
                                                                    totalAmount
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </>
                            ) : (
                                <li className="text-center">
                                    There is no products
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const EditStatus = () => {
    const dispatch = useDispatch();
    const { orderID } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [orderStatus, setStatus] = useState("");

    const onChange = (id, value) => {
        if (value) {
            setStatus(value?.id);
        } else {
            setStatus("");
        }
    };
    const onSearch = (id, value) => {
        console.log("search:", value, id);
    };

    const getOrderDetails = async () => {
        let { data: orders, error } = await supabase
            .from("orders")
            .select("*")
            .eq("id", orderID);

        if (error) {
            console.log(error.message);
        } else {
            //console.log(orders);
            dispatch(addOrderDetails(orders[0]));
        }
    };

    const handleChangeOrderStatus = async () => {
        if (!orderStatus) {
            toast.warning("Please select order status");
        } else {
            setLoading(true);
            try {
                const { data, error } = await supabase
                    .from("orders")
                    .update({
                        status: orderStatus,
                    })
                    .eq("id", orderID)
                    .select("*");

                if (data) {
                    //
                    getOrderDetails();
                    setLoading(false);
                    toast.success("Status is changed successfully");
                } else {
                    toast.error(error.message);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                toast.error(error.message);
            }
        }
    };

    return (
        <>
            <Button
                type="text"
                shape="circle"
                className="flex justify-center"
                onClick={showModal}
            >
                <BiEditAlt className="text-lg cursor-pointer" />
            </Button>
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    className: "hidden",
                }}
                cancelButtonProps={{
                    className: "hidden",
                }}
            >
                <h4 className="text-lg font-semibold text-center pb-2">
                    CHANGE ORDER STATUS
                </h4>
                <div className="w-full">
                    <div className="flex flex-row justify-center py-3">
                        <Select
                            showSearch
                            style={{
                                width: 350,
                            }}
                            placeholder="Select status"
                            optionFilterProp="children"
                            size="large"
                            allowClear
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                {
                                    id: 2,
                                    value: "Accepted",
                                },
                                {
                                    id: 3,
                                    value: "Shipped",
                                },
                                {
                                    id: 4,
                                    value: "Delivered",
                                },
                                {
                                    id: 5,
                                    value: "Cancelled",
                                },
                                {
                                    id: 6,
                                    value: "Refunded",
                                },
                            ]}
                        />
                    </div>
                    <div className="flex flex-row justify-center py-2 pt-4">
                        {loading ? (
                            <button
                                type="button"
                                className="px-6 py-2 w-[74%] cursor-not-allowed opacity-25 border rounded-md border-purple-500 hover:bg-purple-500 hover:text-white"
                            >
                                Loading ...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="px-6 py-2 w-[74%] border rounded-md border-purple-500 hover:bg-purple-500 hover:text-white"
                                onClick={() => handleChangeOrderStatus()}
                            >
                                SAVE CHANGES
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

const Order = () => {
    const dispatch = useDispatch();
    const { orderID } = useParams();

    const [dataLoadng, setDataLoading] = useState(true);

    useEffect(() => {
        const getShopOrders = async () => {
            let { data: shop_orders, error } = await supabase
                .from("shop_orders")
                .select("*, shops(*), orders(*)")
                .eq("order_id", orderID);

            if (shop_orders) {
                dispatch(addOrderShopOrders(shop_orders));
                setDataLoading(false);
            } else {
                setDataLoading(false);
            }
        };

        const getOrderDetails = async () => {
            let { data: orders, error } = await supabase
                .from("orders")
                .select("*, customer(*)")
                .select("*, transactions(*)")
                .eq("id", orderID);

            if (error) {
                console.log(error.message);
            } else {
                dispatch(addOrderDetails(orders[0]));
            }
        };

        getShopOrders();
        getOrderDetails();
    }, [dispatch]);

    const orderDetails = useSelector(selectOrderDetails);
    const orderShopOrders = useSelector(selectOrderShopOrders);

    const sortedOrders = orderShopOrders.map((order, index) => {
        const key = index + 1;
        return { ...order, key };
    });

    const renderStatus = (status) => {
        if (status === 1) {
            return <Tag color={"blue"}>Placed</Tag>;
        } else if (status === 2) {
            return <Tag color={"green"}>Processed</Tag>;
        } else if (status === 3) {
            return <Tag color={"green"}>Shipped</Tag>;
        } else if (status === 4) {
            return <Tag color={"green"}>Delivered</Tag>;
        } else if (status === 5) {
            return <Tag color={"red"}>Cancelled</Tag>;
        } else {
            return <Tag color={"green"}>Refunded</Tag>;
        }
    };

    const formatter = new Intl.NumberFormat("en-US");

    return (
        <div>
            <h4 className="text-center text-lg font-semibold">Order Details</h4>
            <div className="flex flex-row justify-center items-center pt-2">
                <Card sx={{ width: 600, background: "#F3E8FF" }}>
                    <CardContent>
                        <div className="flex flex-row gap-2 py-1">
                            <p className="py-1 w-[50%]">
                                Order Number : {orderDetails?.order_number}
                            </p>
                            <p className="py-1 w-[50%]">
                                Order Type :{" "}
                                {orderDetails?.isPOS ? <>POS</> : <>DIRECT</>}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2 py-1">
                            <p className="py-1 w-[50%]">
                                Total Amount: TZS{" "}
                                {formatter.format(orderDetails?.total_amount)}
                            </p>
                            <p className="py-1 w-[50%]">
                                Date :{" "}
                                {moment(orderDetails?.created_at).format(
                                    "DD-MM-YYYY, h:mm a"
                                )}
                            </p>
                        </div>
                        <p className="py-1">
                            Customer: {orderDetails?.order_number}
                        </p>
                        <div className="flex flex-row gap-2 py-1">
                            <p className="py-1 w-[50%]">
                                Payment:{" "}
                                {orderDetails?.isPaid ? (
                                    <Tag color={"green"}>Paid</Tag>
                                ) : (
                                    <Tag color={"red"}>Not Paid</Tag>
                                )}
                            </p>
                            <div className="py-1 w-[45%]">
                                Status: {renderStatus(orderDetails?.status)}
                            </div>
                            <div className="py-1 w-[5%]">
                                <EditStatus />
                            </div>
                        </div>
                        {!isEmpty(orderDetails?.transactions) && (
                            <div className="flex flex-row gap-2 py-1">
                                <MakeOrderPayment
                                    orderID={orderDetails?.id}
                                    transactionID={
                                        orderDetails?.transactions?.[0]
                                            .transaction_id
                                    }
                                />
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
            <div className="py-3">
                <Divider plain className="py-2">
                    <p className="text-lg font-semibold">Shop Orders</p>
                </Divider>
                <Table columns={columns} dataSource={sortedOrders} />
            </div>
        </div>
    );
};

export default Order;
