import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import supabase from "../../supabaseClient";
import {
  Cancel,
  CheckCircle,
  LocationOn,
} from "@mui/icons-material";
import {
  addVendorDetails,
  addVendorShops,
  selectVendorDetails,
} from "../../features/userSlice";
import VendorShops from "./VendorShops";


const Vendor = () => {
  const dispatch = useDispatch();
  const { vendorID } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getVendor = async () => {
      let { data: vendor, error } = await supabase
        .from("customUsers")
        .select("*")
        .eq("usersId", vendorID);

      if (vendor) {
        console.log(vendor);
        dispatch(addVendorDetails(vendor[0]));
      } else {
        console.log(error.message);
      }
    };

    const getVendorShops = async () => {
      let { data: shops, error } = await supabase
        .from("shops")
        .select("*")
        .eq("shopOwner", vendorID || "");

      if (shops) {
        dispatch(addVendorShops(shops));
      } else {
        console.log(error.message);
      }
    };

    getVendor();
    getVendorShops();
  }, [dispatch]);

  const vendorDetails = useSelector(selectVendorDetails);

  return (
    <div className="">
      <div className="w-[100%]">
        <div className="bg-purple-200 px-4 py-2 rounded-t-md">
          <div className="w-[100%] flex flex-row gap-2 justify-between">
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row gap-1 text-lg">
              <LocationOn className="my-2" />
              <p className="text-sm py-2.5">
                {vendorDetails ? <>{vendorDetails?.region}</> : null}
              </p>
            </div>
            <div className="w-[50%] bg-[#faf5ff] rounded-md h-10 flex flex-row justify-between gap-1">
              <div>
                {vendorDetails ? (
                  <p className="py-2 px-1">
                    {vendorDetails?.status ? (
                      <span className="flex flex-row gap-2">
                        <CheckCircle className="text-xl text-green-500" />{" "}
                        <span className="">Active</span>
                      </span>
                    ) : (
                      <span className="flex flex-row gap-2">
                        <Cancel className="text-xl text-red-500" />{" "}
                        <span className="">Deactivated</span>
                      </span>
                    )}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 justify-between pt-2">
            <div className="py-1">
              <p className="text-xl py-1">
                {vendorDetails?.first_name} {vendorDetails?.last_name}{" "}
              </p>
              <p className="text-base py-1">{vendorDetails?.phone}</p>
            </div>
            <div className="py-2">
              
            </div>
          </div>
        </div>
        <div className="w-[100%]">
          <VendorShops />
        </div>
      </div>
    </div>
  );
};

export default Vendor;
