import apiClient from "../client/ClientApi";

export const createPromotion = async (promotion) => {
  const response = await apiClient.post("/upload/promo/banner", promotion, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

//updating promotion image
export const updatePromotionImage = async (promotion) => {
  const response = await apiClient.post(
    "/upload/promo/update-image",
    promotion,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
};
