import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prices: [],
  filteredPrices: [],
  priceDetails: "",
};

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    addPrices(state, action) {
      state.prices = action.payload;
    },

    addFilteredPrices(state, action) {
      state.filteredPrices = action.payload;
    },

    addPriceDetails(state, action) {
      state.priceDetails = action.payload;
    },
  },
});

export const { addPrices, addFilteredPrices, addPriceDetails } =
  deliverySlice.actions;

export const selectAllPrices = (state) => state.delivery.prices;
export const selectFilteredPrices = (state) => state.delivery.filteredPrices;
export const selectPriceDetails = (state) => state.delivery.priceDetails;

export default deliverySlice.reducer;
